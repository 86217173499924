import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  saveSubject,
  fetchAllSubjects,
  deleteSubject as deleteSubjectApi,
} from "./PayrollAPI";

const MySwal = withReactContent(Swal);

const AddSubject = () => {
  const [subjects, setSubjects] = useState([]);
  const [filteredSubjects, setFilteredSubjects] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [subject, setSubject] = useState({ name: "" });
  const [editing, setEditing] = useState(false);

  const institutecode = localStorage.getItem("institutecode") || "";

  useEffect(() => {
    loadSubjects();
  }, [institutecode]);

  useEffect(() => {
    filterSubjects();
  }, [searchTerm, subjects]);

  const loadSubjects = async () => {
    try {
      const subjectsData = await fetchAllSubjects(institutecode);
      setSubjects(subjectsData);
      setFilteredSubjects(subjectsData);
    } catch (error) {
      console.error("Error loading subjects:", error);
    }
  };

  const filterSubjects = () => {
    const filtered = subjects.filter((subject) =>
      subject.subject.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredSubjects(filtered);
  };

  const handleOpenAddDialog = () => {
    setSubject({ name: "" });
    setEditing(false);
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const onAddSubjectSubmit = async (e) => {
    e.preventDefault();
    if (!subject.name) {
      MySwal.fire({
        title: "Error!",
        text: "Please fill out the necessary fields.",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      });
      return;
    }

    try {
      await saveSubject(subject, institutecode, editing);
      MySwal.fire({
        title: editing ? "Updated!" : "Added!",
        text: `The subject has been ${
          editing ? "updated" : "added"
        } successfully.`,
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
      });
      loadSubjects();
      handleCloseAddDialog();
    } catch (error) {
      console.error("Error processing subject:", error);
      MySwal.fire({
        title: "Error!",
        text: "There was an error processing your request.",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const handleDeleteSubject = (subject) => {
    MySwal.fire({
      title: "Are you sure?",
      text: `Do you want to delete subject: ${subject.subject}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteSubject(subject.id);
      }
    });
  };

  const deleteSubject = async (subjectId) => {
    try {
      await deleteSubjectApi(subjectId);
      MySwal.fire({
        title: "Deleted!",
        text: "The subject has been deleted successfully.",
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
      });
      loadSubjects();
    } catch (error) {
      console.error("Error deleting subject:", error);
      MySwal.fire({
        title: "Error!",
        text: "There was an error deleting the subject.",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  return (
    <div>
      <Grid container spacing={2} sx={{ marginTop: 3 }} className="textField-root">
        <Typography variant="h6" gutterBottom sx={{ marginTop: 3 }}>
          Total Subjects: {filteredSubjects.length}
        </Typography>
        <Grid item xs={12} sm={2.5}>
          <TextField
            label="Search Subject"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
            placeholder="Search Subject"
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenAddDialog}
          >
            Add Subject
          </Button>
        </Grid>
      </Grid>

      <Box mt={2}>
        <TableContainer>
          <Table className="table-root">
            <TableHead>
              <TableRow>
                <TableCell>Subject ID</TableCell>
                <TableCell>Subject Name</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredSubjects.map((subject, index) => (
                <TableRow key={subject.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{subject.subject}</TableCell>
                  <TableCell>
                    <Button
                      color="error"
                      onClick={() => handleDeleteSubject(subject)}
                      size="small"
                      variant="contained"
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Dialog for Adding/Editing Subject */}
      <Dialog open={openAddDialog} onClose={handleCloseAddDialog}>
        <DialogTitle>{editing ? "Edit Subject" : "Add Subject"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {editing ? "Edit the subject." : "Add a new subject."}
          </DialogContentText>
          <form onSubmit={onAddSubjectSubmit} className="textField-root">
            <TextField
              autoFocus
              margin="dense"
              label="Subject Name"
              fullWidth
              variant="outlined"
              value={subject.name}
              onChange={(e) => setSubject({ ...subject, name: e.target.value })}
            />
            <DialogActions>
              <Button onClick={handleCloseAddDialog} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                {editing ? "Update" : "Add"}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddSubject;
