// import React, { useState, useRef, useEffect } from "react";
// import Plans from "./Plans";
// import "./CreateAccount.css";
// import {
//   AppBar,
//   Grid,
//   IconButton,
//   Toolbar,
//   Typography,
//   TextField,
//   FormControl,
//   MenuItem,
//   FormControlLabel,
//   Checkbox,
//   Paper,
// } from "@mui/material";
// import logo from "../../img/logo.jpg";
// import { useNavigate } from "react-router-dom";
// import { Login as LoginIcon } from "@mui/icons-material";
// import indianStatesAndDistricts from "../indianStatesAndDistricts";

// const CreateAccount = () => {
//   const [activeTab, setActiveTab] = useState("School / College");
//   const [selectedPlan, setSelectedPlan] = useState(null);
//   const [formData, setFormData] = useState({
//     emailaddress: "",
//     password: "",
//     confirmpassword: "",
//     institutename: "",
//     state: "",
//     district: "",
//     websitename: "",
//     gstNo: "",
//   });
//   const [showOptionalFields, setShowOptionalFields] = useState(false);

//   const navigate = useNavigate();
//   const formRef = useRef(null);

//   // Smooth scroll when selectedPlan changes
//   useEffect(() => {
//     if (selectedPlan && formRef.current) {
//       formRef.current.scrollIntoView({ behavior: "smooth" });
//     }
//   }, [selectedPlan]);

//   const handleCardClick = (planName) => {
//     setSelectedPlan(planName); // Track which plan was selected
//   };

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log("Form Data:", formData);
//   };

//   const buttonLabels = [
//     { id: "School / College", label: "School / College" },
//     { id: "Organization", label: "Organization / Company" },
//     { id: "Institute / Academy", label: "Institute / Academy" },
//   ];

//   const state = Object.keys(indianStatesAndDistricts);
//   const district = formData.state
//     ? indianStatesAndDistricts[formData.state]
//     : [];

//   const handleCheckboxChange = (e) => {
//     setShowOptionalFields(e.target.checked); // Toggle fields based on checkbox state
//   };

//   return (
//     <>
//       <AppBar
//         position="fixed"
//         sx={{
//           zIndex: (theme) => theme.zIndex.drawer + 1,
//           background: "#00649F",
//         }}
//       >
//         <Toolbar>
//           <Grid container justifyContent="center" alignItems="center">
//             <Grid item display="flex" alignItems="center">
//               <img
//                 src={logo}
//                 alt="Logo"
//                 style={{ marginRight: "8px", height: "30px" }}
//               />
//               <Typography variant="h5" color="white" fontWeight="bold">
//                 PJSOFTTECH
//               </Typography>
//             </Grid>
//           </Grid>
//           <IconButton
//             edge="end"
//             color="inherit"
//             onClick={() => navigate("/")}
//             sx={{ position: "absolute", right: 16 }}
//           >
//             <LoginIcon />
//           </IconButton>
//         </Toolbar>
//       </AppBar>

//       <div className="create-account" style={{ paddingTop: "80px" }}>
//         <h2>Upgrade your plan</h2>
//         <div className="button-group-toggle">
//           {buttonLabels.map((button) => (
//             <button
//               key={button.id}
//               className={activeTab === button.id ? "active" : ""}
//               onClick={() => setActiveTab(button.id)}
//             >
//               {button.label}
//             </button>
//           ))}
//         </div>

//         {/* Pass handleCardClick to Plans */}
//         {activeTab === "School / College" && (
//           <Plans type="student" onCardClick={handleCardClick} />
//         )}
//         {activeTab === "Organization" && (
//           <Plans type="organization" onCardClick={handleCardClick} />
//         )}
//          {activeTab === "Institute / Academy" && (
//           <Plans type="Institute / Academy" onCardClick={handleCardClick} />
//         )}

//         {/* Form section with ref */}
//         {selectedPlan && (
//           <div ref={formRef} className="form-container">
//             <Paper elevation={3} className="paper-wrapper">
//               <Typography
//                 variant="h4"
//                 textAlign="center"
//                 color="purple"
//                 gutterBottom
//               >
//                 Basic Information
//               </Typography>
//               <Grid container spacing={2} className="textField-root">
//                 <Grid item xs={12} sm={4}>
//                   <TextField
//                     label="Owner's Email Address"
//                     name="emailaddress"
//                     value={formData.emailaddress}
//                     onChange={handleChange}
//                     fullWidth
//                     required
//                     // error!!errors.emailaddress}
//                     //helperText={errors.emailaddress}
//                     InputLabelProps={{
//                       className: "required-asterisk",
//                     }}
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={4}>
//                   <TextField
//                     fullWidth
//                     label="Password"
//                     name="password"
//                     type="password"
//                     value={formData.password}
//                     onChange={handleChange}
//                     required
//                     InputLabelProps={{ className: "required-asterisk" }}
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={4}>
//                   <TextField
//                     fullWidth
//                     label="Confirm Password"
//                     name="confirmpassword"
//                     type="password"
//                     value={formData.confirmpassword}
//                     onChange={handleChange}
//                     required
//                     InputLabelProps={{ className: "required-asterisk" }}
//                   />
//                 </Grid>

//                 <Grid item xs={12}>
//                   <Typography
//                     variant="h4"
//                     textAlign="center"
//                     style={{ marginTop: "10px" }}
//                     color="purple"
//                   >
//                     Basic Information
//                   </Typography>
//                 </Grid>
//                 <Grid container spacing={2}>
//                   {/* Email, Phone Number, Mobile Number */}
//                   <Grid item xs={12} sm={3}>
//                     <TextField
//                       label="Firm / Institute Name"
//                       name="institutename"
//                       value={formData.institutename}
//                       onChange={handleChange}
//                       fullWidth
//                       required
//                       // error!!errors.institutename}
//                       //helperText={errors.institutename}
//                       InputLabelProps={{
//                         className: "required-asterisk",
//                       }}
//                     />
//                   </Grid>

//                   <Grid item xs={12} sm={3}>
//                     <TextField
//                       label="Admin Phone Number"
//                       name="phonenumber"
//                       value={formData.phonenumber}
//                       onChange={handleChange}
//                       fullWidth
//                       required
//                       // error!!errors.phonenumber}
//                       //helperText={errors.phonenumber}
//                       InputLabelProps={{
//                         className: "required-asterisk",
//                       }}
//                     />
//                   </Grid>
//                   <Grid item xs={12} sm={3}>
//                     <TextField
//                       label="Firm / Institute Mobile Number"
//                       name="mobilenumber"
//                       value={formData.mobilenumber}
//                       onChange={handleChange}
//                       fullWidth
//                       required
//                       // error!!errors.mobilenumber}
//                       //helperText={errors.mobilenumber}
//                       InputLabelProps={{
//                         className: "required-asterisk",
//                       }}
//                     />
//                   </Grid>

//                   <Grid item xs={12} sm={3}>
//                     <TextField
//                       label="Owner's Aadhar No."
//                       name="aadhar"
//                       value={formData.aadhar}
//                       onChange={handleChange}
//                       fullWidth
//                       // error!!errors.aadhar}
//                       //helperText={errors.aadhar}
//                       required
//                       InputLabelProps={{
//                         className: "required-asterisk",
//                       }}
//                     />
//                   </Grid>
//                   <Grid item xs={12} sm={3}>
//                     <TextField
//                       label="Pan No."
//                       name="pancard"
//                       value={formData.pancard}
//                       onChange={handleChange}
//                       fullWidth
//                       // error!!errors.pan}
//                       //helperText={errors.pan}
//                       required
//                       InputLabelProps={{
//                         className: "required-asterisk",
//                       }}
//                     />
//                   </Grid>

//                   <Grid item xs={12} sm={3}>
//                     <TextField
//                       label="Firm / Institute Address"
//                       name="address"
//                       value={formData.address}
//                       onChange={handleChange}
//                       multiline
//                       // rows={4}
//                       fullWidth
//                       InputLabelProps={{ className: "required-asterisk" }}
//                       required
//                     />
//                   </Grid>
//                   <Grid item xs={12} sm={3}>
//                     <TextField
//                       label="Landmark"
//                       name="landmark"
//                       value={formData.landmark}
//                       onChange={handleChange}
//                       fullWidth
//                     />
//                   </Grid>

//                   {/* Country, State, District */}
//                   <Grid item xs={12} sm={3}>
//                     <FormControl fullWidth>
//                       <TextField
//                         select
//                         label="Country"
//                         name="country"
//                         value={formData.country}
//                         onChange={handleChange}
//                       >
//                         <MenuItem value="India">India</MenuItem>
//                       </TextField>
//                     </FormControl>
//                   </Grid>
//                   <Grid item xs={12} sm={3}>
//                     <FormControl fullWidth>
//                       <TextField
//                         select
//                         label="State"
//                         name="state"
//                         value={formData.state}
//                         onChange={handleChange}
//                         InputLabelProps={{ className: "required-asterisk" }}
//                         required
//                       >
//                         {state.map((state) => (
//                           <MenuItem key={state} value={state}>
//                             {state}
//                           </MenuItem>
//                         ))}
//                       </TextField>
//                     </FormControl>
//                   </Grid>
//                   <Grid item xs={12} sm={3}>
//                     <FormControl fullWidth>
//                       <TextField
//                         select
//                         label="District"
//                         name="district"
//                         value={formData.district}
//                         onChange={handleChange}
//                         disabled={!formData.state}
//                         InputLabelProps={{ className: "required-asterisk" }}
//                         required
//                       >
//                         {district.map((district) => (
//                           <MenuItem key={district} value={district}>
//                             {district}
//                           </MenuItem>
//                         ))}
//                       </TextField>
//                     </FormControl>
//                   </Grid>

//                   {/* City, Registration Number */}
//                   <Grid item xs={12} sm={3}>
//                     <FormControl fullWidth required>
//                       <TextField
//                         label="City"
//                         name="city"
//                         value={formData.city}
//                         onChange={handleChange}
//                         // error!!errors.city}
//                         InputLabelProps={{ className: "required-asterisk" }}
//                         required
//                       ></TextField>
//                     </FormControl>
//                   </Grid>
//                   <Grid item xs={12} sm={3}>
//                     <TextField
//                       label="Pincode"
//                       name="pincode"
//                       required
//                       value={formData.pincode}
//                       onChange={handleChange}
//                       fullWidth
//                       InputLabelProps={{ className: "required-asterisk" }}
//                     />
//                   </Grid>
//                   <Grid item xs={12} sm={3}>
//                     <FormControlLabel
//                       control={
//                         <Checkbox
//                           checked={showOptionalFields}
//                           onChange={handleCheckboxChange}
//                           color="primary"
//                         />
//                       }
//                       label="Website & GST (Optional)"
//                     />
//                   </Grid>

//                   {/* Conditionally render Website Link and GST No fields */}
//                   {showOptionalFields && (
//                     <>
//                       <Grid item xs={12} sm={3}>
//                         <TextField
//                           label="Website Link"
//                           name="websitename"
//                           value={formData.websitename}
//                           onChange={handleChange}
//                           fullWidth
//                         />
//                       </Grid>
//                       <Grid item xs={12} sm={3}>
//                         <TextField
//                           label="GST No."
//                           name="gstNo"
//                           value={formData.gstNo}
//                           onChange={handleChange}
//                           fullWidth
//                         />
//                       </Grid>
//                     </>
//                   )}
//                 </Grid>
//               </Grid>
//             </Paper>
//           </div>
//         )}
//       </div>
//     </>
//   );
// };

// export default CreateAccount;

import React, { useState } from "react";
import OrganizationPlans from "./OrganizationPlans"; // Import OrganizationPlans component
import InstituteOrgPlan from "./InstituteOrgPlan"; // Import InstituteOrgPlan component
import { AppBar, Toolbar, Grid, IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Login as LoginIcon } from "@mui/icons-material";
import logo from "../../img/logo.jpg";
import Plans from "./Plans"
import "./CreateAccount.css"

const CreateAccount = () => {
  const [activeTab, setActiveTab] = useState("School / College");
  const navigate = useNavigate();

  // Handle card click to navigate to the plan details page
  const handleCardClick = (plan, section) => {
    navigate("/plan-details", { state: { plan, section } });
  };

  const buttonLabels = [
    { id: "School / College", label: "School / College" },
    { id: "Organization / Company", label: "Organization / Company" },
    { id: "Institute / Academy", label: "Institute / Academy" },
  ];

  return (
    <>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, background: "#00649F" }}>
        <Toolbar>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item display="flex" alignItems="center">
              <img src={logo} alt="Logo" style={{ marginRight: "8px", height: "30px" }} />
              <Typography variant="h5" color="white" fontWeight="bold">
                PJSOFTTECH
              </Typography>
            </Grid>
          </Grid>
          <IconButton edge="end" color="inherit" onClick={() => navigate("/")} sx={{ position: "absolute", right: 16 }}>
            <LoginIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <div className="create-account" style={{ paddingTop: "70px" }}>
       
        <div className="button-group-toggle">
          {buttonLabels.map((button) => (
            <button
              key={button.id}
              className={activeTab === button.id ? "active" : ""}
              onClick={() => setActiveTab(button.id)}
            >
              {button.label}
            </button>
          ))}
        </div>
        <h2>Select plan To Continue</h2>

        {/* Conditionally render sections based on activeTab */}
        {activeTab === "School / College" && (
          <Plans  onCardClick={(plan) => handleCardClick(plan, "School / College")} />
        )}
        {activeTab === "Organization / Company" && (
          <OrganizationPlans onCardClick={(plan) => handleCardClick(plan, "Organization / Company")} />
        )}
        {activeTab === "Institute / Academy" && (
          <InstituteOrgPlan onCardClick={(plan) => handleCardClick(plan, "Institute / Academy")} />
        )}
      </div>
    </>
  );
};

export default CreateAccount;
