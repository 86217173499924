import React, { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import {
  Box,
  MenuItem,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import { fetchRevenueByPayingDate } from "./PayrollAPI";

const monthsList = [
  { month: "January", count: 0 },
  { month: "February", count: 0 },
  { month: "March", count: 0 },
  { month: "April", count: 0 },
  { month: "May", count: 0 },
  { month: "June", count: 0 },
  { month: "July", count: 0 },
  { month: "August", count: 0 },
  { month: "September", count: 0 },
  { month: "October", count: 0 },
  { month: "November", count: 0 },
  { month: "December", count: 0 },
];

const customTheme = {
  border: {
    light: "#d3d3d3",
    dark: "#333",
  },
  grid: {
    stroke: "#eee",
    strokeWidth: 1,
  },
};

export default function AllMonthsSalaryRevenueChart() {
  const getInstituteCode = () => localStorage.getItem("institutecode");
  const [data, setData] = useState(monthsList);
  const [year, setYear] = useState(2024); 
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 15 }, (_, i) => currentYear - 7 + i);



  useEffect(() => {
    const fetchData = async () => {
      try {
        const instituteCode = getInstituteCode();
        const apiData = await fetchRevenueByPayingDate(instituteCode, year); 

        const updatedData = monthsList.map((monthObj) => {
          const monthData = apiData.find((item) => item[0] === monthObj.month);
          return {
            month: monthObj.month.slice(0, 3), 
            count: monthData ? monthData[1] : 0,
          };
        });

        setData(updatedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [year]); 

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "400px",
    }}>
      <div style={{
          display: "flex",
          justifyContent: "space-between", 
          alignItems: "center", 
          width: "100%", 
          marginBottom: "16px", 
        }}>
          <Typography style={{ fontWeight: "bold" }}>
            All Months Salary Revenue By Year
          </Typography>
          <TextField
          className="textField-root"
            size="small"
            select
            label="Year"
            value={year}
            onChange={handleYearChange}
            style={{ width: "100px" }} 
          >
            {years.map((yr) => (
              <MenuItem key={yr} value={yr}>
                {yr}
              </MenuItem>
            ))}
          </TextField>
      </div>
      <div style={{ width: "90%", marginTop: "16px" }}>
        <Box height={330}>
          <ResponsiveBar
            data={data}
            keys={["count"]}
            indexBy="month"
            margin={{ top: 20, right: 30, bottom: 50, left: 60 }}
            padding={0.5}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            colors={() => "#3498DB"} 
            borderColor={customTheme.border.light}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Month",
              legendPosition: "middle",
              legendOffset: 32,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Count",
              legendPosition: "middle",
              legendOffset: -40,
            }}
            enableLabel={false}
            theme={customTheme}
          />
        </Box>
      </div>
    </div>
  );
}
