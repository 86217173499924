// import React, { useState } from "react";
// import { Button, Box, Typography } from "@mui/material";
// import Exam from "../Enquiry/ExamPages/Exam"; // Replace with the actual Exam component file path
// import Source from "../Enquiry/ConductBy/Sources/Source"; // Create this component
// import Conduct from "../Enquiry/ConductBy/Conduct"; // Create this component

// const EnquirySetting = () => {
//   // State to track which component to render, defaulting to "exam"
//   const [selectedComponent, setSelectedComponent] = useState("exam");

//   const handleButtonClick = (component) => {
//     setSelectedComponent(component);
//   };

//   return (
//     <Box sx={{ padding: "20px" }}>
//       {/* Buttons */}
//       <Box sx={{ display: "flex", gap: "15px", marginBottom: "20px" }} justifyContent={"space-evenly"}>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={() => handleButtonClick("exam")}
//         >
//           Add Exam/Course
//         </Button>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={() => handleButtonClick("source")}
//         >
//           Add Source
//         </Button>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={() => handleButtonClick("conduct")}
//         >
//           Add Conduct
//         </Button>
//       </Box>

//       {/* Conditionally render the selected component */}
//       <Box>
//         {selectedComponent === "exam" && <Exam />}
//         {selectedComponent === "source" && <Source />}
//         {selectedComponent === "conduct" && <Conduct />}
//       </Box>
//     </Box>
//   );
// };

// export default EnquirySetting;

import React, { useState } from "react";
import { Box, Typography, Paper } from "@mui/material";
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import ExamIcon from "@mui/icons-material/Quiz"; // Replace with a suitable icon for "Add Exam/Course"
import SourceIcon from "@mui/icons-material/Source"; // Replace with a suitable icon for "Add Source"
import ConductIcon from "@mui/icons-material/School"; // Replace with a suitable icon for "Add Conduct"
import Exam from "../Enquiry/ExamPages/Exam"; // Your Exam component
import Source from "../Enquiry/ConductBy/Sources/Source"; // Your Source component
import Conduct from "../Enquiry/ConductBy/Conduct"; // Your Conduct component
import QrCode from "./QrCode";

const EnquirySetting = () => {
  // State to track which component to render
  const [selectedComponent, setSelectedComponent] = useState("exam");

  const handleButtonClick = (component) => {
    setSelectedComponent(component);
  };

  return (
    <Box sx={{ padding: "10px", display: "flex", justifyContent: "center" }}>
      {/* Sidebar Buttons */}
      <Paper
        elevation={3}
        sx={{
          width: "220px",
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          height: "200px",
          justifyContent: "space-evenly",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          backgroundColor: "#f0f0f0",
        }}
      >
        {/* Add Exam/Course Button */}
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
            padding: "5px 10px",
            color: selectedComponent === "exam" ? "white" : "text.primary",
            fontWeight: selectedComponent === "exam" ? "bold" : "normal",
            borderRadius: selectedComponent === "exam" ? "10px" : "4px",
            backgroundColor: selectedComponent === "exam" ? "#3498DB" : "",
            textAlign: "center",
            transition: "all 0.3s ease",
            fontSize: "13px",
            boxShadow:
              selectedComponent === "exam"
                ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                : "none",
          }}
          onClick={() => handleButtonClick("exam")}
        >
          <ExamIcon /> Add Exam/Course
        </Typography>

        {/* Add Source Button */}
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
            padding: "5px 10px",
            color: selectedComponent === "source" ? "white" : "text.primary",
            fontWeight: selectedComponent === "source" ? "bold" : "normal",
            borderRadius: selectedComponent === "source" ? "10px" : "4px",
            backgroundColor: selectedComponent === "source" ? "#3498DB" : "",
            textAlign: "center",
            transition: "all 0.3s ease",
            fontSize: "13px",
            boxShadow:
              selectedComponent === "source"
                ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                : "none",
          }}
          onClick={() => handleButtonClick("source")}
        >
          <SourceIcon /> Add Source
        </Typography>

        {/* Add Conduct Button */}
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
            padding: "5px 10px",
            color: selectedComponent === "conduct" ? "white" : "text.primary",
            fontWeight: selectedComponent === "conduct" ? "bold" : "normal",
            borderRadius: selectedComponent === "conduct" ? "10px" : "4px",
            backgroundColor: selectedComponent === "conduct" ? "#3498DB" : "",
            textAlign: "center",
            transition: "all 0.3s ease",
            fontSize: "13px",
            boxShadow:
              selectedComponent === "conduct"
                ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                : "none",
          }}
          onClick={() => handleButtonClick("conduct")}
        >
          <ConductIcon /> Add Conduct
        </Typography>


        {/* Add Conduct Button */}
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
            padding: "5px 10px",
            color: selectedComponent === "qrcode" ? "white" : "text.primary",
            fontWeight: selectedComponent === "qrcode" ? "bold" : "normal",
            borderRadius: selectedComponent === "qrcode" ? "10px" : "4px",
            backgroundColor: selectedComponent === "qrcode" ? "#3498DB" : "",
            textAlign: "center",
            transition: "all 0.3s ease",
            fontSize: "13px",
            boxShadow:
              selectedComponent === "qrcode"
                ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                : "none",
          }}
          onClick={() => handleButtonClick("qrcode")}
        >
          <QrCodeScannerIcon /> Generate QR
        </Typography>
      </Paper>

      {/* Content Area */}
      <Box sx={{ flex: 1, marginLeft: "30px", padding: "20px" }}>
        {selectedComponent === "exam" && <Exam />}
        {selectedComponent === "source" && <Source />}
        {selectedComponent === "conduct" && <Conduct />}
        {selectedComponent === "qrcode" && <QrCode />}
      </Box>
    </Box>
  );
};

export default EnquirySetting;
