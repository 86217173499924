import React, { useState } from "react";
import {
  Grid,
  Paper,
  Box,
  MenuItem,
  FormControl,
  TextField,
} from "@mui/material";
import AllMonthsMonthlySalaryCount from "./AllMonthsMonthlySalaryCount";
import AllMonthsSalaryRevenue from "./AllMonthsSalaryRevenue";
import PendingAmountCount from "./PendingAmountCount";
import PendingAmountRevenue from "./PendingAmountRevenue";
import Installment1CountRevenue from "./Installment1CountRevenue";
import Installment2CountRevenue from "./Installment2CountRevenue";
import Installment3CountRevenue from "./Installment3CountRevenue";
import AllMonthsSalaryCountChart from "./AllMonthsSalaryCountChart";
import AllMonthsSalaryRevenueChart from "./AllMonthsSalaryRevenueChart";
import TwoYearComparison from "./TwoYearComparison";
import AllDayHourlySalaryCountRevenue from "./AllDayHourlySalaryCountRevenue";

const PayrollDashboard = () => {
  const [selectedOption, setSelectedOption] = useState("Monthly Salary Count"); // Default selection is 'All'

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const renderSection = () => {
    switch (selectedOption) {
      case "All":
        return (
          <>
            <Grid container xs={12}>
              <Grid item xs={12}>
                <AllMonthsMonthlySalaryCount />
              </Grid>
              <Grid item xs={12} mt={-3}>
                <AllMonthsSalaryRevenue />
              </Grid>
              <Grid item xs={12} mt={-3}>
                <PendingAmountCount />
              </Grid>
              <Grid item xs={12} mt={-3}>
                <PendingAmountRevenue />
              </Grid>
              <Grid item xs={12}>
                <Installment1CountRevenue />
              </Grid>
              <Grid item xs={12}>
                <Installment2CountRevenue />
              </Grid>
              <Grid item xs={12}>
                <Installment3CountRevenue />
              </Grid>
            </Grid>
          </>
        );
      case "Monthly Salary Count":
        return (
          <Grid item xs={12}>
            <AllMonthsMonthlySalaryCount />
          </Grid>
        );
      case "Monthly Salary Revenue":
        return (
          <Grid item xs={12}>
            <AllMonthsSalaryRevenue />
          </Grid>
        );
      case "Pending Salary Count":
        return (
          <Grid item xs={12}>
            <PendingAmountCount />
          </Grid>
        );
      case "Pending Salary Revenue":
        return (
          <Grid item xs={12}>
            <PendingAmountRevenue />
          </Grid>
        );
      case "Installment 1":
        return (
          <Grid item xs={12} mt={2}>
            <Installment1CountRevenue />
          </Grid>
        );
      case "Installment 2":
        return (
          <Grid item xs={12} mt={2}>
            <Installment2CountRevenue />
          </Grid>
        );
      case "Installment 3":
        return (
          <Grid item xs={12} mt={2}>
            <Installment3CountRevenue />
          </Grid>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <Box sx={{ textAlign: "left", marginBottom: "20px" }}>
        {/* Dropdown for selecting different views */}
        <FormControl
          variant="outlined"
          size="small"
          style={{ mb: 2, minWidth: 120 }}
          className="textField-root"
        >
          <TextField
            select
            value={selectedOption}
            onChange={handleSelectChange}
            label="Select Period"
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Monthly Salary Count">
              Monthly Salary Count
            </MenuItem>
            <MenuItem value="Monthly Salary Revenue">
              Monthly Salary Revenue
            </MenuItem>
            <MenuItem value="Pending Salary Count">
              Pending Salary Count
            </MenuItem>
            <MenuItem value="Pending Salary Revenue">
              Pending Salary Revenue
            </MenuItem>
            <MenuItem value="Installment 1">Installment 1</MenuItem>
            <MenuItem value="Installment 2">Installment 2</MenuItem>
            <MenuItem value="Installment 3">Installment 3</MenuItem>
          </TextField>
        </FormControl>

        {/* Conditionally render the components based on the dropdown selection */}
        {renderSection()}

        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={6} style={{ padding: "16px" }}>
            <Paper elevation={3} style={{ padding: "16px" }}>
              <AllMonthsSalaryCountChart />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} style={{ padding: "16px" }}>
            <Paper elevation={3} style={{ padding: "16px" }}>
              <AllMonthsSalaryRevenueChart />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} style={{ padding: "16px" }}>
            <Paper elevation={3} style={{ padding: "16px" }}>
              <TwoYearComparison />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} style={{ padding: "16px" }}>
            <Paper elevation={3} style={{ padding: "16px" }}>
              <AllDayHourlySalaryCountRevenue/>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default PayrollDashboard;
