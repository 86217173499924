import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { fetchExams, saveExam, deleteExamById } from './PayrollAPI'; // Import extracted APIs

const MySwal = withReactContent(Swal);

const Exam = () => {
  const getInstituteCode = () => localStorage.getItem("institutecode");
  const [exams, setExams] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [examData, setExamData] = useState({ id: null, exam: "" });
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    loadExams();
  }, []);

  const loadExams = async () => {
    try {
      const data = await fetchExams(getInstituteCode());
      setExams(data);
    } catch (error) {
      MySwal.fire("Error", "Failed to load exams.", "error");
    }
  };

  const handleOpenAddDialog = () => {
    setExamData({ id: null, exam: "" });
    setEditing(false);
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const onAddExamSubmit = async (e) => {
    e.preventDefault();
    if (!examData.exam) {
      MySwal.fire("Error", "Please provide an exam name.", "error");
      return;
    }

    try {
      await saveExam(examData, getInstituteCode(), editing);
      MySwal.fire(
        "Success",
        editing ? "The exam has been updated." : "The exam has been added.",
        "success"
      );
      loadExams();
      handleCloseAddDialog();
    } catch (error) {
      MySwal.fire("Error", "Failed to save the exam.", "error");
    }
  };

  const handleEditExam = (exam) => {
    setExamData({ id: exam.id, exam: exam.exam });
    setEditing(true);
    handleOpenAddDialog();
  };

  const handleDeleteExam = (exam) => {
    MySwal.fire({
      title: "Are you sure?",
      text: `Do you want to delete the exam: ${exam.exam}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteExam(exam.id);
      }
    });
  };

  const deleteExam = async (examId) => {
    try {
      await deleteExamById(examId);
      MySwal.fire("Deleted!", "The exam has been deleted.", "success");
      loadExams();
    } catch (error) {
      MySwal.fire("Error", "Failed to delete the exam.", "error");
    }
  };

  const filteredExams = exams.filter((exam) =>
    exam.exam.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <Grid container spacing={2} sx={{ marginTop: 3 }} className="textField-root">
        <Typography variant="h6" gutterBottom sx={{ marginTop: 3 }}>
          Total Exams: {filteredExams.length}
        </Typography>
        <Grid item xs={12} sm={2.5}>
          <TextField
            label="Search Exam"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
            placeholder="Search Exam"
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button variant="contained" color="primary" onClick={handleOpenAddDialog}>
            Add Exam
          </Button>
        </Grid>
      </Grid>

      <Box mt={2}>
        <TableContainer>
        <Table  className="table-root">
            <TableHead>
              <TableRow>
                <TableCell>Exam ID</TableCell>
                <TableCell>Exam Name</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredExams.map((exam) => (
                <TableRow key={exam.id}>
                  <TableCell>{exam.id}</TableCell>
                  <TableCell>{exam.exam}</TableCell>
                  <TableCell>
                    <Button
                      color="error"
                      onClick={() => handleDeleteExam(exam)}
                      size="small"
                      variant="contained"
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Dialog open={openAddDialog} onClose={handleCloseAddDialog}>
        <DialogTitle>{editing ? "Edit Exam" : "Add Exam"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {editing ? "Edit the exam." : "Add a new exam."}
          </DialogContentText>
          <form onSubmit={onAddExamSubmit} className="textField-root">
            <TextField
              autoFocus
              margin="dense"
              label="Exam Name"
              fullWidth
              variant="outlined"
              value={examData.exam}
              onChange={(e) => setExamData({ ...examData, exam: e.target.value })}
            />
            <DialogActions>
              <Button onClick={handleCloseAddDialog} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                {editing ? "Update" : "Add"}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Exam;
