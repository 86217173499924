// VendorName.js
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Grid, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { getVendorNames, deleteVendorName, createVendorName } from './API/ShipmentAPI';  // Import the API functions

const MySwal = withReactContent(Swal);

const VendorName = () => {
  const { id } = useParams(); // Make sure id is correctly used
  const [vendorNames, setVendorNames] = useState([]);
  const [filteredVendorNames, setFilteredVendorNames] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openAddDialog, setOpenAddDialog] = useState(false); // For add Vendor Name dialog
  const [vendorName, setVendorName] = useState({ vendorName: "" });

  const institutecode = localStorage.getItem("institutecode");

  // Load vendor names when the component mounts or the institutecode changes
  useEffect(() => {
    loadVendorNames();
  }, [institutecode]);

  // Filter vendor names based on the search term
  useEffect(() => {
    filterVendorNames();
  }, [searchTerm, vendorNames]);

  // Load vendor names from the server
  const loadVendorNames = async () => {
    try {
      const data = await getVendorNames();
      setVendorNames(data);
      setFilteredVendorNames(data);
    } catch (error) {
      console.error("Error fetching Vendor Names:", error);
    }
  };

  // Filter the vendor names list based on the search term
  const filterVendorNames = () => {
    const filtered = vendorNames.filter((vendor) => {
      if (vendor.vendorName && vendor.vendorName.toLowerCase) {
        return vendor.vendorName.toLowerCase().includes(searchTerm.toLowerCase());
      }
      return false;
    });
    setFilteredVendorNames(filtered);
  };

  // Handle deleting a vendor name
  const handleDeleteVendorName = (vendor) => {
    MySwal.fire({
      title: "Are you sure?",
      text: `Do you want to delete vendor name: ${vendor.vendorName}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteVendor(vendor.id);
      }
    });
  };

  // Delete the vendor name by ID
  const deleteVendor = async (vendorId) => {
    try {
      await deleteVendorName(vendorId);
      MySwal.fire({
        title: "Deleted!",
        text: "The vendor name has been deleted successfully.",
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
      });
      loadVendorNames();
    } catch (error) {
      console.error("Error deleting vendor name:", error);
      MySwal.fire({
        title: "Error!",
        text: "There was an error deleting the vendor name.",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  // Open the dialog to add a new vendor name
  const handleOpenAddDialog = () => {
    setVendorName({ vendorName: "" }); // Clear the input field
    setOpenAddDialog(true);
  };

  // Close the dialog
  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  // Handle submitting the form to add a new vendor name
  const onAddVendorNameSubmit = async (e) => {
    e.preventDefault();
    if (!vendorName.vendorName) {
      MySwal.fire({
        title: "Error!",
        text: "Please fill out the necessary fields.",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      });
      return;
    }

    try {
      // Send the POST request to create a new vendor name
      await createVendorName(vendorName.vendorName);
      MySwal.fire({
        title: "Success!",
        text: "The vendor name has been added successfully.",
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
      });
      handleCloseAddDialog();
      loadVendorNames();
    } catch (error) {
      console.error("There was an error adding the vendor name!", error);
      MySwal.fire({
        title: "Error!",
        text: "There was an error adding the vendor name.",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  return (
    <div sx={{ marginTop: "10px" }}>
      <Grid container spacing={2} sx={{ marginTop: "10px" }}>
        <Typography variant="h6" gutterBottom sx={{ marginTop: 3, whiteSpace: "nowrap" }}>
          Total Vendor Names: {filteredVendorNames.length}
        </Typography>

        <Grid item xs={12} sm={1.6} className="textField-root">
          <TextField
            label="Search Vendor Name"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenAddDialog}
            sx={{ marginTop: 1 }}
          >
            Add Vendor Name
          </Button>
        </Grid>
      </Grid>

      <Box mt={2}>
        <TableContainer>
          <Table className="table-root">
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Vendor Name</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredVendorNames.map((vendor, index) => (
                <TableRow key={vendor.id}>
                  <TableCell>{vendor.id}</TableCell>
                  <TableCell>{vendor.vendorName}</TableCell>
                  <TableCell>
                    <Button
                      color="error"
                      onClick={() => handleDeleteVendorName(vendor)}
                      size="small"
                      variant="contained"
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Add Vendor Name Dialog */}
      <Dialog open={openAddDialog} onClose={handleCloseAddDialog}>
        <DialogTitle>Add Vendor Name</DialogTitle>
        <DialogContent>
          <DialogContentText>Add a new vendor name.</DialogContentText>
          <form onSubmit={onAddVendorNameSubmit}>
            <TextField
              autoFocus
              margin="dense"
              label="Vendor Name"
              fullWidth
              variant="outlined"
              value={vendorName.vendorName} // Bind the field to 'vendorName.vendorName'
              onChange={(e) => setVendorName({ ...vendorName, vendorName: e.target.value })} // Update 'vendorName' correctly
            />

            <DialogActions>
              <Button onClick={handleCloseAddDialog} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Add
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default VendorName;
