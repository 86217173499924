import React from "react";
import { Routes, Route } from "react-router-dom";
import PayrollManagerLayout from "./PayrollManagerLayout";
import PayrollDashboard from "./PayrollDashboard";
import AddTeacher from "./AddTeacher";
import AddSubject from "./AddSubject";
import HourlySalary from "./HourlySalary";
import HourlyRate from "./HourlyRate";
import TeacherExam from "./TeacherExam";
import TeacherList from "./ManageTeacher";
import MonthlySalary from "./MonthlySalary";
import AddMonthlySalary from "./AddMonthlySalary";
import PayrollSetting from "./PayrollSetting";

export default function PayrollManager() {
  return (
    <Routes>
      <Route path="/" element={<PayrollManagerLayout />}>
        <Route index element={<PayrollDashboard />} />
        <Route path="monthly-salary" element={<MonthlySalary />} />
        <Route path="hourly-salary" element={<HourlySalary />} />
        <Route path="teachers-list" element={<TeacherList />} />
        <Route path="add-teacher" element={<AddTeacher />} />
        <Route path="add-monthlysalary" element={<AddMonthlySalary />} />

        <Route path="PayrollSetting" element={<PayrollSetting />} />
        <Route path="hourly-rate" element={<HourlyRate />} />
        <Route path="add-subject" element={<AddSubject />} />
        <Route path="add-exam" element={<TeacherExam />} />
      </Route>
    </Routes>
  );
}
