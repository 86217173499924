import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Grid, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getStatuses, deleteStatus, addStatus } from './API/ShipmentAPI';  // Import API functions

const MySwal = withReactContent(Swal);

const Status = () => {
  const { id } = useParams();
  const [statuses, setStatuses] = useState([]);
  const [filteredStatuses, setFilteredStatuses] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [status, setStatus] = useState({ status: '' });
  const institutecode = localStorage.getItem('institutecode') || '';

  useEffect(() => {
    loadStatuses();
  }, [institutecode]);

  useEffect(() => {
    filterStatuses();
  }, [searchTerm, statuses]);

  // Fetch statuses from API
  const loadStatuses = async () => {
    try {
      const data = await getStatuses(institutecode);
      setStatuses(data);
      setFilteredStatuses(data);
    } catch (error) {
      console.error('Error fetching statuses:', error);
      MySwal.fire({
        title: 'Error!',
        text: 'Failed to load statuses.',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  // Filter statuses based on the search term
  const filterStatuses = () => {
    const filtered = statuses.filter((status) => {
      if (status.status && status.status.toLowerCase) {
        return status.status.toLowerCase().includes(searchTerm.toLowerCase());
      }
      return false;
    });
    setFilteredStatuses(filtered);
  };

  // Handle status deletion
  const handleDeleteStatus = (status) => {
    MySwal.fire({
      title: 'Are you sure?',
      text: `Do you want to delete status: ${status.status}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteStatusHandler(status.id);
      }
    });
  };

  // Delete status by ID
  const deleteStatusHandler = async (statusId) => {
    try {
      await deleteStatus(statusId);
      MySwal.fire({
        title: 'Deleted!',
        text: 'The status has been deleted successfully.',
        icon: 'success',
        timer: 1500,
        showConfirmButton: false,
      });
      loadStatuses();
    } catch (error) {
      console.error('Error deleting status:', error);
      MySwal.fire({
        title: 'Error!',
        text: 'There was an error deleting the status.',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  // Open dialog to add a new status
  const handleOpenAddDialog = () => {
    setStatus({ status: '' });
    setOpenAddDialog(true);
  };

  // Close the dialog
  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  // Handle adding a new status
  const onAddStatusSubmit = async (e) => {
    e.preventDefault();
    if (!status.status) {
      MySwal.fire({
        title: 'Error!',
        text: 'Please fill out the necessary fields.',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false,
      });
      return;
    }

    try {
      await addStatus(status, institutecode);
      MySwal.fire({
        title: 'Success!',
        text: 'The status has been added successfully.',
        icon: 'success',
        timer: 1500,
        showConfirmButton: false,
      });
      handleCloseAddDialog();
      loadStatuses();
    } catch (error) {
      console.error('Error adding status:', error);
      MySwal.fire({
        title: 'Error!',
        text: 'There was an error adding the status.',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  return (
    <div sx={{ marginTop: '10px' }}>
      <Grid container spacing={2} sx={{ marginTop: '10px' }}>
        <Typography variant="h6" gutterBottom sx={{ marginTop: 3, whiteSpace: 'nowrap' }}>
          Total Status: {filteredStatuses.length}
        </Typography>

        <Grid item xs={12} sm={1.6} className="textField-root">
          <TextField
            label="Search Status"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenAddDialog}
            sx={{ marginTop: 1 }}
          >
            Add Status
          </Button>
        </Grid>
      </Grid>

      <Box mt={2}>
        <TableContainer>
          <Table className="table-root">
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredStatuses.map((status) => (
                <TableRow key={status.id}>
                  <TableCell>{status.id}</TableCell>
                  <TableCell>{status.status}</TableCell>
                  <TableCell>
                    <Button
                      color="error"
                      onClick={() => handleDeleteStatus(status)}
                      size="small"
                      variant="contained"
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Add Status Dialog */}
      <Dialog open={openAddDialog} onClose={handleCloseAddDialog}>
        <DialogTitle>Add Status</DialogTitle>
        <DialogContent>
          <DialogContentText>Add new status.</DialogContentText>
          <form onSubmit={onAddStatusSubmit}>
            <TextField
              autoFocus
              margin="dense"
              label="Status Name"
              fullWidth
              variant="outlined"
              value={status.status}
              onChange={(e) => setStatus({ ...status, status: e.target.value })}
            />
            <DialogActions>
              <Button onClick={handleCloseAddDialog} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Add
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Status;
