// import React, { useState } from "react";
// import { Button, Box, Typography } from "@mui/material";
// import AddCategory from "../Income Expense/AddCategory"; // Replace with the actual Exam component file path
// import AddUser from "../Income Expense/AddUser"; // Create this component

// const IncomeExpenceSetting = () => {
//   // State to track which component to render, defaulting to "exam"
//   const [selectedComponent, setSelectedComponent] = useState("AddCategory");

//   const handleButtonClick = (component) => {
//     setSelectedComponent(component);
//   };

//   return (
//     <Box sx={{ padding: "20px" }}>
//       {/* Buttons */}
//       <Box sx={{ display: "flex", gap: "15px", marginBottom: "20px" }} justifyContent={"space-evenly"}>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={() => handleButtonClick("AddCategory")}
//         >
//           Add Category
//         </Button>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={() => handleButtonClick("AddUser")}
//         >
//           Add User
//         </Button>
//       </Box>

//       {/* Conditionally render the selected component */}
//       <Box>
//         {selectedComponent === "AddCategory" && <AddCategory />}
//         {selectedComponent === "AddUser" && <AddUser />}
//       </Box>
//     </Box>
//   );
// };

// export default IncomeExpenceSetting;


import React, { useState } from "react";
import { Box, Typography, Paper } from "@mui/material";
import CategoryIcon from "@mui/icons-material/Category";
import PersonAddIcon from "@mui/icons-material/PersonAdd"; // Replace with an appropriate icon for "Add User"
import AddCategory from "../Income Expense/AddCategory";
import AddUser from "../Income Expense/AddUser";

const IncomeExpenceSetting = () => {
  // State to track which component to render
  const [selectedComponent, setSelectedComponent] = useState("AddCategory");

  const handleButtonClick = (component) => {
    setSelectedComponent(component);
  };

  return (
    <Box sx={{ padding: "10px", display: "flex", justifyContent: "center" }}>
      {/* Sidebar Buttons */}
      <Paper
        elevation={3}
        sx={{
          width: "220px",
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          height: "150px",
          justifyContent: "space-evenly",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          backgroundColor: "#f0f0f0",
        }}
      >
        {/* Add Category */}
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
            padding: "5px 10px",
            color: selectedComponent === "AddCategory" ? "white" : "text.primary",
            fontWeight: selectedComponent === "AddCategory" ? "bold" : "normal",
            borderRadius: selectedComponent === "AddCategory" ? "10px" : "4px",
            backgroundColor: selectedComponent === "AddCategory" ? "#3498DB" : "",
            textAlign: "center",
            transition: "all 0.3s ease",
            fontSize: "13px",
            boxShadow:
              selectedComponent === "AddCategory"
                ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                : "none",
          }}
          onClick={() => handleButtonClick("AddCategory")}
        >
          <CategoryIcon /> Add Category
        </Typography>

        {/* Add User */}
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
            padding: "5px 10px",
            color: selectedComponent === "AddUser" ? "white" : "text.primary",
            fontWeight: selectedComponent === "AddUser" ? "bold" : "normal",
            borderRadius: selectedComponent === "AddUser" ? "10px" : "4px",
            backgroundColor: selectedComponent === "AddUser" ? "#3498DB" : "",
            textAlign: "center",
            transition: "all 0.3s ease",
            fontSize: "13px",
            boxShadow:
              selectedComponent === "AddUser"
                ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                : "none",
          }}
          onClick={() => handleButtonClick("AddUser")}
        >
          <PersonAddIcon /> Add User
        </Typography>
      </Paper>

      {/* Content Area */}
      <Box sx={{ flex: 1, marginLeft: "30px", padding: "20px" }}>
        {selectedComponent === "AddCategory" && <AddCategory />}
        {selectedComponent === "AddUser" && <AddUser />}
      </Box>
    </Box>
  );
};

export default IncomeExpenceSetting;
