import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Grid,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import Swal from 'sweetalert2';


const AddClass = () => {
  const [formData, setFormData] = useState({
    standardId: "",
    mediumId: "",
    divisionId: "",
    year: "",
  });
  const [classRooms, setClassRooms] = useState([]);
  const [standards, setStandards] = useState([]);
  const [mediums, setMediums] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentClassroomId, setCurrentClassroomId] = useState(null);
  const [error, setError] = useState("");
  // const [successMessage, setSuccessMessage] = useState("");

  const institutecode = localStorage.getItem("institutecode");

  useEffect(() => {
    fetchAllClassRooms();
    fetchDropdownOptions();
  }, []);

  // Fetch all classrooms
  const fetchAllClassRooms = async () => {
    try {
      const response = await axios.get(
        `https://pjsofttech.in:9443/getallclassrooms?institutecode=${institutecode}`
      );
      setClassRooms(response.data);
    } catch (error) {
      setError("Failed to fetch classrooms");
    }
  };

  // Fetch options for dropdowns
  const fetchDropdownOptions = async () => {
    try {
      const [standardsRes, mediumsRes, divisionsRes] = await Promise.all([
        axios.get(`https://pjsofttech.in:9443/all?institutecode=${institutecode}`),
        axios.get(`https://pjsofttech.in:9443/getall?institutecode=${institutecode}`),
        axios.get(`https://pjsofttech.in:9443/getallDivision?institutecode=${institutecode}`),
      ]);

      setStandards(standardsRes.data);
      setMediums(mediumsRes.data);
      setDivisions(divisionsRes.data);
    } catch (error) {
      setError("Failed to fetch dropdown options");
    }
  };

  // Handle form input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    const { standardId, mediumId, divisionId, year } = formData;

    if (!standardId || !mediumId || !divisionId || !year) {
      setError("All fields are required");
      return;
    }

    try {
      await axios.post("https://pjsofttech.in:9443/addclassroom", null, {
        params: {
          standardId,
          mediumId,
          divisionId,
          year,
          institutecode,
        },
      });
      Swal.fire("Success", "Classroom added successfully", "success");
      setFormData({
        standardId: "",
        mediumId: "",
        divisionId: "",
        year: "",
      });
      fetchAllClassRooms();
    } catch (error) {
      Swal.fire("Error", "Failed to add classroom", "error");
    }
  };

  const handleOpenDialog = async (classroomId) => {
    setCurrentClassroomId(classroomId);
  
    // Find the selected classroom details
    const selectedClassroom = classRooms.find(
      (classRoom) => classRoom.id === classroomId
    );
  
    if (!selectedClassroom) {
      Swal.fire("Error", "Classroom not found", "error");
      return;
    }
  
    const { standard, medium, year } = selectedClassroom;
  
    try {
      const response = await axios.get(
        `https://pjsofttech.in:9443/Studentfilter`, 
        {
          params: {
            standard: standard?.standard || "",
            mediumname: medium?.mediumname || "",
            year: year || "",
            institutecode,
          },
        }
      );
  
      if (response.data.length === 0) {
        Swal.fire("Error", "No students found for the selected criteria", "error");
      } else {
        setStudents(response.data);
        setOpenDialog(true);
        setError("");
      }
    } catch (error) {
      Swal.fire("Error", "Failed to fetch students", "error");
    }
  };
  
// Handle adding student to classroom
const handleAddStudent = async () => {
  if (!selectedStudent || !currentClassroomId) {
    Swal.fire("Error", "Please select a student and classroom", "error");
    return;
  }

  try {
    await axios.post(
      `https://pjsofttech.in:9443/addStudent`,
      null, // No body needed, params are passed in the URL
      {
        params: {
          classRoomId: currentClassroomId,
          studentId: selectedStudent,
        },
      }
    );
    Swal.fire("Success", "Student added to classroom successfully", "success");
    setOpenDialog(false);
    setSelectedStudent(null);
    fetchAllClassRooms(); // Refresh classroom data
  } catch (error) {
    Swal.fire("Error", `Failed to add student: ${error.response?.data || error.message}`, "error");
  }
};


  return (
    <div>
      <Box p={2}>
        <Paper elevation={3} style={{ padding: "20px" }}>
          <Grid container spacing={2} component="form" onSubmit={handleSubmit}  className="textField-root">
            {/* Standard Dropdown */}
            <Grid item xs={12} md={3}>
              <TextField
                select
                label="Standard"
                name="standardId"
                value={formData.standardId}
                onChange={handleChange}
                fullWidth
              >
                {standards.map((standard) => (
                  <MenuItem key={standard.sid} value={standard.sid}>
                    {standard.standard}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {/* Medium Dropdown */}
            <Grid item xs={12} md={3}>
              <TextField
                select
                label="Medium"
                name="mediumId"
                value={formData.mediumId}
                onChange={handleChange}
                fullWidth
              >
                {mediums.map((medium) => (
                  <MenuItem key={medium.mediumid} value={medium.mediumid}>
                    {medium.mediumname}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {/* Division Dropdown */}
            <Grid item xs={12} md={3}>
              <TextField
                select
                label="Division"
                name="divisionId"
                value={formData.divisionId}
                onChange={handleChange}
                fullWidth
              >
                {divisions.map((division) => (
                  <MenuItem key={division.did} value={division.did}>
                    {division.division}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {/* Year Field */}
            <Grid item xs={12} md={3}>
              <TextField
                label="Year"
                name="year"
                value={formData.year}
                onChange={handleChange}
                fullWidth
              />
            </Grid>

            {/* Submit Button */}
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <Button variant="contained" color="primary" type="submit">
                  Add Classroom
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Paper>

        {/* Classroom Table */}
        <Paper elevation={3} style={{ padding: "20px", marginTop: "30px" }}>
          <TableContainer component={Paper} className="table-root">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Classroom ID</TableCell>
                  <TableCell>Standard</TableCell>
                  <TableCell>Medium</TableCell>
                  <TableCell>Division</TableCell>
                  <TableCell>Year</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {classRooms.length > 0 ? (
                  classRooms.map((classRoom) => (
                    <TableRow key={classRoom.id}>
                      <TableCell>{classRoom.id}</TableCell>
                      <TableCell>{classRoom.standard?.standard || "N/A"}</TableCell>
                      <TableCell>{classRoom.medium?.mediumname || "N/A"}</TableCell>
                      <TableCell>{classRoom.division?.division || "N/A"}</TableCell>
                      <TableCell>{classRoom.year || "N/A"}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleOpenDialog(classRoom.id)}
                        >
                          Add Student
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No Classrooms Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        {/* Student Dialog */}
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>Select Student</DialogTitle>
          <DialogContent>
            <List>
              {students.map((student) => (
                <ListItem key={student.stdid}>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={selectedStudent === student.stdid}
                        onChange={() => setSelectedStudent(student.stdid)}
                        value={student.stdid}
                      />
                    }
                    label={`${student.full_name} (ID: ${student.stdid})`}
                  />
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAddStudent} color="primary" variant="contained">
              Add
            </Button>
            <Button onClick={() => setOpenDialog(false)} color="secondary" variant="outlined">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        {/* Error/Success Messages */}
        {error && <Box color="error.main">{error}</Box>}
        {/* {successMessage && <Box color="success.main">{successMessage}</Box>} */}
      </Box>
    </div>
  );
};

export default AddClass;
