
import React, { useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Paper,
  FormHelperText,
} from "@mui/material";
import { createTeacher } from "./PayrollAPI";

const AddTeacher = () => {
  const getInstituteCode = () => localStorage.getItem("institutecode");
  const instituteCode = getInstituteCode();
  const initialFormData = {
    teacherName: "",
    mobileNo: "",
    email: "",
    address: "",
    joiningDate: "",
    instituteCode: getInstituteCode(),
  };
  
  const [teacherData, setTeacherData] = useState(initialFormData);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTeacherData({
      ...teacherData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "", 
    });
  };

  const validateForm = () => {
    const newErrors = {};
    
    Object.keys(initialFormData).forEach((key) => {
      if (key !== "instituteCode" && !teacherData[key]) {
        newErrors[key] = "This field is required";
      }
    });

    const mobilePattern = /^[0-9]{10}$/;
    if (teacherData.mobileNo && !mobilePattern.test(teacherData.mobileNo)) {
      newErrors.mobileNo = "Mobile number must be 10 digits";
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (teacherData.email && !emailPattern.test(teacherData.email)) {
      newErrors.email = "Enter a valid email address";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; 
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      alert("Please fill all the necessary fields and fix the errors.");
      return;
    }

    try {
      const response = await createTeacher(teacherData, instituteCode);  
      if (response.status === 200) {
        alert("Teacher created successfully");
        setTeacherData(initialFormData); 
      } else {
        console.error("Failed to create teacher:", response.status);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <Paper
        elevation={3}
        style={{
          padding: "2%",
          borderRadius: "8px",
          backgroundColor: "#fff",
          boxShadow: "0 4px 8px rgba(70, 130, 180, 0.3)",
        }}
      >
        <form onSubmit={handleSubmit} className="textField-root">
          <Grid container spacing={2}>
            {[
              { label: "Teacher Name", name: "teacherName", type: "text" },
              { label: "Email", name: "email", type: "email" },
              { label: "Phone Number", name: "mobileNo", type: "tel" },
              { label: "Address", name: "address", type: "text" },
              { label: "Joining Date", name: "joiningDate", type: "date" },
            ].map((field) => (
              <Grid item xs={12} sm={4} key={field.name}>
                <TextField
                  fullWidth
                  label={field.label}
                  name={field.name}
                  type={field.type}
                  value={teacherData[field.name]}
                  onChange={handleChange}
                  variant="outlined"
                  InputLabelProps={
                    field.type === "date" ? { shrink: true } : {}
                  }
                  error={Boolean(errors[field.name])}
                />
                {errors[field.name] && (
                  <FormHelperText error>
                    {errors[field.name]}
                  </FormHelperText>
                )}
              </Grid>
            ))}

            
          </Grid>
          <Grid item xs={12} mt={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ py: 2, textAlign:'center' }}
                size="small"
              >
                Create Teacher
              </Button>
            </Grid>
        </form>
      </Paper>
    </div>
  );
};

export default AddTeacher;
