import axios from "axios";

// API function for fetching data
export const fetchRevenueData = async (y1, y2, instituteCode) => {
  try {
    const response = await axios.get(
      `https://pjsofttech.in:19443/revenue/twoyears?year1=${y1}&year2=${y2}&institutecode=${instituteCode}`
    );
    const revenueData = response.data;

    // Transform API response to format suitable for Pie chart
    return [
      { id: `${y1}`, label: `${y1}`, value: revenueData[y1] },
      { id: `${y2}`, label: `${y2}`, value: revenueData[y2] },
    ];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};



// Function to fetch monthly salary report counts
export const fetchSalaryReportCounts = async (apiEndpoints, instituteCode) => {
  try {
    const responses = await Promise.all(
      apiEndpoints.map((endpoint) =>
        axios.get(
          `https://pjsofttech.in:19443/MonthlySalaryReportcount?timeframe=${endpoint.timeframe}&institutecode=${instituteCode}`
        )
      )
    );

    return responses.reduce((acc, response, index) => {
      acc[apiEndpoints[index].timeframe] = response.data;
      return acc;
    }, {});
  } catch (error) {
    console.error("Error fetching salary report counts:", error);
    throw error;
  }
};

// Function to fetch the monthly salary revenue
export const fetchMonthlySalaryRevenue = async (
  apiEndpoints,
  instituteCode
) => {
  try {
    const responses = await Promise.all(
      apiEndpoints.map((endpoint) =>
        axios.get(
          `https://pjsofttech.in:19443/MonthlySalaryReportRevenue?timeframe=${endpoint.timeframe}&institutecode=${instituteCode}`
        )
      )
    );

    return responses.reduce((acc, response, index) => {
      acc[apiEndpoints[index].timeframe] = response.data; // Assuming API response has a count property
      return acc;
    }, {});
  } catch (error) {
    console.error("Error fetching monthly salary revenue:", error);
    throw error;
  }
};

// Fetch all subjects
export const fetchAllSubjects = async (instituteCode) => {
  try {
    const response = await axios.get(
      `https://pjsofttech.in:19443/getAllSubjects?institutecode=${instituteCode}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching subjects:", error);
    throw error;
  }
};

// Add or update a subject
export const saveSubject = async (subject, instituteCode, editing) => {
  try {
    if (editing) {
      await axios.put(
        `https://pjsofttech.in:19443/updateSubject/${subject.id}`,
        subject
      );
    } else {
      await axios.post(
        `https://pjsofttech.in:19443/createSubject?institutecode=${instituteCode}`,
        { subject: subject.name }
      );
    }
  } catch (error) {
    console.error("Error saving subject:", error);
    throw error;
  }
};

// Delete a subject
export const deleteSubject = async (subjectId) => {
  try {
    await axios.delete(`https://pjsofttech.in:19443/deleteSubject/${subjectId}`);
  } catch (error) {
    console.error("Error deleting subject:", error);
    throw error;
  }
};

// Fetch salary count by month for a specific year
export const fetchMonthlySalaryCountChart = async (year, instituteCode) => {
  try {
    const response = await axios.get(
      `https://pjsofttech.in:19443/countByPayingDate?institutecode=${instituteCode}&year=${year}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching salary count:", error);
    throw error;
  }
};

// Fetch revenue by paying date for a specific year
export const fetchRevenueByPayingDate = async (instituteCode, year) => {
  try {
    const response = await axios.get(
      `https://pjsofttech.in:19443/revenueByPayingDate?institutecode=${instituteCode}&year=${year}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching revenue by paying date:", error);
    throw error;
  }
};

// Fetch all exams
export const fetchExams = async (instituteCode) => {
  try {
    const response = await axios.get(
      `https://pjsofttech.in:19443/AllExams?institutecode=${instituteCode}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching exams:", error);
    throw error;
  }
};

// Add or update an exam
export const saveExam = async (exam, instituteCode, editing) => {
  try {
    if (editing) {
      await axios.put(`https://pjsofttech.in:19443/updateExam/${exam.id}`, {
        exam: exam.exam,
      });
    } else {
      await axios.post(
        `https://pjsofttech.in:19443/createExam?institutecode=${instituteCode}`,
        { exam: exam.exam }
      );
    }
  } catch (error) {
    console.error("Error saving exam:", error);
    throw error;
  }
};

// Delete an exam
export const deleteExamById = async (examId) => {
  try {
    await axios.delete(`https://pjsofttech.in:19443/deleteExam/${examId}`);
  } catch (error) {
    console.error("Error deleting exam:", error);
    throw error;
  }
};

// Function to fetch hourly rates
export const fetchHourlyRates = async (instituteCode) => {
  try {
    const response = await axios.get(
      `https://pjsofttech.in:19443/getAllHourlyRates?instituteCode=${instituteCode}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching hourly rates:", error);
    throw error;
  }
};

// Function to delete an hourly rate
export const deleteHourlyRate = async (id) => {
  try {
    await axios.delete(`https://pjsofttech.in:19443/deleteHourlyRate/${id}`);
  } catch (error) {
    console.error("Error deleting hourly rate:", error);
    throw error;
  }
};

// Function to add an hourly rate
export const addHourlyRate = async (data, instituteCode) => {
  try {
    await axios.post(
      `https://pjsofttech.in:19443/createHourlyRate?institutecode=${instituteCode}`,
      data
    );
  } catch (error) {
    console.error("Error adding hourly rate:", error);
    throw error;
  }
};

// Function to update an hourly rate
export const updateHourlyRate = async (id, data) => {
  try {
    await axios.put(`https://pjsofttech.in:19443/updateHourlyRate/${id}`, data);
  } catch (error) {
    console.error("Error updating hourly rate:", error);
    throw error;
  }
};

// Fetch all teachers
export const fetchAllTeachers = async (instituteCode) => {
  try {
    const response = await axios.get(
      `https://pjsofttech.in:19443/getAllTeachers?institutecode=${instituteCode}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching teachers:", error);
    throw error;
  }
};

// Update teacher details
export const updateTeacher = async (teacherId, teacherData) => {
  try {
    await axios.put(
      `https://pjsofttech.in:19443/updateTeacher/${teacherId}`,
      teacherData
    );
  } catch (error) {
    console.error("Error updating teacher:", error);
    throw error;
  }
};

// Delete teacher
export const deleteTeacher = async (teacherId) => {
  try {
    await axios.delete(`https://pjsofttech.in:19443/deleteTeacher/${teacherId}`);
  } catch (error) {
    console.error("Error deleting teacher:", error);
    throw error;
  }
};

// Change teacher status
export const updateTeacherStatus = async (teacherId, status) => {
  try {
    await axios.put(`https://pjsofttech.in:19443/${teacherId}/status`, { status });
  } catch (error) {
    console.error("Error updating teacher status:", error);
    throw error;
  }
};

// Fetch installment by ID
export const fetchInstallmentsById = async (salaryId, instituteCode) => {
  try {
    const response = await axios.get(
      `https://pjsofttech.in:19443/installments?institutecode=${instituteCode}&id=${salaryId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching installments:", error);
    throw error;
  }
};

// Fetch salary details by ID
export const fetchSalaryDetailsById = async (salaryId) => {
  try {
    const response = await axios.get(
      `https://pjsofttech.in:19443/getMonthlySalaryById/${salaryId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching salary details:", error);
    throw error;
  }
};

export const apiClient = axios.create({
  baseURL: "https://pjsofttech.in:19443", // Base URL for all API calls
});

// Function to fetch all salaries
export const fetchAllSalaries = async (instituteCode) => {
  try {
    const response = await apiClient.get(
      `/getAllSalaryHourlies?institutecode=${instituteCode}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching salaries:", error);
    throw error;
  }
};

// Create a new teacher
export const createTeacher = async (teacherData, instituteCode) => {
  try {
    const response = await axios.post(
      `https://pjsofttech.in:19443/createTeacher?institutecode=${instituteCode}`,
      teacherData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error creating teacher:", error);
    throw error;
  }
};


// Function to fetch the Pending salary count
export const fetchPendingAmountCount = async (apiEndpoints, instituteCode) => {
  try {
    const responses = await Promise.all(
      apiEndpoints.map((endpoint) =>
        axios.get(
          `https://pjsofttech.in:19443/pendingAmount-count?timeframe=${endpoint.timeframe}&institutecode=${instituteCode}`
        )
      )
    );

    return responses.reduce((acc, response, index) => {
      acc[apiEndpoints[index].timeframe] = response.data;
      return acc;
    }, {});
  } catch (error) {
    console.error("Error fetching salary report counts:", error);
    throw error;
  }
};


// Function to fetch the Pending salary revenue
export const fetchPendingAmountRevenue = async (
  apiEndpoints,
  instituteCode
) => {
  try {
    const responses = await Promise.all(
      apiEndpoints.map((endpoint) =>
        axios.get(
          `https://pjsofttech.in:19443/pendingAmount-revenue?timeframe=${endpoint.timeframe}&institutecode=${instituteCode}`
        )
      )
    );

    return responses.reduce((acc, response, index) => {
      acc[apiEndpoints[index].timeframe] = response.data; // Assuming API response has a count property
      return acc;
    }, {});
  } catch (error) {
    console.error("Error fetching monthly salary revenue:", error);
    throw error;
  }
};