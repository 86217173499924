// src/components/ShipmentForm.js
import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Grid,
  Paper,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Chip,
} from "@mui/material";
import { styled } from "@mui/system";
import { districts } from "../../src/Employee_System/Employee/dropdownData";
import {
  getTypes,
  getBookNames,
  getStatuses,
  getVendorNames,
  getSentBys,
} from "./API/ShipmentAPI";

const FormContainer = styled(Paper)(({ theme }) => ({
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#ffffff", // White background for the form
}));

const ShipmentForm = ({ shipmentData, onClose, refreshShipments }) => {
  const institutecode = localStorage.getItem("institutecode");

  // Initialize form data state
  const [formData, setFormData] = useState({
    type: "",
    customerName: "",
    customerMobile: "",
    customerAddress: "",
    state: "",
    district: "",
    taluka: "",
    city: "",
    pincode: "",
    customerEmail: "",
    orderDate: "",
    dispatchDate: "",
    trackingNo: "",
    sentBy: "",
    vendorName: "",
    bookName: [], // Initialize as an array
    quantity:"",
    price: "",
    status: "",
    institutecode: "",
  });

  const [districtOptions, setDistrictOptions] = useState([]);
  const [types, setTypes] = useState([]);
  const [vendorNames, setVendorNames] = useState([]);
  const [bookNames, setBookNames] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [sentBys, setSentBys] = useState([]);

  const fetchDropdownData = async () => {
    try {
      const fetchedTypes = await getTypes(institutecode);
      setTypes(fetchedTypes);

      const fetchedSentBys = await getSentBys(institutecode);
      setSentBys(fetchedSentBys);

      const fetchedVendorNames = await getVendorNames();
      setVendorNames(fetchedVendorNames);

      const fetchedBookNames = await getBookNames();
      setBookNames(fetchedBookNames);

      const fetchedStatuses = await getStatuses(institutecode);
      setStatuses(fetchedStatuses);
    } catch (error) {
      console.error("Error fetching dropdown data:", error);
    }
  };

  useEffect(() => {
    if (shipmentData) {
      setFormData(shipmentData);
      setDistrictOptions(districts[shipmentData.state] || []);
    }
  }, [shipmentData]);

  useEffect(() => {
    fetchDropdownData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "state") {
      setDistrictOptions(districts[value] || []);
      setFormData((prev) => ({
        ...prev,
        district: "",
        state: value,
        bookName: [], // Reset bookName when state changes
      }));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // Adjusted handleSelectChange function
  const handleSelectChange = async (event) => {
    const {
      target: { value },
    } = event;

    // Get book names based on selected book IDs
    const selectedBookNames = value
      .map((bookId) => {
        const book = bookNames.find((book) => book.id === bookId);
        return book ? book.bookName : null;
      })
      .filter(Boolean); // Filter out null values

    setFormData((prev) => ({
      ...prev,
      bookName: selectedBookNames, // Store book names instead of IDs
    }));

    if (value.length > 0) {
      try {
        const pricePromises = value.map((bookId) =>
          fetch(`https://pjsofttech.in:17443/getBookNameById/${bookId}`).then(
            (response) => {
              if (!response.ok) throw new Error("Network response was not ok");
              return response.json();
            }
          )
        );

        const bookPrices = await Promise.all(pricePromises);
        const totalPrice = bookPrices.reduce(
          (acc, bookData) => acc + bookData.price,
          0
        );

        setFormData((prev) => ({
          ...prev,
          price: totalPrice,
        }));
      } catch (error) {
        console.error("Error fetching book prices:", error);
        setFormData((prev) => ({ ...prev, price: "" }));
      }
    } else {
      setFormData((prev) => ({ ...prev, price: "" }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const apiEndpoint = shipmentData
      ? `https://pjsofttech.in:17443/updateShipmentForm/${shipmentData.id}`
      : `https://pjsofttech.in:17443/createShipmentForm?institutecode=${institutecode}`;

    try {
      const response = await fetch(apiEndpoint, {
        method: shipmentData ? "PUT" : "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...formData,
          price: parseFloat(formData.price),
        }),
      });

      if (!response.ok) throw new Error("Network response was not ok");

      const responseData = await response.json();
      console.log("Form submitted successfully:", responseData);
      await refreshShipments();
      onClose();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div maxWidth="false" sx={{ padding: 2, width: "100%" }}>
      <FormContainer>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} className="textField-root">
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Customer Name"
                name="customerName"
                value={formData.customerName}
                onChange={handleChange}
                variant="outlined"
                required
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Customer Mobile"
                name="customerMobile"
                value={formData.customerMobile}
                onChange={handleChange}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Customer Email"
                name="customerEmail"
                value={formData.customerEmail}
                onChange={handleChange}
                variant="outlined"
                type="email"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Customer Address"
                name="customerAddress"
                value={formData.customerAddress}
                onChange={handleChange}
                variant="outlined"
                required
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl fullWidth variant="outlined" required>
                <TextField
                  select
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  label="State"
                >
                  {Object.keys(districts).map((state) => (
                    <MenuItem key={state} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl fullWidth variant="outlined" required>
                <TextField
                  select
                  name="district"
                  value={formData.district}
                  onChange={handleChange}
                  label="District"
                  disabled={!districtOptions.length}
                >
                  {districtOptions.map((district) => (
                    <MenuItem key={district} value={district}>
                      {district}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>

            {/* Remaining grid items for taluka, city, etc. */}
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Taluka"
                name="taluka"
                value={formData.taluka}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="City"
                name="city"
                value={formData.city}
                onChange={handleChange}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Pincode"
                name="pincode"
                value={formData.pincode}
                onChange={handleChange}
                variant="outlined"
                required
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl fullWidth variant="outlined" required>
                <TextField
                  select
                  name="type"
                  value={formData.type}
                  onChange={handleChange}
                  label="Type"
                  required
                >
                  {types.map((type) => (
                    <MenuItem key={type.id} value={type.typeName}>
                      {type.typeName}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl fullWidth variant="outlined" required>
                <InputLabel>Book Name</InputLabel>
                <Select
                  multiple
                  name="bookName"
                  value={bookNames
                    .filter((book) => formData.bookName.includes(book.bookName))
                    .map((book) => book.id)} // This will keep IDs for the Select component
                  onChange={handleSelectChange}
                  label="Book Name"
                  renderValue={(selected) => (
                    <div>
                      {formData.bookName.map((bookName) => (
                        <Chip key={bookName} label={bookName} />
                      ))}
                    </div>
                  )}
                >
                  {bookNames.map((book) => (
                    <MenuItem key={book.id} value={book.id}>
                      {book.bookName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
              fullWidth
              type="number"
              label="Quantity"
              name="quantity"
              value={formData.quantity}
              onChange={handleChange}
              variant="outlined"
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Price"
                name="price"
                value={formData.price}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl fullWidth variant="outlined" required>
                <TextField
                  select
                  name="vendorName"
                  value={formData.vendorName}
                  onChange={handleChange}
                  label="Vendor Name"
                >
                  {vendorNames.map((vendor) => (
                    <MenuItem key={vendor.id} value={vendor.vendorName}>
                      {vendor.vendorName}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl fullWidth variant="outlined" required>
                <TextField
                  select
                  name="sentBy"
                  value={formData.sentBy}
                  onChange={handleChange}
                  label="Sent By"
                >
                  {sentBys.map((sentBy) => (
                    <MenuItem key={sentBy.id} value={sentBy.sentBy}>
                      {sentBy.sentBy}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Tracking No"
                name="trackingNo"
                value={formData.trackingNo}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Order Date"
                name="orderDate"
                value={formData.orderDate}
                onChange={handleChange}
                variant="outlined"
                type="date"
                InputLabelProps={{ shrink: true }}
                required
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Dispatch Date"
                name="dispatchDate"
                value={formData.dispatchDate}
                onChange={handleChange}
                variant="outlined"
                type="date"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl fullWidth variant="outlined" required>
                <TextField
                  select
                  name="status"
                  value={formData.status}
                  onChange={handleChange}
                  label="Status"
                >
                  {statuses.map((status) => (
                    <MenuItem key={status.id} value={status.status}>
                      {status.status}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                {shipmentData ? "Save" : "Submit"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormContainer>
    </div>
  );
};

export default ShipmentForm;
