import React, { useState, useEffect } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Box,
  Typography,
  IconButton,
  Grid,
} from "@mui/material";

import { Link } from "react-router-dom";
import {
  Add,
  Remove,
  Dashboard as DashboardIcon,
  Announcement as AnnouncementIcon,
  People as PeopleIcon,
  Person,
} from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonSearchRoundedIcon from "@mui/icons-material/PersonSearchRounded";
import AddHomeRoundedIcon from "@mui/icons-material/AddHomeRounded";
import CurrencyRupeeRoundedIcon from "@mui/icons-material/CurrencyRupeeRounded";
import EmojiPeopleRoundedIcon from "@mui/icons-material/EmojiPeopleRounded";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import SupervisorAccountRoundedIcon from "@mui/icons-material/SupervisorAccountRounded";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import CategoryIcon from "@mui/icons-material/Category";
import SubjectIcon from "@mui/icons-material/Subject";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import GroupIcon from "@mui/icons-material/Group";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PersonIcon from "@mui/icons-material/Person";
import HelpIcon from "@mui/icons-material/Help";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuIcon from "@mui/icons-material/Menu";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import WorkOffIcon from "@mui/icons-material/WorkOff";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

const drawerWidth = 255; // Full sidebar width
const iconOnlyWidth = 60; // Width when showing icons only
const Sidebar = () => {
  const [expanded, setExpanded] = useState("");
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const [systemValues, setSystemValues] = useState(null);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const [isCompletelyHidden, setIsCompletelyHidden] = useState(false); // Fully hide sidebar
  const [isMinimized, setIsMinimized] = useState(false); // Controls sidebar minimized state
  const institutecode = () => localStorage.getItem("institutecode");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://pjsofttech.in:20443/getSystemValueByInstitutecode?institutecode=${institutecode()}`
        );
        const data = await response.json();
        setSystemValues(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  // Collapse sidebar automatically after 30 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsMinimized(true);
    }, 30000);
    return () => clearTimeout(timer); // Clear timeout on component unmount
  }, []);

  // Hover events to expand/collapse sidebar
  const handleMouseEnter = () => {
    if (isMinimized) setIsMinimized(false);
  };

  const handleMouseLeave = () => {
    if (!isMinimized) setIsMinimized(true);
  };

  // Handle toggling between minimized and expanded states
  const handleDrawerToggle = () => {
    setIsMinimized((prev) => !prev); // Toggle between minimized and expanded
  };

  // Toggle expanding sub-options
  const handleToggle = (optionName) => {
    setExpanded((prev) => (prev === optionName ? "" : optionName));
  };

  const isActive = (route) => location.pathname === route;
  const sidebarOptions = [
    {
      name: "Main Dashboard",
      icon: <ViewQuiltIcon sx={{ color: "#fff" }} />,
      route: "/layout/combineDash",
      subOptions: [],
    },
    {
      name: "Enquiry System",
      icon: <PersonSearchRoundedIcon sx={{ color: "#fff" }} />,
      show: systemValues?.enquirymanagementsystem,
      route: "/layout/Enquiry-manager",
      subOptions: [],
    },
    {
      name: "Admission System",
      icon: <AddHomeRoundedIcon sx={{ color: "#fff" }} />,
      show: systemValues?.admissionmanagementsystem,
      route: "/layout/Admission-manager",
      subOptions: [],
    },
    {
      name: "Income & Expense",
      icon: <CurrencyRupeeRoundedIcon sx={{ color: "#fff" }} />,
      show: systemValues?.incomeandexpense,
      route: "/layout/income-expense-manager",
      subOptions: [],
    },
    {
      name: "Employee System",
      icon: <PeopleIcon sx={{ color: "#fff" }} />,
      show: systemValues?.employeemanagementsystem,
      subOptions: [
        {
          name: "Employee",
          icon: <PersonIcon sx={{ color: "#fff" }} />,
          route: "/layout/employee-manager",
        },
        {
          name: "Attendance",
          icon: <SupervisorAccountRoundedIcon sx={{ color: "#fff" }} />,
          route: "/layout/attendance-manager",
        },
        {
          name: "Leave",
          icon: <WorkOffIcon sx={{ color: "#fff" }} />,
          route: "/layout/leave-manager",
        },
        {
          name: "Salary",
          icon: <CurrencyRupeeRoundedIcon sx={{ color: "#fff" }} />,
          route: "/layout/employee-salary-manager",
        },
        {
          name: "Memo",
          icon: <NoteAltIcon sx={{ color: "#fff" }} />,
          route: "/layout/Memo-manager",
        },
        {
          name: "Notice",
          icon: <StickyNote2Icon sx={{ color: "#fff" }} />,
          route: "/layout/AddNotice",
        },
      ],
    },
    // {
    //   name: "Enquiry System",
    //   icon: <PersonSearchRoundedIcon sx={{ color: "#fff" }} />,
    //   show: systemValues?.enquirymanagementsystem,
    //   subOptions: [
    //     {
    //       name: "Enquiry Manager",
    //       icon: <DashboardIcon sx={{ color: "#fff" }} />,
    //       route: "/layout/Enquiry-manager", // Route for the manager
    //     },
    //   ],
    // },
    {
      name: "Student System",
      icon: <EmojiPeopleRoundedIcon sx={{ color: "#fff" }} />,
      show: systemValues?.studentmanagementsystem,
      subOptions: [
        {
          name: "Student",
          route: "/layout/student-manager",
          icon: <DashboardIcon sx={{ color: "#fff" }} />,
        },

        {
          name: "Fees System",
          icon: <CurrencyRupeeRoundedIcon sx={{ color: "#fff" }} />,
          route: "/layout/fees-manager",
        },
      ],
    },
    {
      name: "Classroom System",
      icon: <SchoolRoundedIcon sx={{ color: "#fff" }} />,
      // show: systemValues?.classroommanagementsystem,
      subOptions: [
        // { name: "Classroom", icon: <AnnouncementIcon /> },
        // { name: "Subject", icon: <SubjectIcon /> },
        // { name: "Account", icon: <AccountCircleIcon /> },
        // { name: "Timetable", icon: <CalendarMonthIcon /> },
        // { name: "Teachers", icon: <GroupIcon /> },
        // { name: "Students", icon: <Person /> },
        // {
        //   name: "Attendance",
        //   icon: <NotificationsIcon />,
        // },
        // { name: "Homework", icon: <NotificationsIcon /> },
        // { name: "Exam", icon: <NotificationsIcon /> },
        // { name: "Result", icon: <NotificationsIcon /> },
      ],
    },
    {
      name: "Shipment System",
      icon: <LocalShippingIcon  sx={{ color: "#fff" }} />,
      // show: systemValues?.enquirymanagementsystem,
      route: "/layout/shipment-manager",
      subOptions: [],
    },
    {
      name: "Payroll System",
      icon: <AccountBalanceWalletIcon  sx={{ color: "#fff" }} />,
      // show: systemValues?.enquirymanagementsystem,
      route: "/layout/payroll-manager",
      subOptions: [],
    },
    {
      name: "Add Sub-Admin",
      icon: <AdminPanelSettingsIcon sx={{ color: "#fff" }} />,
      route: "/layout/subadmin",
      subOptions: [],
    },
    {
      name: "Help Desk",
      icon: <HelpIcon sx={{ color: "#fff" }} />,
      route: "/layout/helpDesk",
      subOptions: [],
    },
    {
      name: "Billing Section",
      icon: <PriceCheckIcon sx={{ color: "#fff" }} />,
      route: "/layout/bill",
      subOptions: [],
    },
    {
      name: "Settings",
      icon: <SettingsRoundedIcon sx={{ color: "#fff" }} />,
      route: "/layout/Settings",
      subOptions: [],
    },
  ];

  const filteredSidebarOptions = sidebarOptions.filter(
    (option) => option.show !== false
  );

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        width: isMinimized ? iconOnlyWidth : drawerWidth,
        transition: "width 0.3s",
        "& .MuiDrawer-paper": {
          width: isMinimized ? iconOnlyWidth : drawerWidth,
          transition: "width 0.3s",
          overflowX: "hidden",
          backgroundColor: "#3498DB",
          overflowX: "hidden",
          overflowY: "auto",
        },
      }}
    >

      {/* Sidebar Options */}
      <Box sx={{ overflowY: "auto", marginTop: "60px", overflowX: "hidden" }}>
        <List sx={{ color: "white" }}>
          {filteredSidebarOptions.map((option, index) => (
            <div key={index}>
              <ListItem
                button
                onClick={() => handleToggle(option.name)}
                component={Link}
                to={option.route}
                sx={{
                  backgroundColor: isActive(option.route)
                    ? "#2980B9"
                    : "transparent",
                  "&:hover": {
                    backgroundColor: "#1F618D",
                  },
                }}
              >
                <ListItemIcon>{option.icon}</ListItemIcon>

                {/* Show option name only if not minimized */}
                {!isMinimized && (
                  <ListItemText
                    primary={
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: "bold",
                          fontSize: "13px",
                          marginLeft: "-20px",
                        }}
                      >
                        {option.name}
                      </Typography>
                    }
                  />
                )}

                {/* Dropdown Icon for options with sub-options */}
                {option.subOptions.length > 0 && (
                  <ListItemIcon>
                    {expanded === option.name ? (
                      <Remove sx={{ color: "red", marginLeft: "auto" }} />
                    ) : (
                      <Add sx={{ color: "#FFF", marginLeft: "auto" }} />
                    )}
                  </ListItemIcon>
                )}
              </ListItem>

              {/* Sub-options with collapse */}
              {!isMinimized && option.subOptions.length > 0 && (
                <Collapse
                  in={expanded === option.name}
                  timeout="auto"
                  unmountOnExit
                >
                  <Collapse in={expanded === option.name} timeout="auto">
                    <List component="div" disablePadding>
                      {option.subOptions.map((subOption, subIndex) => (
                        <ListItem
                          key={subIndex}
                          button
                          component={Link}
                          to={subOption.route}
                          sx={{
                            pl: 4,
                            backgroundColor: isActive(subOption.route)
                              ? "#2980B9"
                              : "transparent",
                            "&:hover": {
                              backgroundColor: "#1F618D",
                            },
                          }}
                        >
                          <ListItemIcon sx={{ ml: "-8px" }}>
                            {subOption.icon}
                          </ListItemIcon>

                          {/* Sub-option text */}
                          {!isMinimized && (
                            <ListItemText
                              primary={
                                <Typography
                                  variant="body2"
                                  sx={{
                                    fontSize: "12px",
                                    marginLeft: "-20px",
                                    fontWeight: "normal",
                                  }}
                                >
                                  {subOption.name}
                                </Typography>
                              }
                            />
                          )}
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </Collapse>
              )}
            </div>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};
export default Sidebar;
