import React, { useState, useMemo, useCallback, useEffect } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, TextField, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, TablePagination, Grid, MenuItem, Paper } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import { Delete } from "@mui/icons-material";
import GetAppIcon from "@mui/icons-material/GetApp";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import axios from "axios";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import Swal from "sweetalert2"; // Import SweetAlert2

const StudentTable = ({ students, setStudents, onUpdate, onInfo, fetchAllStudents }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState("all");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedStudentId, setSelectedStudentId] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const institutecode = () => localStorage.getItem("institutecode");

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://pjsofttech.in:9443/deleteMapping/${id}`);
      fetchAllStudents();
      handleCloseDialog();
           // Optionally, show a success message
           Swal.fire({
            icon: 'success',
            title: 'Deleted!',
            text: 'Student data has been deleted.',
          });
    } catch (error) {
      console.error("Error deleting student!", error);
    }
  };

  const fetchFilteredStudents = useCallback(
    async (filter, startDate = null, endDate = null) => {
      try {
        let response;
        if (filter === "custom" && startDate && endDate) {
          response = await axios.get(
            `https://pjsofttech.in:9443/getStudentsByDateRange?institutecode=${institutecode()}&startDate=${startDate}&endDate=${endDate}`
          );
        } else {
          switch (filter) {
            case "7":
              response = await axios.get(
                // `https://pjsofttech.in:9443/getDataof7Days?institutecode=${institutecode()}`
                `https://pjsofttech.in:9443/getStudentsByTimeframe?institutecode=${institutecode()}&timeframe=7days`
              );
              break;
            case "30":
              response = await axios.get(
                `https://pjsofttech.in:9443/getStudentsByTimeframe?institutecode=${institutecode()}&timeframe=30days`
              );
              break;
            case "365":
              response = await axios.get(
                `https://pjsofttech.in:9443/getStudentsByTimeframe?institutecode=${institutecode()}&timeframe=365days`
              );
              break;
            default:
              response = await axios.get(`https://pjsofttech.in:9443/getAllStudent?institutecode=${institutecode()}`);
              break;
          }
        }
        if (
          response.status === 200 &&
          response.data &&
          Array.isArray(response.data)
        ) {
          setStudents(response.data);
        } else {
          console.error("Unexpected response format:", response);
          setStudents([]);
        }
      } catch (error) {
        console.error("There was an error fetching the students!", error);
        setStudents([]);
      }
    },
    []
  );
  useEffect(() => {
    if (filter === "custom" && startDate && endDate) {
      fetchFilteredStudents("custom", startDate, endDate);
    }
  }, [filter, startDate, endDate, fetchFilteredStudents]);
  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };
  useEffect(() => {
    fetchAllStudents();
  }, [fetchAllStudents]);

  useEffect(() => {
    if (filter !== "all") {
      fetchFilteredStudents(filter);
    } else {
      fetchAllStudents();
    }
  }, [filter, fetchFilteredStudents, fetchAllStudents]);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    if (event.target.value !== "custom") {
      fetchFilteredStudents(event.target.value);
    }
  };

  const handleOpenDialog = (id) => {
    setSelectedStudentId(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedStudentId("");
  };

  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [
        [
          "ID",
          "First Name",
          "Last Name",
          "Registration Date",
          "Email",
          "Phone",
        ],
      ],
      body: students.map((student) => [
        student.id,
        student.firstName,
        student.surname,
        student.dateOfRegistration,
        student.emailAddress,
        student.phoneNumber,
      ]),
    });
    doc.save("students.pdf");
  };

  const csvData = students.map((student) => ({
    ID: student.id,
    "First Name": student.firstName,
    "Last Name": student.surname,
    Email: student.emailAddress,
    Phone: student.phoneNumber,
  }));

  const filteredStudents = useMemo(() => {
    const query = searchQuery.toLowerCase();
    return students.filter(
      (student) =>
        (student.firstName || "").toLowerCase().includes(query) ||
        (student.surname || "").toLowerCase().includes(query) ||
        (student.emailAddress || "").toLowerCase().includes(query)
    );
  }, [students, searchQuery]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when changing rows per page
  };

  const handleDeleteClick = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(id);
      }
    });
  };

  return (
    <>
              <Paper
            variant="outlined"
            style={{ padding: "16px", marginTop: "10px" }}
          >
    <Grid container spacing={2} className='textField-root' alignItems="center">
    <Grid item xs={12} sm={4}>
      <TextField
      size="small"
        label="Search by First Name"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        fullWidth
      />
      </Grid>
      <Grid item xs={12} sm={4}>
                <TextField
                select
                size="small"
                  value={filter}
                  onChange={handleFilterChange}
                  fullWidth
                  label="Select"
                  InputLabelProps={{ shrink: true }}
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="7">Last 7 Days</MenuItem>
                  <MenuItem value="30">Last 30 Days</MenuItem>
                  <MenuItem value="365">Last 365 Days</MenuItem>
                  <MenuItem value="custom">Custom Date Range</MenuItem>
                </TextField>
              </Grid>
              {filter === "custom" && (
                <>
                  <Grid item xs={12} sm={4}>
                    <TextField
                    size="small"
                      type="date"
                      label="Start Date"
                      value={startDate}
                      onChange={handleStartDateChange}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                    size="small"
                      type="date"
                      label="End Date"
                      value={endDate}
                      onChange={handleEndDateChange}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </>
              )}
      <Grid item xs={12} sm={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDownloadPDF}
                  startIcon={<GetAppIcon />} // Place the icon before the text
                  fullWidth
                >
                  Download PDF
                </Button>
              </Grid>
              <Grid item xs={12} sm={2}>
                <CSVLink
                  data={csvData}
                  filename="students.csv"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<CloudDownloadIcon />}
                    fullWidth
                  >
                    Download CSV
                  </Button>
                </CSVLink>
              </Grid>
      </Grid>
      <TableContainer style={{ marginTop: "16px" }}>
        <Table className="table-root">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Standards</TableCell>
            <TableCell>Medium</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Registered Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredStudents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((student) => (
              <TableRow key={student.stdid}>
                <TableCell>{student.stdid}</TableCell>
                <TableCell>{student.firstName}</TableCell>
                <TableCell>{student.surname}</TableCell>
                <TableCell>{student.standard}</TableCell>
                <TableCell>{student.mediumname}</TableCell>
                <TableCell>{student.emailAddress}</TableCell>
                <TableCell>{student.phoneNumber}</TableCell>
                <TableCell>{student.dateOfRegistration}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => onInfo(student)}>
                    <InfoIcon />
                  </IconButton>
                  <IconButton sx={{color:'blue'}} onClick={() => onUpdate(student)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="error"  onClick={() => handleDeleteClick(student.id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={filteredStudents.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              style={{  position: "relative"}}
            />
            </Paper>
    </>
  );
};

export default StudentTable;
