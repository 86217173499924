import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Typography,
  Grid,MenuItem,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { Cancel } from "@mui/icons-material";
import {  fetchAllTeachers, updateTeacher, deleteTeacher, updateTeacherStatus } from "./PayrollAPI";

export default function ManageTeacher() {
  const getInstituteCode = () => localStorage.getItem("institutecode");
  const navigate = useNavigate();
  const [teachers, setTeachers] = useState([]);
  const [filteredTeachers, setFilteredTeachers] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [searchPhone, setSearchPhone] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState(null);

  const handleNavigate = () => {
    navigate("/layout/payroll-manager/add-teacher");
  };

  useEffect(() => {
    fetchTeachers();
  }, []);

  useEffect(() => {
    filterTeachers();
  }, [searchName, searchEmail, searchPhone, teachers]);

  const fetchTeachers = async () => {
    try {
      const instituteCode = getInstituteCode();
      const data = await fetchAllTeachers(instituteCode);
      setTeachers(data);
      setFilteredTeachers(data);
    } catch (error) {
      console.error(error);
    }
  };

  const filterTeachers = () => {
    const filtered = teachers.filter((teacher) => {
      return (
        teacher.teacherName.toLowerCase().includes(searchName.toLowerCase()) &&
        teacher.email.toLowerCase().includes(searchEmail.toLowerCase()) &&
        teacher.mobileNo.includes(searchPhone)
      );
    });
    setFilteredTeachers(filtered);
  };

  const handleEditClick = (teacher) => {
    setSelectedTeacher(teacher);
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
    setSelectedTeacher(null);
  };

  const handleDeleteClick = async (id) => {
    if (window.confirm("Are you sure you want to delete this teacher?")) {
      try {
        await deleteTeacher(id);
        fetchTeachers();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleUpdateTeacher = async (event) => {
    event.preventDefault();
    try {
      await updateTeacher(selectedTeacher.id, selectedTeacher);
      fetchTeachers();
      handleDialogClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedTeacher({ ...selectedTeacher, [name]: value });
  };

  const handleCancel = async (id) => {
    const teacher = teachers.find((teacher) => teacher.id === id);
    if (teacher && teacher.status === "Inactive") {
      alert("The teacher is already Inactive.");
      return;
    }

    const confirmation = window.confirm(
      "Are you sure you want to Inactive This Teacher?"
    );

    if (confirmation) {
      try {
        await updateTeacherStatus(id, "Inactive");
        fetchTeachers();
      } catch (error) {
        console.error(error);
      }
    }
  }

  return (
    <div>
      

      <Grid
        container
        spacing={3}
        style={{ marginBottom: "20px", alignItems:'center' }}
        className="textField-root"
      >
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            placeholder="Search by Name"
            
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            placeholder="Search by Email"
            
            value={searchEmail}
            onChange={(e) => setSearchEmail(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            placeholder="Search by Phone"
            
            value={searchPhone}
            onChange={(e) => setSearchPhone(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="h6" gutterBottom>
            Total Teachers - {filteredTeachers.length}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button variant="contained" color="primary" onClick={handleNavigate}>
            Add New
          </Button>
        </Grid>
      </Grid>

      <TableContainer style={{ marginTop: "2%" }}>
        <Table size="small">
          <TableHead style={{ backgroundColor: "#f2f2f2" }}>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>Serial No</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>ID</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Teacher Name</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Phone Number</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Email</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Address</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Joining Date</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                Action Required
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredTeachers.map((teacher, index) => (
              <TableRow key={teacher.id}>
                <TableCell>{index + 1}.</TableCell>
                <TableCell>{teacher.id}</TableCell>
                <TableCell>{teacher.teacherName}</TableCell>
                <TableCell>{teacher.mobileNo}</TableCell>
                <TableCell>{teacher.email}</TableCell>
                <TableCell>{teacher.address}</TableCell>
                <TableCell>{new Date(teacher.joiningDate).toISOString().split("T")[0]}</TableCell>
                <TableCell
                  style={{
                    color:
                      teacher.status === "Active"
                        ? "green"
                        : teacher.status === "Deactive"
                        ? "red"
                        : "red",
                  }}
                >
                  <b>{teacher.status}</b>
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => handleEditClick(teacher)}
                    color="success"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleDeleteClick(teacher.id)}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    sx={{ color: "red" }}
                    onClick={() => handleCancel(teacher.id)}
                  >
                    <Cancel />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleDialogClose}>
        <DialogTitle>Edit Teacher</DialogTitle>
        <DialogContent>
          {selectedTeacher && (
            <>
              <TextField
                autoFocus
                margin="dense"
                name="teacherName"
                label="Teacher Name"
                fullWidth
                value={selectedTeacher.teacherName}
                onChange={handleInputChange}
              />
              <TextField
                margin="dense"
                name="mobileNo"
                label="Mobile Number"
                type="tel"
                fullWidth
                value={selectedTeacher.mobileNo}
                onChange={handleInputChange}
              />
              <TextField
                margin="dense"
                name="address"
                label="Address"
                fullWidth
                value={selectedTeacher.address}
                onChange={handleInputChange}
              />
              <TextField
                margin="dense"
                name="email"
                label="Email"
                type="email"
                fullWidth
                value={selectedTeacher.email}
                onChange={handleInputChange}
              />
              <TextField
                margin="dense"
                name="joiningDate"
                label="Joining Date"
                type="date"
                fullWidth
                value={new Date(selectedTeacher.joiningDate).toISOString().split("T")[0]}
                onChange={handleInputChange}
              />
              <TextField
                margin="dense"
                name="status"
                label="Status"
                select
                fullWidth
                value={selectedTeacher.status}
                onChange={handleInputChange}
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </TextField>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdateTeacher} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
