import React, { useState } from "react";
import { Box, Paper, Typography } from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AssessmentIcon from "@mui/icons-material/Assessment";
import BookIcon from "@mui/icons-material/Book";
import StoreIcon from "@mui/icons-material/Store";
import PersonIcon from "@mui/icons-material/Person";
import Type from "../Shipment/Type";
import Status from "../Shipment/Status";
import BookName from "../Shipment/Bookname";
import VendorName from "../Shipment/VendorName";
import Sentby from "./Sentby";

const ShipmentSetting = () => {
  const [selectedComponent, setSelectedComponent] = useState("Type");

  return (
    <Box sx={{ display: "flex", padding: "10px" }}>
      {/* Sidebar */}
      <Paper
        elevation={3}
        sx={{
          width: "220px",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          height: "260px",
          justifyContent: "space-between",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          whiteSpace: "nowrap",
          borderRadius: "8px",
          backgroundColor: "#f0f0f0",
        }}
      >
        {/* Add Type */}
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
            padding: "5px 10px",
            color: selectedComponent === "Type" ? "white" : "text.primary",
            fontWeight: selectedComponent === "Type" ? "bold" : "normal",
            borderRadius: selectedComponent === "Type" ? "10px" : "4px",
            backgroundColor: selectedComponent === "Type" ? "#3498DB" : "",
            fontSize: "13px",
            transition: "all 0.3s ease",
            boxShadow:
              selectedComponent === "Type"
                ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                : "none",
          }}
          onClick={() => setSelectedComponent("Type")}
        >
          <LocalShippingIcon /> Add Type
        </Typography>

        {/* Add Status */}
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
            padding: "5px 10px",
            color: selectedComponent === "Status" ? "white" : "text.primary",
            fontWeight: selectedComponent === "Status" ? "bold" : "normal",
            borderRadius: selectedComponent === "Status" ? "10px" : "4px",
            backgroundColor: selectedComponent === "Status" ? "#3498DB" : "",
            fontSize: "13px",
            transition: "all 0.3s ease",
            boxShadow:
              selectedComponent === "Status"
                ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                : "none",
          }}
          onClick={() => setSelectedComponent("Status")}
        >
          <AssessmentIcon /> Add Status
        </Typography>

        {/* Add Book Name */}
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
            padding: "5px 10px",
            color: selectedComponent === "BookName" ? "white" : "text.primary",
            fontWeight: selectedComponent === "BookName" ? "bold" : "normal",
            borderRadius: selectedComponent === "BookName" ? "10px" : "4px",
            backgroundColor: selectedComponent === "BookName" ? "#3498DB" : "",
            fontSize: "13px",
            transition: "all 0.3s ease",
            boxShadow:
              selectedComponent === "BookName"
                ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                : "none",
          }}
          onClick={() => setSelectedComponent("BookName")}
        >
          <BookIcon /> Add Book Name
        </Typography>

        {/* Add Vendor Name */}
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
            padding: "5px 10px",
            color: selectedComponent === "VendorName" ? "white" : "text.primary",
            fontWeight: selectedComponent === "VendorName" ? "bold" : "normal",
            borderRadius: selectedComponent === "VendorName" ? "10px" : "4px",
            backgroundColor: selectedComponent === "VendorName" ? "#3498DB" : "",
            fontSize: "13px",
            transition: "all 0.3s ease",
            boxShadow:
              selectedComponent === "VendorName"
                ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                : "none",
          }}
          onClick={() => setSelectedComponent("VendorName")}
        >
          <StoreIcon /> Add Vendor Name
        </Typography>

        {/* Add Sent By */}
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
            padding: "5px 10px",
            color: selectedComponent === "SentBy" ? "white" : "text.primary",
            fontWeight: selectedComponent === "SentBy" ? "bold" : "normal",
            borderRadius: selectedComponent === "SentBy" ? "10px" : "4px",
            backgroundColor: selectedComponent === "SentBy" ? "#3498DB" : "",
            fontSize: "13px",
            transition: "all 0.3s ease",
            boxShadow:
              selectedComponent === "SentBy"
                ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                : "none",
          }}
          onClick={() => setSelectedComponent("SentBy")}
        >
          <PersonIcon /> Add Sent By
        </Typography>
      </Paper>

      {/* Content Area */}
      <Box
        sx={{
          flexGrow: 1,
          padding: "20px",
          marginLeft: "20px",
          border: "1px solid #ddd",
          borderRadius: "8px",
        }}
      >
        {selectedComponent === "Type" && <Type />}
        {selectedComponent === "Status" && <Status />}
        {selectedComponent === "BookName" && <BookName />}
        {selectedComponent === "VendorName" && <VendorName />}
        {selectedComponent === "SentBy" && <Sentby />}
      </Box>
    </Box>
  );
};

export default ShipmentSetting;
