import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Grid,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Fade,
  Typography,
} from "@mui/material";
import Axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import { PlusOne } from "@mui/icons-material";

export default function AddMonthlySalary({
  onClose,
  editMode,
  currentSalary,
  fetchSalaries,
  setSnackbarMessage,
  setOpenSnackbar,
}) {
  const getInstituteCode = () => localStorage.getItem("institutecode");
  const [formData, setFormData] = useState({
    month: "",
    year: "",
    hourlyRate: "",
    hoursWorked: "",
    paymentMethod: "",
    subject: "",
    teacherId: "",
    payingAmount: "",
    pendingAmount: "",
    monthSalary: "",
    billType: "",
    paidBy: "",
    transactionId: "",
    remark: "",
    duedate: "",
    payingDate: "",

    amount1: "",
    dueDate1: "",
    installmentDate1: "",
    status1: "Pending",
    billType1: "",
    paidBy1: "",
    transactionId1: "",
    remark1: "",

    amount2: "",
    dueDate2: "",
    installmentDate2: "",
    status2: "Pending",
    billType2: "",
    paidBy2: "",
    transactionId2: "",
    remark2: "",

    amount3: "",
    dueDate3: "",
    installmentDate3: "",
    status3: "Pending",
    billType3: "",
    paidBy3: "",
    transactionId3: "",
    remark3: "",
  });

  const [installments, setInstallments] = useState([{ id: 1 }]);

  const handleInstallmentChange = (e, index) => {
    const { name, value } = e.target;

    const updatedInstallments = installments.map((installment, i) =>
      i === index ? { ...installment, [name]: value } : installment
    );

    setInstallments(updatedInstallments);

    setFormData((prevData) => {
      let updatedData = { ...prevData };
      updatedData[`amount${index + 1}`] = updatedInstallments[index].amount;
      updatedData[`dueDate${index + 1}`] = updatedInstallments[index].dueDate;
      updatedData[`installmentDate${index + 1}`] =
        updatedInstallments[index].installmentDate;
      updatedData[`billType${index + 1}`] = updatedInstallments[index].billType;
      updatedData[`paidBy${index + 1}`] = updatedInstallments[index].paidBy;
      updatedData[`transactionId${index + 1}`] =
        updatedInstallments[index].transactionId;
      updatedData[`remark${index + 1}`] = updatedInstallments[index].remark;
      return updatedData;
    });
  };

  const addInstallment = () => {
    setInstallments((prevInstallments) => {
      if (prevInstallments.length < 3) {
        const previousAmount = prevInstallments.reduce((total, installment) => {
          return total + (parseFloat(installment.amount) || 0);
        }, 0);

        const newInstallments = [
          ...prevInstallments,
          {
            id: prevInstallments.length + 1,
            amount:
              formData.monthSalary - previousAmount > 0
                ? formData.monthSalary - previousAmount
                : 0,
            dueDate: "",
            installmentDate: "",
          },
        ];
        return newInstallments;
      }
      return prevInstallments;
    });
  };

  const removeInstallment = (index) => {
    setInstallments((prevInstallments) => {
      const updatedInstallments = prevInstallments.filter(
        (_, i) => i !== index
      );

      const remainingAmount = parseFloat(formData.monthSalary) || 0;
      const remainingPaidAmount = updatedInstallments.reduce(
        (total, installment) => total + (parseFloat(installment.amount) || 0),
        0
      );
      const amountToDistribute = Math.max(
        0,
        remainingAmount - remainingPaidAmount
      );

      const distributedInstallments = updatedInstallments.map(
        (installment, i) => {
          if (i === updatedInstallments.length - 1) {
            return { ...installment, amount: amountToDistribute };
          } else {
            return installment;
          }
        }
      );

      setFormData((prevData) => {
        const updatedFormData = { ...prevData };
        updatedFormData[`amount${index + 1}`] = "";
        updatedFormData[`dueDate${index + 1}`] = "";
        updatedFormData[`installmentDate${index + 1}`] = "";
        updatedFormData[`billType${index + 1}`] = "";
        updatedFormData[`paidBy${index + 1}`] = "";
        updatedFormData[`transactionId${index + 1}`] = "";
        updatedFormData[`remark${index + 1}`] = "";
        return updatedFormData;
      });

      return distributedInstallments;
    });
  };

  useEffect(() => {
    if (formData.paymentMethod === "Installments") {
      const installmentDates = installments.map(
        (installment) => installment.installmentDate
      );

      const validDates = installmentDates.filter((date) => date);
      if (validDates.length > 0) {
        const latestDate = new Date(
          Math.max(...validDates.map((d) => new Date(d)))
        );
        setFormData((prevData) => ({
          ...prevData,
          payingDate: latestDate.toISOString().split("T")[0],
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          payingDate: "",
        }));
      }
    }
  }, [installments, formData.paymentMethod]);
  useEffect(() => {
    const totalPaid = installments.reduce((total, installment) => {
      return total + (parseFloat(installment.amount) || 0);
    }, 0);
    const remainingAmount = formData.monthSalary - totalPaid;

    setInstallments((prevInstallments) => {
      return prevInstallments.map((installment, index) => {
        if (index > 0) {
          return {
            ...installment,
            amount:
              index === prevInstallments.length - 1
                ? Math.max(0, remainingAmount)
                : installment.amount,
          };
        }
        return installment;
      });
    });
  }, [formData.monthSalary]);

  const updateInstallmentAmounts = () => {
    const monthSalary = parseFloat(formData.monthSalary) || 0;

    setInstallments((prevInstallments) => {
      const amount1 = parseFloat(prevInstallments[0]?.amount) || 0;
      const amount2 = parseFloat(prevInstallments[1]?.amount) || 0;

      const amount3 = Math.max(0, monthSalary - amount1 - amount2);

      return prevInstallments.map((installment, index) => {
        if (index === 0) {
          return { ...installment, amount: amount1 };
        }
        if (index === 1) {
          return { ...installment, amount: amount2 };
        }
        if (index === 2) {
          return { ...installment, amount: amount3 };
        }
        return installment;
      });
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => {
      let updatedData = { ...prevData, [name]: value };
      if (name === "paymentMethod") {
        if (value === "Complete") {
          updatedData.payingAmount = prevData.monthSalary;
          updatedData.pendingAmount = 0;
        } else if (value === "Pending") {
          updatedData.pendingAmount = prevData.monthSalary;
          updatedData.payingAmount = 0;
        }
      }

      if (name === "payingAmount") {
        updatedData.pendingAmount = prevData.monthSalary - value;
      }

      if (name.startsWith("amount")) {
        updateInstallmentAmounts();
      }

      return updatedData;
    });

    if (name.startsWith("amount")) {
      updateInstallmentAmounts();
    }
  };

  const [teachers, setTeachers] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const months = [
    { name: "January", value: 1 },
    { name: "February", value: 2 },
    { name: "March", value: 3 },
    { name: "April", value: 4 },
    { name: "May", value: 5 },
    { name: "June", value: 6 },
    { name: "July", value: 7 },
    { name: "August", value: 8 },
    { name: "September", value: 9 },
    { name: "October", value: 10 },
    { name: "November", value: 11 },
    { name: "December", value: 12 },
  ];

  const [year, setYear] = useState(null);

  const years = Array.from({ length: 11 }, (_, index) => {
    const yearValue = new Date().getFullYear() - 5 + index;
    return { value: yearValue, name: yearValue.toString() };
  });

  useEffect(() => {
    fetchTeachers();
    fetchSubjects();
    if (editMode && currentSalary) {
      setFormData(currentSalary);

      const existingInstallments = [];
      for (let i = 1; i <= 3; i++) {
        if (currentSalary[`amount${i}`]) {
          existingInstallments.push({
            id: i,
            amount: currentSalary[`amount${i}`] || "",
            dueDate: currentSalary[`dueDate${i}`] || "",
            installmentDate: currentSalary[`installmentDate${i}`] || "",
            billType: currentSalary[`billType${i}`] || "",
            paidBy: currentSalary[`paidBy${i}`] || "",
            transactionId: currentSalary[`transactionId${i}`] || "",
            remark: currentSalary[`remark${i}`] || "",
          });
        }
      }
      setInstallments(
        existingInstallments.length ? existingInstallments : [{ id: 1 }]
      );
    }

    if (formData.paymentMethod === "Installments") {
      const installmentDates = installments.map(
        (installment) => installment.installmentDate
      );

      const validDates = installmentDates.filter((date) => date);
      if (validDates.length > 0) {
        const latestDate = new Date(
          Math.max(...validDates.map((d) => new Date(d)))
        );
        setFormData((prevData) => ({
          ...prevData,
          payingDate: latestDate.toISOString().split("T")[0],
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          payingDate: "",
        }));
      }
    }
  }, [editMode, currentSalary]);

  const fetchTeachers = async () => {
    try {
      const response = await Axios.get(
        ` https://pjsofttech.in:19443/getAllTeachers?institutecode=${getInstituteCode()}`
      );
      setTeachers(response.data);
    } catch (error) {
      console.error("Error fetching teachers:", error);
    }
  };

  const fetchSubjects = async () => {
    try {
      const response = await Axios.get(
        ` https://pjsofttech.in:19443/getAllSubjects?institutecode=${getInstituteCode()}`
      );
      setSubjects(response.data);
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };

  const fetchSalaryDetails = async (teacherId, month, year) => {
    if (!teacherId || !month || !year) return;

    setIsLoading(true);
    try {
      const response = await Axios.get(
        ` https://pjsofttech.in:19443/SumOfSalariesByMonthAndTeacher?teacherId=${teacherId}&month=${month}&institutecode=${getInstituteCode()}&year=${year}`
      );
      if (response.data) {
        setFormData((prevData) => ({
          ...prevData,
          hourlyRate: response.data.totalHourlyRate,
          hoursWorked: response.data.totalHoursWorked,
          monthSalary: response.data.totalSalary, // Ensure this is being set
          pendingAmount: response.data.totalSalary,
        }));
      }
    } catch (error) {
      console.error("Error fetching salary details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTeacherChange = (e) => {
    const teacherId = e.target.value;
    setFormData((prevData) => {
      const newData = { ...prevData, teacherId };
      fetchSalaryDetails(teacherId, newData.month, year);
      return newData;
    });
  };

  const handleMonthChange = (e) => {
    const month = e.target.value;
    setFormData((prevData) => {
      const newData = { ...prevData, month };
      fetchSalaryDetails(prevData.teacherId, month, year);
      return newData;
    });
  };

  const handleYearChange = (event) => {
    const selectedYear = parseInt(event.target.value, 10);
    setYear(selectedYear);
    setFormData((prevData) => {
      const newData = {
        ...prevData,
        year: selectedYear,
      };
      if (newData.teacherId && newData.month) {
        fetchSalaryDetails(newData.teacherId, newData.month, selectedYear);
      }
      return newData;
    });
  };

  const getOrdinalSuffix = (num) => {
    if (num === 1) return "1st EMI";
    if (num === 2) return "2nd EMI";
    if (num === 3) return "3rd EMI";
    return `${num}th Installment`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form Data Being Submitted:", formData);
    try {
      console.log("Submitting data:", formData);
      if (editMode) {
        await Axios.put(
          ` https://pjsofttech.in:19443/updateMonthlySalary/${currentSalary.id}`,
          formData
        );
        setSnackbarMessage(
          `Salary updated for Teacher ID: ${formData.teacherId}`
        );
      } else {
        await Axios.post(
          ` https://pjsofttech.in:19443/createMonthlySalary?institutecode=${getInstituteCode()}`,
          formData
        );
        setSnackbarMessage(
          `Salary added for Teacher ID: ${formData.teacherId}`
        );
      }
      fetchSalaries();
      setOpenSnackbar(true);
      onClose();
    } catch (error) {
      console.error("Error saving monthly salary:", error);
    }
  };

  return (
    <>
      <DialogTitle style={{ textAlign: "center" }}>
        {editMode ? "Edit Monthly Salary" : "Add Monthly Salary"}
      </DialogTitle>
      <DialogContent style={{ padding: "2%" }}>
        <form onSubmit={handleSubmit} className="textField-root">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <TextField
                  select
                  label="Select Year"
                  name="year"
                  value={formData.year}
                  onChange={handleYearChange}
                  required
                >
                  {years.map((yearOption) => (
                    <MenuItem key={yearOption.value} value={yearOption.value}>
                      {yearOption.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <TextField
                  select
                  label="Select Month"
                  name="month"
                  value={formData.month}
                  onChange={handleMonthChange}
                  required
                >
                  {months.map((month) => (
                    <MenuItem key={month.value} value={month.value}>
                      {month.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <TextField
                  select
                  label="Select Teacher"
                  name="teacherId"
                  value={formData.teacherId}
                  onChange={handleTeacherChange}
                  required
                >
                  {teachers.map((teacher) => (
                    <MenuItem key={teacher.id} value={teacher.id}>
                      {teacher.teacherName}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Fade in={!isLoading} timeout={600}>
                <TextField
                  label="Hours Worked"
                  name="hoursWorked"
                  type="number"
                  value={formData.hoursWorked}
                  fullWidth
                  InputProps={{ readOnly: true }}
                />
              </Fade>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Fade in={!isLoading} timeout={600}>
                <TextField
                  label="Monthly Salary"
                  name="monthSalary"
                  value={formData.monthSalary}
                  fullWidth
                  InputProps={{ readOnly: true }}
                />
              </Fade>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <TextField
                  select
                  label="Payment Method"
                  name="paymentMethod"
                  value={formData.paymentMethod}
                  onChange={handleInputChange}
                  required
                >
                  <MenuItem value="Complete">Complete</MenuItem>
                  <MenuItem value="Pending">Pending</MenuItem>
                  <MenuItem value="Installments">Installments</MenuItem>
                </TextField>
              </FormControl>
            </Grid>

            {/* {formData.paymentMethod === "Installments" && (
              <Grid item xs={12}>
                <div
                  style={{
                    textAlign: "center",
                    borderRadius: "8px",
                  }}
                >
                  {installments.map((installment, index) => (
                    <Grid
                      mt={-2}
                      container
                      spacing={3}
                      maxWidth="lg"
                      key={installment.id}
                      style={{
                        width: "100%",
                      }}
                      alignItems={"center"}
                      padding={1.5}
                      justifyContent={"center"}
                    >
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            fontSize: "1.2rem",
                          }}
                        >
                          {`Installment ${index + 1}`}
                        </Typography>
                      </Grid>

                      <Grid
                        container
                        item
                        xs={12}
                        spacing={2}
                        style={{
                          justifyContent: "flex-start",
                          flexWrap: "nowrap",
                        }}
                      >
                        <Grid item xs={12} sm={3}>
                          <TextField
                            label={`Installment Amount`}
                            name="amount"
                            value={installment.amount || ""}
                            onChange={(e) => handleInstallmentChange(e, index)}
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <TextField
                            label={`Due Date`}
                            name="dueDate"
                            type="date"
                            value={installment.dueDate || ""}
                            onChange={(e) => handleInstallmentChange(e, index)}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>

                        {(installment.paidBy === "UPI" ||
                          installment.paidBy === "Cheque") && (
                          <Grid item xs={12} sm={2}>
                            <TextField
                              label={`${getOrdinalSuffix(
                                index + 1
                              )} Transaction ID`}
                              name="transactionId"
                              type="text"
                              value={installment.transactionId || ""}
                              onChange={(e) =>
                                handleInstallmentChange(e, index)
                              }
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                        )}

                        <Grid item xs={12} sm={3}>
                          <FormControl fullWidth>
                            <TextField
                              select
                              label="Bill Type"
                              name="billType"
                              value={installment.billType || ""}
                              onChange={(e) =>
                                handleInstallmentChange(e, index)
                              }
                            >
                              <MenuItem value="Invoice">Invoice</MenuItem>
                              <MenuItem value="Receipt">Receipt</MenuItem>
                            </TextField>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            label="Remark"
                            name="remark"
                            type="text"
                            value={installment.remark || ""}
                            onChange={(e) => handleInstallmentChange(e, index)}
                            fullWidth
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={2}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            marginTop: "20px",
                          }}
                        >
                          <Button
                            color="error"
                            onClick={() => removeInstallment(index)}
                            style={{ marginRight: "8px" }}
                          >
                            <DeleteIcon />
                          </Button>

                          <Button
                            color="success"
                            onClick={addInstallment}
                            disabled={installments.length >= 3}
                          >
                            <PlusOne />
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </div>
              </Grid>
            )} */}

            {["Partial", "Complete"].includes(formData.paymentMethod) && (
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Paying Amount"
                  name="payingAmount"
                  type="number"
                  value={formData.payingAmount}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
            )}
            {["Partial", "Pending"].includes(formData.paymentMethod) && (
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Pending Amount"
                  name="pendingAmount"
                  type="number"
                  value={formData.pendingAmount}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
            )}

            {formData.paymentMethod === "Complete" && (
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Paying Date"
                  name="payingDate"
                  type="date"
                  value={formData.payingDate}
                  onChange={handleInputChange}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            )}

            {formData.paymentMethod === "Partial" && (
              <>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Paying Date"
                    name="payingDate"
                    type="date"
                    value={formData.payingDate}
                    onChange={handleInputChange}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Due Date"
                    name="duedate"
                    type="date"
                    value={formData.duedate}
                    onChange={handleInputChange}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </>
            )}

            {formData.paymentMethod === "Pending" && (
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Due Date"
                  name="duedate"
                  type="date"
                  value={formData.duedate}
                  onChange={handleInputChange}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            )}

            {formData.paymentMethod !== "Pending" &&
              formData.paymentMethod !== "Installments" && (
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <TextField
                      select
                      label="Paid By"
                      name="paidBy"
                      value={formData.paidBy}
                      onChange={handleInputChange}
                    >
                      <MenuItem value="Cash">Cash</MenuItem>
                      <MenuItem value="UPI">UPI</MenuItem>
                      <MenuItem value="Cheque">Cheque</MenuItem>
                    </TextField>
                  </FormControl>
                </Grid>
              )}

            {formData.paidBy !== "Cash" &&
              formData.paymentMethod !== "Pending" &&
              formData.paymentMethod !== "Installments" && (
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Transaction ID"
                    name="transactionId"
                    value={formData.transactionId}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
              )}

            {(formData.paymentMethod === "Complete" ||
              formData.paymentMethod === "Partial") && (
              <Grid item xs={12} sm={4}>
                <TextField 
                select
                  label="Bill Type"
                  name="billType"
                  value={formData.billType}
                  onChange={handleInputChange}
                  required
                  fullWidth
                >
                  <MenuItem value="Invoice">Invoice</MenuItem>
                      <MenuItem value="Receipt">Receipt</MenuItem>
                </TextField>
              </Grid>
            )}

            {formData.paymentMethod !== "Installments" && (
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Remark"
                  name="remark"
                  value={formData.remark}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
            )}
          </Grid>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 3 }}
          >
            {editMode ? "Update Salary" : "Create Salary"}
          </Button>
        </form>
      </DialogContent>
    </>
  );
}
