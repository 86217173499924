import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { getBookNames, deleteBookName, addBookName } from "./API/ShipmentAPI";

const MySwal = withReactContent(Swal);

const Bookname = () => {
  const { id } = useParams(); // If needed, use the id param
  const [bookNames, setBookNames] = useState([]);
  const [filteredBookNames, setFilteredBookNames] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [bookName, setBookName] = useState({ bookName: "", price: "" });

  const institutecode = localStorage.getItem("institutecode") || "MIT1"; // Default to MIT1 if not found

  // Load book names when the component mounts or institutecode changes
  useEffect(() => {
    loadBookNames();
  }, [institutecode]);

  // Filter book names based on the search term
  useEffect(() => {
    filterBookNames();
  }, [searchTerm, bookNames]);

  // Load book names from the server
  const loadBookNames = async () => {
    try {
      const data = await getBookNames();
      setBookNames(data);
      setFilteredBookNames(data);
    } catch (error) {
      console.error("Error fetching book names:", error);
    }
  };

  // Filter the book names list based on the search term
  const filterBookNames = () => {
    const filtered = bookNames.filter((book) => {
      if (book.bookName && book.bookName.toLowerCase) {
        return book.bookName.toLowerCase().includes(searchTerm.toLowerCase());
      }
      return false;
    });
    setFilteredBookNames(filtered);
  };

  // Handle deleting a book name
  const handleDeleteBookName = (book) => {
    MySwal.fire({
      title: "Are you sure?",
      text: `Do you want to delete book name: ${book.bookName}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteBookNameHandler(book.id);
      }
    });
  };

  // Delete the book name by ID
  const deleteBookNameHandler = async (bookId) => {
    try {
      await deleteBookName(bookId);
      MySwal.fire({
        title: "Deleted!",
        text: "The book name has been deleted successfully.",
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
      });
      loadBookNames();
    } catch (error) {
      console.error("Error deleting book name:", error);
      MySwal.fire({
        title: "Error!",
        text: "There was an error deleting the book name.",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  // Open the dialog to add a new book name
  const handleOpenAddDialog = () => {
    setBookName({ bookName: "" }); // Clear the input field
    setOpenAddDialog(true);
  };

  // Close the dialog
  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  // Handle submitting the form to add a new book name
  const onAddBookNameSubmit = async (e) => {
    e.preventDefault();
    if (!bookName.bookName || !bookName.price) {
      MySwal.fire({
        title: 'Error!',
        text: 'Please fill out the necessary fields.',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false,
      });
      return;
    }
  
    try {
      await addBookName(bookName.bookName, bookName.price); // Include price in the API call
      MySwal.fire({
        title: 'Success!',
        text: 'The book name has been added successfully.',
        icon: 'success',
        timer: 1500,
        showConfirmButton: false,
      });
      handleCloseAddDialog();
      loadBookNames();
    } catch (error) {
      console.error('There was an error adding the book name!', error);
      MySwal.fire({
        title: 'Error!',
        text: 'There was an error adding the book name.',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };
  

  return (
    <div sx={{ marginTop: "10px" }}>
      <Grid container spacing={2} sx={{ marginTop: "10px" }}>
        <Typography
          variant="h6"
          gutterBottom
          sx={{ marginTop: 3, whiteSpace: "nowrap" }}
        >
          Total Book Names: {filteredBookNames.length}
        </Typography>

        <Grid item xs={12} sm={1.6} className="textField-root">
          <TextField
            label="Search Book Name"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenAddDialog}
            sx={{ marginTop: 1 }}
          >
            Add Book Name
          </Button>
        </Grid>
      </Grid>

      <Box mt={2}>
        <TableContainer>
          <Table className="table-root">
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Book Name</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredBookNames.map((book, index) => (
                <TableRow key={book.id}>
                  <TableCell>{book.id}</TableCell>
                  <TableCell>{book.bookName}</TableCell>
                  <TableCell>{book.price || 0}</TableCell>
                  <TableCell>
                    <Button
                      color="error"
                      onClick={() => handleDeleteBookName(book)}
                      size="small"
                      variant="contained"
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Add Book Name Dialog */}
      <Dialog open={openAddDialog} onClose={handleCloseAddDialog}>
        <DialogTitle>Add Book Name</DialogTitle>
        <DialogContent>
          <DialogContentText>Add a new book name.</DialogContentText>
          <form onSubmit={onAddBookNameSubmit}>
            <TextField
              autoFocus
              margin="dense"
              label="Book Name"
              fullWidth
              variant="outlined"
              value={bookName.bookName} // Bind the field to 'bookName.bookName'
              onChange={(e) =>
                setBookName({ ...bookName, bookName: e.target.value })
              } // Update 'bookName' correctly
            />

            <TextField
              margin="dense"
              label="Price"
              fullWidth
              variant="outlined"
              value={bookName.price}
              onChange={(e) =>
                setBookName({ ...bookName, price: e.target.value })
              }
            />

            <DialogActions>
              <Button onClick={handleCloseAddDialog} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Add
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Bookname;
