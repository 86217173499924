// src/components/Dashboard.js
import React, { useState, useEffect } from "react";
import ShipmentCounts from "./ShipmentCounts";
import DailyFormsByMonthYear from "./DailyFormsByMonthYear";
import {
  Grid,
  Paper,
} from "@mui/material";
import MonthlyFormsByYear from "./MonthlyFormsByYear";
import YearsComparison from "./YearsComparison";
import axios from "axios";

const ShipmentDashboard = () => {
  const [statuses, setStatuses] = useState([]);
  const [orderCounts, setOrderCounts] = useState({});
  const [loading, setLoading] = useState(true);
  const institutecode = localStorage.getItem("institutecode");

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const response = await axios.get(
          `https://pjsofttech.in:17443/getAllStatuses?institutecode=${institutecode}`
        );
        setStatuses(response.data.map((item) => item.status));
      } catch (error) {
        console.error("Error fetching statuses:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStatuses();
  }, [institutecode]);

  useEffect(() => {
    const fetchOrderCounts = async () => {
      if (statuses.length === 0) return;
      const counts = {};

      for (const status of statuses) {
        try {
          const response = await axios.get(
            `https://pjsofttech.in:17443/getOrdersStatusCount?status=${encodeURIComponent(
              status
            )}&institutecode=${institutecode}`
          );
          counts[status] = response.data;
        } catch (error) {
          console.error(`Error fetching count for status ${status}:`, error);
          counts[status] = 0;
        }
      }

      setOrderCounts(counts);
    };

    fetchOrderCounts();
  }, [statuses, institutecode]);
  return (
    <div>
     

      {/* <div style={{ marginBottom: "10px", marginTop: "20px" }}>
        <OrdersCountByStatus />
      </div> */}
      <ShipmentCounts />

      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={6} style={{ padding: "16px" }}>
          <Paper elevation={3} style={{ padding: "16px" }}>
            <DailyFormsByMonthYear />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} style={{ padding: "16px" }}>
          <Paper elevation={3} style={{ padding: "16px" }}>
            <MonthlyFormsByYear />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} style={{ padding: "16px" }}>
          <Paper elevation={3} style={{ padding: "16px" }}>
            <YearsComparison />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} style={{ padding: "16px" }}>
          
        </Grid>
      </Grid>

      <style>
        {`
          @keyframes marquee {
            0% { transform: translateX(0); }
            100% { transform: translateX(-100%); }
          }
        `}
      </style>
    </div>
  );
};

export default ShipmentDashboard;
