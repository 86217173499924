import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Typography, CircularProgress, Grid, Button } from "@mui/material";
import { jsPDF } from "jspdf";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

export default function QrCode() {
  const [qrcode, setQrcode] = useState(""); // Store the QR code data
  const [loading, setLoading] = useState(true); // State for loading indicator
  const [employeeDetails, setEmployeeDetails] = useState(""); // Store employee details

  const getInstituteCode = () => localStorage.getItem("institutecode");

  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      try {
        if (!getInstituteCode()) {
          console.error("No institutecode found in localStorage");
          return;
        }

        const response = await axios.get(
          `https://pjsofttech.in:20443/findInstitutesby/Institutecode?institutecode=${getInstituteCode()}`
        );
        setEmployeeDetails(response.data);
      } catch (error) {
        console.error("Error fetching employee details:", error);
      }
    };

    fetchEmployeeDetails();
  }, [getInstituteCode()]);

  const fetchQrCode = async () => {
    try {
      const instituteCode = getInstituteCode();
      if (!instituteCode) {
        // Redirect to "/" if instituteCode is missing
        window.location.href = "/";
        return;
      }

      const response = await axios.get(
        `https://pjsofttech.in:14443/generate-qrcode?institutecode=${instituteCode}`,
        { responseType: "blob" } // Handle the response as a Blob
      );

      // Create a local URL for the image Blob
      const imageUrl = URL.createObjectURL(response.data);
      setQrcode(imageUrl); // Set the Blob URL to state
      setLoading(false); // Set loading to false after data is fetched
    } catch (error) {
      console.error("Error fetching QR code:", error);
      setLoading(false); // Set loading to false on error
    }
  };

  useEffect(() => {
    fetchQrCode();
  }, []); // Fetch QR code once when the component is mounted

  const downloadPdf = () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const borderPadding = 10; // Padding from edges for the border
  
    // Add border
    doc.setLineWidth(0.2); // Set border thickness to 0.5px
  doc.rect(
    borderPadding, // Start X with padding
    borderPadding, // Start Y with padding
    pageWidth - 2 * borderPadding, // Width minus padding on both sides
    pageHeight - 2 * borderPadding // Height minus padding on both sides
  );

  
    // Sub-title: Employee Details (Institutename)
    if (employeeDetails?.institutename) {
      const subTitle = employeeDetails.institutename;
      const subTitleFontSize = 22; // Larger font size for institutename
      doc.setFontSize(subTitleFontSize);
      const subTitleWidth = doc.getTextWidth(subTitle);
      const subTitleX = (pageWidth - subTitleWidth) / 2; // Center the subtitle
      const subTitleY = 20; // Position 20 units from the top
      doc.text(subTitle, subTitleX, subTitleY);
    }
  
    // Title: "Scan here to fill the Enquiry form"
    const title = "Scan here to fill the Enquiry form";
    const titleFontSize = 18;
    doc.setFontSize(titleFontSize);
    const titleWidth = doc.getTextWidth(title);
    const titleX = (pageWidth - titleWidth) / 2; // Center the title horizontally
    const titleY = 40; // Position the title below the subtitle
    doc.text(title, titleX, titleY);
  
    // QR Code
    const qrCodeSize = 100;
    const qrCodeX = (pageWidth - qrCodeSize) / 2;
    const qrCodeY = 60; // Position the QR code below the title
    if (qrcode) {
      doc.addImage(qrcode, "PNG", qrCodeX, qrCodeY, qrCodeSize, qrCodeSize);
    }
  
    // Footer
    const footer = "Made By - PJSOFTTECH PVT. LTD.";
    const footerFontSize = 10;
    doc.setFontSize(footerFontSize);
    const footerWidth = doc.getTextWidth(footer);
    const footerX = (pageWidth - footerWidth) / 2;
    const footerY = qrCodeY + qrCodeSize + 20; // Position below the QR code
    doc.text(footer, footerX, footerY);
  
    // Save the PDF
    doc.save("QRCode.pdf");
  };
  
  
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100vh",
          textAlign: "center",
        }}
      >
        {loading ? (
          <CircularProgress /> // Show a loading spinner while fetching data
        ) : qrcode ? (
          <>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              style={{
                backgroundColor: "#f0f4f8",
                padding: "16px",
                borderRadius: "10px",
                border: "0.5px solid lightgray",
                marginBottom: "16px",
              }}
            >
              <Typography
                variant="h5"
                style={{
                  fontWeight: "bold",
                  color: "#333",
                }}
              >
                Organization - {employeeDetails.institutename}
              </Typography>
            </Grid>

            <Box
              component="img"
              src={qrcode} // Use the Blob URL as the image source
              alt="QR Code"
              sx={{
                width: "350px",
                height: "350px",
                borderRadius: "8px",
                border: "2px solid #ddd",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                marginBottom: "16px",
              }}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={downloadPdf}
              sx={{ marginTop: "16px" }}
            >
              <FileDownloadIcon/>Download PDF
            </Button>
          </>
        ) : (
          <Typography variant="h6" color="error">
            Failed to load QR Code.
          </Typography>
        )}
      </Box>
    </div>
  );
}
