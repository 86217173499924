import React, { useState } from "react";
import { Box, Paper, Typography } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ClassIcon from '@mui/icons-material/Class';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import GroupsIcon from '@mui/icons-material/Groups';
import AddField from "../Student/AddField";
import AddClass from "../Student/AddClass";
import Stream from "./Stream";
import Group from "./Group";
const ShipmentSetting = () => {
  const [selectedComponent, setSelectedComponent] = useState("AddField");

  return (
    <Box sx={{ display: "flex", padding: "10px" }}>
      {/* Sidebar */}
      <Paper
        elevation={3}
        sx={{
          width: "220px",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          height: "190px",
          justifyContent: "space-between",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          whiteSpace: "nowrap",
          borderRadius: "8px",
          backgroundColor: "#f0f0f0",
        }}
      >
        {/* Add Type */}
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
            padding: "5px 10px",
            color: selectedComponent === "AddField" ? "white" : "text.primary",
            fontWeight: selectedComponent === "AddField" ? "bold" : "normal",
            borderRadius: selectedComponent === "AddField" ? "10px" : "4px",
            backgroundColor: selectedComponent === "AddField" ? "#3498DB" : "",
            fontSize: "13px",
            transition: "all 0.3s ease",
            boxShadow:
              selectedComponent === "AddField"
                ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                : "none",
          }}
          onClick={() => setSelectedComponent("AddField")}
        >
          <AddCircleIcon /> Add Field
        </Typography>

        {/* Add Status */}
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
            padding: "5px 10px",
            color: selectedComponent === "AddClass" ? "white" : "text.primary",
            fontWeight: selectedComponent === "AddClass" ? "bold" : "normal",
            borderRadius: selectedComponent === "AddClass" ? "10px" : "4px",
            backgroundColor: selectedComponent === "AddClass" ? "#3498DB" : "",
            fontSize: "13px",
            transition: "all 0.3s ease",
            boxShadow:
              selectedComponent === "AddClass"
                ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                : "none",
          }}
          onClick={() => setSelectedComponent("AddClass")}
        >
          <ClassIcon /> Add Class
        </Typography>

        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
            padding: "5px 10px",
            color: selectedComponent === "Stream" ? "white" : "text.primary",
            fontWeight: selectedComponent === "Stream" ? "bold" : "normal",
            borderRadius: selectedComponent === "Stream" ? "10px" : "4px",
            backgroundColor: selectedComponent === "Stream" ? "#3498DB" : "",
            fontSize: "13px",
            transition: "all 0.3s ease",
            boxShadow:
              selectedComponent === "Stream"
                ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                : "none",
          }}
          onClick={() => setSelectedComponent("Stream")}
        >
          <HistoryEduIcon /> Add Stream
        </Typography>

        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
            padding: "5px 10px",
            color: selectedComponent === "Group" ? "white" : "text.primary",
            fontWeight: selectedComponent === "Group" ? "bold" : "normal",
            borderRadius: selectedComponent === "Group" ? "10px" : "4px",
            backgroundColor: selectedComponent === "Group" ? "#3498DB" : "",
            fontSize: "13px",
            transition: "all 0.3s ease",
            boxShadow:
              selectedComponent === "Group"
                ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                : "none",
          }}
          onClick={() => setSelectedComponent("Group")}
        >
          <GroupsIcon /> Add Group
        </Typography>

      </Paper>

      {/* Content Area */}
      <Box
        sx={{
          flexGrow: 1,
          padding: "20px",
          marginLeft: "20px",
          border: "1px solid #ddd",
          borderRadius: "8px",
        }}
      >
        {selectedComponent === "AddField" && <AddField />}
        {selectedComponent === "AddClass" && <AddClass />}
        {selectedComponent === "Stream" && <Stream />}
        {selectedComponent === "Group" && <Group />}
      </Box>
    </Box>
  );
};

export default ShipmentSetting;
