
import React from "react";
import { Routes, Route } from "react-router-dom";
import ShipmentDashboard from "./ShipmentDashboard";
import ShipmentForm from "./Shipmentform";
import ShipmentManagerLayout from './ShipmentManagerLayout';
import ShipmentReport from "./ShipmentReport";
import BookName from "./Bookname";
import Type from "./Type";
import VendorName from "./VendorName";
import Status from "./Status";
import ShipmentSetting from "./ShipmentSetting";

const ShipmentManager = () => {
  return (
    <Routes>
      <Route path="/" element={<ShipmentManagerLayout />}>
        <Route index element={<ShipmentDashboard />} />
        <Route path="shipment-form" element={<ShipmentForm />} />
        <Route path="ShipmentSetting" element={<ShipmentSetting />} />
        <Route path="shipment-report" element={<ShipmentReport />} />
        <Route path="bookname" element={<BookName/>}/>
        <Route path="shipment-type" element={<Type/>}/>
        <Route path="vendorname" element={<VendorName/>}/>
        <Route path="status" element={<Status/>}/> 
      </Route>
    </Routes>
  );
};

export default ShipmentManager;
