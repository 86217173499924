import React, { useState } from "react";
import { Box, Paper, Typography } from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import SubjectIcon from "@mui/icons-material/Subject";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TeacherExam from "./TeacherExam";
import AddSubject from "./AddSubject";
import HourlyRate from "./HourlyRate";

export default function PayrollSetting() {
  const [selectedComponent, setSelectedComponent] = useState("add-exam");

  return (
    <Box sx={{ display: "flex", padding: "10px" }}>
      {/* Sidebar */}
      <Paper
        elevation={3}
        sx={{
          width: "220px",
          height:"170px",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          backgroundColor: "#f0f0f0",
          whiteSpace: "nowrap",
        }}
      >
        {/* Add Exam */}
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
            padding: "5px 10px",
            color: selectedComponent === "add-exam" ? "white" : "text.primary",
            fontWeight: selectedComponent === "add-exam" ? "bold" : "normal",
            borderRadius: selectedComponent === "add-exam" ? "10px" : "4px",
            backgroundColor: selectedComponent === "add-exam" ? "#3498DB" : "",
            fontSize: "13px",
            transition: "all 0.3s ease",
            boxShadow:
              selectedComponent === "add-exam"
                ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                : "none",
          }}
          onClick={() => setSelectedComponent("add-exam")}
        >
          <SchoolIcon /> Add Exam
        </Typography>

        {/* Add Subject */}
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
            padding: "5px 10px",
            color: selectedComponent === "add-subject" ? "white" : "text.primary",
            fontWeight: selectedComponent === "add-subject" ? "bold" : "normal",
            borderRadius: selectedComponent === "add-subject" ? "10px" : "4px",
            backgroundColor: selectedComponent === "add-subject" ? "#3498DB" : "",
            fontSize: "13px",
            transition: "all 0.3s ease",
            boxShadow:
              selectedComponent === "add-subject"
                ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                : "none",
          }}
          onClick={() => setSelectedComponent("add-subject")}
        >
          <SubjectIcon /> Add Subject
        </Typography>

        {/* Add Hourly Rate */}
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
            padding: "5px 10px",
            color:
              selectedComponent === "hourly-rate" ? "white" : "text.primary",
            fontWeight: selectedComponent === "hourly-rate" ? "bold" : "normal",
            borderRadius: selectedComponent === "hourly-rate" ? "10px" : "4px",
            backgroundColor:
              selectedComponent === "hourly-rate" ? "#3498DB" : "",
            fontSize: "13px",
            transition: "all 0.3s ease",
            boxShadow:
              selectedComponent === "hourly-rate"
                ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                : "none",
          }}
          onClick={() => setSelectedComponent("hourly-rate")}
        >
          <AccessTimeIcon /> Add Hourly Rate
        </Typography>
      </Paper>

      {/* Content Area */}
      <Box
        sx={{
          flexGrow: 1,
          padding: "20px",
          marginLeft: "20px",
          border: "1px solid #ddd",
          borderRadius: "8px",
        }}
      >
        {selectedComponent === "add-exam" && <TeacherExam />}
        {selectedComponent === "add-subject" && <AddSubject />}
        {selectedComponent === "hourly-rate" && <HourlyRate />}
      </Box>
    </Box>
  );
}
