// import React, { useState } from "react";
// import { Button, Box, Typography } from "@mui/material";
// import AdmissionAddCourse from "../Addmission/AdmissionAddCourse"; // Replace with the actual Exam component file path
// import AddmissionSource from "../Addmission/AddmissionSource"; // Create this component
// import AddGuide from "../Addmission/AddGuide"; // Create this component

// const AdmissionSetting = () => {
//   // State to track which component to render, defaulting to "exam"
//   const [selectedComponent, setSelectedComponent] = useState("AdmissionAddCourse");

//   const handleButtonClick = (component) => {
//     setSelectedComponent(component);
//   };

//   return (
//     <Box sx={{ padding: "20px" }}>
//       {/* Buttons */}
//       <Box sx={{ display: "flex", gap: "15px", marginBottom: "20px" }} justifyContent={"space-evenly"}>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={() => handleButtonClick("AdmissionAddCourse")}
//         >
//           Add Course
//         </Button>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={() => handleButtonClick("AddmissionSource")}
//         >
//           Add Source
//         </Button>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={() => handleButtonClick("AddGuide")}
//         >
//           Add Guide
//         </Button>
//       </Box>

//       {/* Conditionally render the selected component */}
//       <Box>
//         {selectedComponent === "AdmissionAddCourse" && <AdmissionAddCourse />}
//         {selectedComponent === "AddmissionSource" && <AddmissionSource />}
//         {selectedComponent === "AddGuide" && <AddGuide />}
//       </Box>
//     </Box>
//   );
// };

// export default AdmissionSetting;

import React, { useState } from "react";
import { Box, Typography, Paper } from "@mui/material";
import AddCourseIcon from "@mui/icons-material/LibraryAdd"; // Replace with a suitable icon
import SourceIcon from "@mui/icons-material/Source"; // Replace with a suitable icon for "Add Source"
import GuideIcon from "@mui/icons-material/School"; // Replace with a suitable icon for "Add Guide"
import AdmissionAddCourse from "../Addmission/AdmissionAddCourse";
import AddmissionSource from "../Addmission/AddmissionSource";
import AddGuide from "../Addmission/AddGuide";

const AdmissionSetting = () => {
  // State to track which component to render
  const [selectedComponent, setSelectedComponent] = useState("AdmissionAddCourse");

  const handleButtonClick = (component) => {
    setSelectedComponent(component);
  };

  return (
    <Box sx={{ padding: "20px", display: "flex", justifyContent: "center" }}>
      {/* Sidebar Buttons */}
      <Paper
        elevation={3}
        sx={{
          width: "220px",
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          height: "200px",
          justifyContent: "space-evenly",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          backgroundColor: "#f0f0f0",
        }}
      >
        {/* Add Course Button */}
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
            padding: "5px 10px",
            color: selectedComponent === "AdmissionAddCourse" ? "white" : "text.primary",
            fontWeight: selectedComponent === "AdmissionAddCourse" ? "bold" : "normal",
            borderRadius: selectedComponent === "AdmissionAddCourse" ? "10px" : "4px",
            backgroundColor: selectedComponent === "AdmissionAddCourse" ? "#3498DB" : "",
            textAlign: "center",
            transition: "all 0.3s ease",
            fontSize: "13px",
            boxShadow:
              selectedComponent === "AdmissionAddCourse"
                ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                : "none",
          }}
          onClick={() => handleButtonClick("AdmissionAddCourse")}
        >
          <AddCourseIcon /> Add Course
        </Typography>

        {/* Add Source Button */}
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
            padding: "5px 10px",
            color: selectedComponent === "AddmissionSource" ? "white" : "text.primary",
            fontWeight: selectedComponent === "AddmissionSource" ? "bold" : "normal",
            borderRadius: selectedComponent === "AddmissionSource" ? "10px" : "4px",
            backgroundColor: selectedComponent === "AddmissionSource" ? "#3498DB" : "",
            textAlign: "center",
            transition: "all 0.3s ease",
            fontSize: "13px",
            boxShadow:
              selectedComponent === "AddmissionSource"
                ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                : "none",
          }}
          onClick={() => handleButtonClick("AddmissionSource")}
        >
          <SourceIcon /> Add Source
        </Typography>

        {/* Add Guide Button */}
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
            padding: "5px 10px",
            color: selectedComponent === "AddGuide" ? "white" : "text.primary",
            fontWeight: selectedComponent === "AddGuide" ? "bold" : "normal",
            borderRadius: selectedComponent === "AddGuide" ? "10px" : "4px",
            backgroundColor: selectedComponent === "AddGuide" ? "#3498DB" : "",
            textAlign: "center",
            transition: "all 0.3s ease",
            fontSize: "13px",
            boxShadow:
              selectedComponent === "AddGuide"
                ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                : "none",
          }}
          onClick={() => handleButtonClick("AddGuide")}
        >
          <GuideIcon /> Add Guide
        </Typography>
      </Paper>

      {/* Content Area */}
      <Box sx={{ flex: 1, marginLeft: "30px", padding: "20px" }}>
        {selectedComponent === "AdmissionAddCourse" && <AdmissionAddCourse />}
        {selectedComponent === "AddmissionSource" && <AddmissionSource />}
        {selectedComponent === "AddGuide" && <AddGuide />}
      </Box>
    </Box>
  );
};

export default AdmissionSetting;
