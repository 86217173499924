import React, { useEffect, useState } from "react";
import {useParams, useNavigate} from "react-router-dom"
import axios from "axios";
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  Typography,
  TextField,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
} from "@mui/material";

const ViewLeave = () => {
  const [leaves, setLeaves] = useState([]);
  const [filteredLeaves, setFilteredLeaves] = useState([]);
  const [filterStatus, setFilterStatus] = useState("All");
  const [filterDuration, setFilterDuration] = useState("All");
  const [customStartDate, setCustomStartDate] = useState("");
  const [customEndDate, setCustomEndDate] = useState("");

  const [leaveSummary, setLeaveSummary] = useState({
    TotalPaidLeaves: 0,
    TotalUnpaidLeaves: 0,
    TotalAppliedPaidLeaves: 0,
    TotalAppliedLeaves: 0,
    RemainingPaidLeaves: 0,
  });

  const { empID } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLeaveSummary = async () => {
      try {
        const response = await axios.get(`https://pjsofttech.in:10443/leaveSummary/${empID}`);
        setLeaveSummary(response.data);
      } catch (error) {
        console.error("Error fetching leave summary", error);
      }
    };

    fetchLeaveSummary();
  }, [empID]);

  useEffect(() => {
    const fetchLeaves = async () => {
      try {
        const response = await axios.get(`https://pjsofttech.in:10443/leaveRequestsByEmpID/${empID}`); // Ensure you have the correct endpoint here
        setLeaves(response.data);
      } catch (error) {
        console.error("Error fetching leaves", error);
      }
    };

    fetchLeaves();
  }, [empID]);

  useEffect(() => {
    // Filter leaves based on status and duration
    let filtered = leaves;

    if (filterStatus !== "All") {
      filtered = filtered.filter(leave => leave.status === filterStatus);
    }

    if (filterDuration === "Last 7 Days") {
      const dateLimit = new Date();
      dateLimit.setDate(dateLimit.getDate() - 7);
      filtered = filtered.filter(leave => new Date(leave.fromDate) >= dateLimit);
    } else if (filterDuration === "Last 30 Days") {
      const dateLimit = new Date();
      dateLimit.setDate(dateLimit.getDate() - 30);
      filtered = filtered.filter(leave => new Date(leave.fromDate) >= dateLimit);
    } else if (filterDuration === "Last 365 Days") {
      const dateLimit = new Date();
      dateLimit.setFullYear(dateLimit.getFullYear() - 1);
      filtered = filtered.filter(leave => new Date(leave.fromDate) >= dateLimit);
    } else if (filterDuration === "Custom Range" && customStartDate && customEndDate) {
      filtered = filtered.filter(leave => {
        const leaveDate = new Date(leave.fromDate);
        return leaveDate >= new Date(customStartDate) && leaveDate <= new Date(customEndDate);
      });
    }

    setFilteredLeaves(filtered);
  }, [leaves, filterStatus, filterDuration, customStartDate, customEndDate]);

  const handleFilterChange = (event) => {
    setFilterStatus(event.target.value);
  };

  const handleDurationChange = (event) => {
    setFilterDuration(event.target.value);
    if (event.target.value !== "Custom Range") {
      setCustomStartDate("");
      setCustomEndDate("");
    }
  };

  const handleCustomStartDateChange = (event) => {
    setCustomStartDate(event.target.value);
  };

  const handleCustomEndDateChange = (event) => {
    setCustomEndDate(event.target.value);
  };

  return (
    <div>

      <Grid container spacing={2} alignItems="center" justifyContent="space-between" className='textField-root'>
        {/* Card for Total Paid Leaves */}
        <Grid item xs={12} md={2.4}>
          <Card elevation={3} sx={{ backgroundColor: "#F9E79F"}}>           
            <CardContent>
            <Typography sx={{fontSize:'14px', fontWeight:'bold'}} gutterBottom>
              Total Paid Leaves Assigned
              </Typography>
              <Typography variant="h5">
                {leaveSummary.TotalPaidLeaves}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Card for Total Applied Paid Leaves */}
        <Grid item xs={12} sm={6} md={2.4}>
          <Card elevation={3} sx={{ backgroundColor: "#FF6F61"}}>
            <CardContent>
            <Typography sx={{fontSize:'14px', fontWeight:'bold'}} gutterBottom>
            Total Used Paid Leaves
              </Typography>
              <Typography variant="h5">
                {leaveSummary.TotalAppliedPaidLeaves}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Card for Total Applied Leaves */}
        <Grid item xs={12} sm={6} md={2.4}>
          <Card elevation={3} sx={{backgroundColor: "#3498DB" }}>
            <CardContent>
            <Typography sx={{fontSize:'14px', fontWeight:'bold'}} gutterBottom>
            Total Applied Leaves
              </Typography>
              <Typography variant="h5">
                {leaveSummary.TotalAppliedLeaves}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Card for Remaining Paid Leaves */}
        <Grid item xs={12} sm={6} md={2.4}>
        <Card elevation={3} sx={{backgroundColor: "#F9E79F" }}>
            <CardContent>
            <Typography sx={{fontSize:'14px', fontWeight:'bold'}} gutterBottom>
            Remaining Paid Leaves
              </Typography>
              <Typography variant="h5">
                {leaveSummary.RemainingPaidLeaves}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Card for Unpaid Leaves */}
        <Grid item xs={12} sm={6} md={2.4}>
      
          <Card elevation={3} sx={{backgroundColor: "#FF6F61" }}>
            <CardContent>
            <Typography sx={{fontSize:'14px', fontWeight:'bold'}} gutterBottom>
            Used Unpaid Leaves
              </Typography>
              <Typography variant="h5">
                {leaveSummary.TotalUnpaidLeaves}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Filter Options */}
        <Grid container spacing={2} item xs={12} alignItems="center">
        <Grid item xs={4} sm={6} md={3}>
          <TextField
            value={filterStatus}
            fullWidth
            select
            label="Status"
            onChange={handleFilterChange}
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Pending">Pending</MenuItem>
            <MenuItem value="Approved">Approved</MenuItem>
            <MenuItem value="Rejected">Rejected</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <TextField
            select
            label="Duration"
            fullWidth
            value={filterDuration}
            onChange={handleDurationChange}
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Last 7 Days">Last 7 Days</MenuItem>
            <MenuItem value="Last 30 Days">Last 30 Days</MenuItem>
            <MenuItem value="Last 365 Days">Last 365 Days</MenuItem>
            <MenuItem value="Custom Range">Custom Range</MenuItem>
          </TextField>
        </Grid>

        {filterDuration === "Custom Range" && (
          <>
            <Grid item xs={12} sm={6} md={2.4}>
              <TextField
                type="date"
                label="Start Date"
                fullWidth
                value={customStartDate}
                onChange={handleCustomStartDateChange}
                InputLabelProps={{shrink:true}}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2.4}>
              <TextField
                type="date"
                label="End Date"
                fullWidth
                value={customEndDate}
                onChange={handleCustomEndDateChange}
                InputLabelProps={{shrink:true}}

              />
            </Grid>
          </>
        )}
          <Grid item xs={12} md={1.7}>
            <Button
              variant="contained"
              onClick={() => navigate('/layout/leave-manager/leaveReport')}
            >
              Back to Report
            </Button>
          </Grid>
      </Grid>
    
      </Grid>

      <TableContainer component={Box} sx={{ mt: 4 }}>
        <Table className='table-root'>
          <TableHead>
            <TableRow>
              {[
                "ID",
                "From Date",
                "To Date",
                "Leave Days",
                "Type",
                "Leave Type",
                "Reason Description",

                "Status",

              ].map((header) => (
                <TableCell
                  key={header}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredLeaves.map((leave) => (
              <TableRow key={leave.empID}>
                <TableCell>{leave.id}</TableCell>
                <TableCell>{leave.fromDate}</TableCell>
                <TableCell>{leave.toDate}</TableCell>
                <TableCell>{leave.leaveRequired}</TableCell>
                <TableCell>{leave.type}</TableCell>
                <TableCell>{leave.medicalleave}</TableCell>
                <TableCell>{leave.reasondescription}</TableCell>

                <TableCell sx={{
          fontWeight: 'bold',
          color: leave.status === 'Approved' ? 'green' : 
          leave.status === 'Pending' ? 'orange' : 
          leave.status === 'Rejected' ? 'red' : 'inherit',
        }}>{leave.status}</TableCell>


              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ViewLeave;
