import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  MenuItem,
  Select,
  Grid,
  FormControl,
  InputLabel
} from "@mui/material";
import axios from "axios";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { CSVLink } from "react-csv";

const FeeReport = () => {
  const [fees, setFees] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filter, setFilter] = useState("all");
  const [medium, setMedium] = useState("");
  const [standard, setStandard] = useState("");
  const [feeStatus, setFeeStatus] = useState("");
  const [feesCollectionType, setFeesCollectionType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const navigate = useNavigate();
  const institutecode = () => localStorage.getItem("institutecode");

  // Fetch all fees once
  const fetchAllFees = useCallback(async () => {
    try {
      const response = await axios.get(`https://pjsofttech.in:9443/getAllFees?institutecode=${institutecode()}`);
      if (response.status === 200 && Array.isArray(response.data)) {
        setFees(response.data);
      } else {
        console.error("Unexpected response format:", response);
        setFees([]);
      }
    } catch (error) {
      console.error("There was an error fetching the Fees!", error);
      setFees([]);
    }
  }, []);

  useEffect(() => {
    fetchAllFees();
  }, [fetchAllFees]);

  const getStatusColor = (feesStatus) => {
    switch (feesStatus) {
      case "Complete":
        return "green";
      case "Incomplete":
        return "red";
      case "Pending":
        return "orange";
      default:
        return "";
    }
  };

  // Extract unique values for filter dropdowns
  const uniqueMediums = useMemo(() => {
    const mediums = fees.map(fee => fee.medium);
    return [...new Set(mediums)];
  }, [fees]);

  const uniqueStandards = useMemo(() => {
    const standards = fees.map(fee => fee.standard);
    return [...new Set(standards)];
  }, [fees]);

  const uniqueFeeStatuses = useMemo(() => {
    const statuses = fees.map(fee => fee.feesStatus);
    return [...new Set(statuses)];
  }, [fees]);

  const uniqueFeesCollectionTypes = useMemo(() => {
    const collectionTypes = fees.map(fee => fee.feesCollectionType);
    return [...new Set(collectionTypes)];
  }, [fees]);

  // Compute filtered fees
  const filteredFees = useMemo(() => {
    return fees.filter(fee => {
      // Search filter
      const matchesSearch = fee.studentName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            fee.rollNo.toLowerCase().includes(searchQuery.toLowerCase());

      // Standard filter
      const matchesStandard = standard ? fee.standard === standard : true;

      // Medium filter
      const matchesMedium = medium ? fee.medium === medium : true;

      // Fee Status filter
      const matchesFeeStatus = feeStatus ? fee.feesStatus === feeStatus : true;

      // Fees Collection Type filter
      const matchesFeesCollectionType = feesCollectionType ? fee.feesCollectionType === feesCollectionType : true;

      // Date filter
      let matchesDate = true;
      const feeDate = new Date(fee.registrationDate);
      const today = new Date();

      if (filter === "7") {
        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(today.getDate() - 7);
        matchesDate = feeDate >= sevenDaysAgo;
      } else if (filter === "30") {
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(today.getDate() - 30);
        matchesDate = feeDate >= thirtyDaysAgo;
      } else if (filter === "365") {
        const threeSixtyFiveDaysAgo = new Date();
        threeSixtyFiveDaysAgo.setDate(today.getDate() - 365);
        matchesDate = feeDate >= threeSixtyFiveDaysAgo;
      } else if (filter === "Custom") {
        if (startDate && endDate) {
          const start = new Date(startDate);
          const end = new Date(endDate);
          matchesDate = feeDate >= start && feeDate <= end;
        }
      }

      return matchesSearch && matchesStandard && matchesMedium && matchesFeeStatus && matchesFeesCollectionType && matchesDate;
    });
  }, [fees, searchQuery, standard, medium, feeStatus, feesCollectionType, filter, startDate, endDate]);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };
  const handleDownloadPDF = () => {
    const doc = new jsPDF("landscape");

    // Title
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text("Fee Report", 14, 15);

    // Subtitle
    doc.setFontSize(8);
    doc.setFont("helvetica", "normal");
    // doc.text(`Institute Code: ${institutecode()}`, 14, 22);
    doc.text(`Generated on: ${new Date().toLocaleDateString()}`, 14, 27);

    // Define table settings
    doc.autoTable({
      startY: 32,
      head: [[
        "Student Name", "Roll No", "Std", "Med", "Div",
        "Tui Fee", "Adm Fee", "Pract Fee", "Comp Class Fee",
        "Exam Fee", "Uniform", "Transport", "Hostel",
        "Bldg Fund", "Library", "Sport", "Fee Amt",
        "Disc", "GST", "Late Fee", "Pending Amt",
        "Coll Type", "Status", "Trans ID", "GST No",
        // "Show GST",
         "Reg Date"
      ]],
      body: filteredFees.map((fee) => [
        fee.studentName,
        fee.rollNo,
        fee.standard,
        fee.medium,
        fee.division,
        fee.tuitionFee,
        fee.admissionFee,
        fee.practicalFee,
        fee.computerClassFee,
        fee.examFees,
        fee.uniformFee,
        fee.transportBusFee,
        fee.hostelFee,
        fee.buildingFundFee,
        fee.libraryFees,
        fee.sportFees,
        fee.feesAmount,
        fee.discount,
        fee.gst,
        fee.lateFeeCharges,
        fee.pendingFeesAmount,
        fee.feesCollectionType,
        fee.feesStatus,
        fee.transactionId,
        fee.gstNo,
        // fee.showGstNo ? "Yes" : "No",
        fee.registrationDate,
      ]),
      styles: {
        font: "helvetica",
        fontSize: 5,  // Smaller font size to fit more columns
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
        overflow: "linebreak",
        valign: "middle",
        cellPadding: 0.6,  // Reduced padding for compact cells
      },
      headStyles: {
        fillColor: [220, 220, 220],
        textColor: 0,
        fontStyle: "bold",
        halign: "center",
        fontSize: 6,  // Smaller font size for headers
      },
      columnStyles: {
        0: { cellWidth: 15 },  // Student Name
        1: { cellWidth: 8 },  // Roll No
        2: { cellWidth: 8 },   // Standard (abbreviated as Std)
        3: { cellWidth: 12 },   // Medium (abbreviated as Med)
        4: { cellWidth: 8 },   // Division (abbreviated as Div)
        5: { cellWidth: 10 },  // Tuition Fee (abbreviated as Tui Fee)
        6: { cellWidth: 10 },  // Admission Fee (abbreviated as Adm Fee)
        7: { cellWidth: 10 },  // Practical Fee (abbreviated as Pract Fee)
        8: { cellWidth: 10 },  // Computer Class Fee (abbreviated as Comp Class Fee)
        9: { cellWidth: 10 },  // Exam Fee
        10: { cellWidth: 10 }, // Uniform Fee
        11: { cellWidth: 10 }, // Transport Bus Fee
        12: { cellWidth: 10 }, // Hostel Fee
        13: { cellWidth: 10 }, // Building Fund Fee (abbreviated as Bldg Fund)
        14: { cellWidth: 10 }, // Library Fees
        15: { cellWidth: 10 }, // Sport Fees
        16: { cellWidth: 15 }, // Fees Amount (abbreviated as Fee Amt)
        17: { cellWidth: 8 },  // Discount (abbreviated as Disc)
        18: { cellWidth: 8 },  // GST
        19: { cellWidth: 10 }, // Late Fee Charges (abbreviated as Late Fee)
        20: { cellWidth: 17 }, // Pending Fees Amount (abbreviated as Pending Amt)
        21: { cellWidth: 10 }, // Fees Collection Type (abbreviated as Coll Type)
        22: { cellWidth: 10 }, // Fees Status (abbreviated as Status)
        23: { cellWidth: 12 }, // Transaction ID (abbreviated as Trans ID)
        24: { cellWidth: 8 },  // GST No
        // 25: { cellWidth: 8 },  // Show GST No (abbreviated as Show GST)
        26: { cellWidth: 10 }, // Registration Date (abbreviated as Reg Date)
      },
      margin: { top: 10},  // Further reduced side margins
      theme: "grid",
    });

    // Footer with page number
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(`Page ${i} of ${pageCount}`, 14, doc.internal.pageSize.height - 10);
    }

    doc.save("fee_report.pdf");
  };

  return (
    <div
  >
        <Grid container spacing={2} className="textField-root">
          {/* Search Field */}
          <Grid item xs={12} sm={1.5}>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              fullWidth
              onChange={handleSearch}
            />
          </Grid>

          {/* Date Filter */}
          <Grid item xs={12} sm={1.5}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel>Filter</InputLabel>
              <Select
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                label="Filter"
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="7">Last 7 Days</MenuItem>
                <MenuItem value="30">Last 30 Days</MenuItem>
                <MenuItem value="365">Last 365 Days</MenuItem>
                <MenuItem value="Custom">Custom Date</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Custom Date Range */}
          {filter === "Custom" && (
            <>
              <Grid item xs={12} sm={1.5}>
                <TextField
                  label="Start Date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setStartDate(e.target.value)}
                  size="small"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={1.5}>
                <TextField
                  label="End Date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setEndDate(e.target.value)}
                  size="small"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </>
          )}

          {/* Standard Dropdown */}
          <Grid item xs={12} sm={1.5}>
            <TextField
              fullWidth
              select
              label="Standard"
              value={standard}
              onChange={(e) => setStandard(e.target.value)}
              variant="outlined"
              size="small"
            >
              <MenuItem value="">All Standards</MenuItem>
              {uniqueStandards.map((std) => (
                <MenuItem key={std} value={std}>
                  {std}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Medium Dropdown */}
          <Grid item xs={12} sm={1.5}>
            <TextField
              fullWidth
              select
              label="Medium"
              value={medium}
              onChange={(e) => setMedium(e.target.value)}
              variant="outlined"
              size="small"
            >
              <MenuItem value="">All Mediums</MenuItem>
              {uniqueMediums.map((med) => (
                <MenuItem key={med} value={med}>
                  {med}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Fee Status Dropdown */}
          <Grid item xs={12} sm={1.5}>
            <TextField
              fullWidth
              select
              label="Fee Status"
              value={feeStatus}
              onChange={(e) => setFeeStatus(e.target.value)}
              variant="outlined"
              size="small"
            >
              <MenuItem value="">All Statuses</MenuItem>
              {uniqueFeeStatuses.map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Fees Collection Type Dropdown */}
          <Grid item xs={12} sm={1.5}>
            <TextField
              fullWidth
              select
              label="Fees Collection Type"
              value={feesCollectionType}
              onChange={(e) => setFeesCollectionType(e.target.value)}
              variant="outlined"
              size="small"
            >
              <MenuItem value="">All Types</MenuItem>
              {uniqueFeesCollectionTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Download Buttons */}
          <Grid item xs={12} sm={1.4}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleDownloadPDF}
              size="small"
            >
              Download PDF
            </Button>
          </Grid>
          <Grid item xs={12} sm={1.4}>
            <CSVLink
              data={filteredFees}
              filename="fee_report.csv"
            >
              <Button variant="contained"  size="small">
                Download CSV
              </Button>
            </CSVLink>
          </Grid>
        </Grid>

        {/* Fee Records Table */}
        <Container style={{ padding: "10px", overflow: "hidden" }}>
        <TableContainer component={Paper}      style={{
            maxHeight: "400px", // set your desired height
            overflow: "auto",
            position: "relative", // to position absolute scrollbar
            paddingRight: "15px" // to account for hidden scrollbar width
          }}>
          <Table className="table-root">
            <TableHead>
              <TableRow>
                <TableCell>Student Name</TableCell>
                <TableCell>Roll No</TableCell>
                <TableCell>Standard</TableCell>
                <TableCell>Medium</TableCell>
                <TableCell>Division</TableCell>
                <TableCell>Tuition Fee</TableCell>
                <TableCell>Admission Fee</TableCell>
                <TableCell>Practical Fee</TableCell>
                <TableCell>Computer Class Fee</TableCell>
                <TableCell>Exam Fees</TableCell>
                <TableCell>Uniform Fee</TableCell>
                <TableCell>Transport Bus Fee</TableCell>
                <TableCell>Hostel Fee</TableCell>
                <TableCell>Building Fund Fee</TableCell>
                <TableCell>Library Fees</TableCell>
                <TableCell>Sport Fees</TableCell>
                <TableCell>Fees Amount</TableCell>
                <TableCell>Discount</TableCell>
                <TableCell>GST</TableCell>
                <TableCell>Late Fee Charges</TableCell>
                <TableCell>Pending Fees Amount</TableCell>
                <TableCell>Fees CollectionType</TableCell>
                <TableCell>Transaction ID</TableCell>
                <TableCell>GST No</TableCell>
                <TableCell>Show GST No</TableCell>
                <TableCell>Registration Date</TableCell>
                <TableCell>Fees Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredFees.map((fee) => (
                <TableRow key={fee.id}>
                  <TableCell>{fee.studentName}</TableCell>
                  <TableCell>{fee.rollNo}</TableCell>
                  <TableCell>{fee.standard}</TableCell>
                  <TableCell>{fee.mediumname}</TableCell>
                  <TableCell>{fee.division}</TableCell>
                  <TableCell>{fee.tuitionFee}</TableCell>
                  <TableCell>{fee.admissionFee}</TableCell>
                  <TableCell>{fee.practicalFee}</TableCell>
                  <TableCell>{fee.computerClassFee}</TableCell>
                  <TableCell>{fee.examFees}</TableCell>
                  <TableCell>{fee.uniformFee}</TableCell>
                  <TableCell>{fee.transportBusFee}</TableCell>
                  <TableCell>{fee.hostelFee}</TableCell>
                  <TableCell>{fee.buildingFundFee}</TableCell>
                  <TableCell>{fee.libraryFees}</TableCell>
                  <TableCell>{fee.sportFees}</TableCell>
                  <TableCell>{fee.feesAmount}</TableCell>
                  <TableCell>{fee.discount}</TableCell>
                  <TableCell>{fee.gst}</TableCell>
                  <TableCell>{fee.lateFeeCharges}</TableCell>
                  <TableCell>{fee.pendingFeesAmount}</TableCell>
                  <TableCell>{fee.feesCollectionType}</TableCell>
                  <TableCell>{fee.transactionId}</TableCell>
                  <TableCell>{fee.gstNo}</TableCell>
                  <TableCell>{fee.showGstNo ? "Yes" : "No"}</TableCell>
                  <TableCell>{fee.registrationDate}</TableCell>
                  <TableCell style={{ color: getStatusColor(fee.feesStatus), fontWeight:'bold' }}>{fee.feesStatus}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </Container>
    </div>
  );
};

export default FeeReport;

