// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { Link, useParams, useNavigate } from "react-router-dom";
// import {
//   TextField,
//   Button,
//   Grid,
//   Box,
//   MenuItem,
//   Typography,
//   Paper,Table , TableContainer, TableHead, TableRow, TableBody, TableCell
// } from "@mui/material";
// import Swal from "sweetalert2";

// const months = [
//   { value: 1, label: "January" },
//   { value: 2, label: "February" },
//   { value: 3, label: "March" },
//   { value: 4, label: "April" },
//   { value: 5, label: "May" },
//   { value: 6, label: "June" },
//   { value: 7, label: "July" },
//   { value: 8, label: "August" },
//   { value: 9, label: "September" },
//   { value: 10, label: "October" },
//   { value: 11, label: "November" },
//   { value: 12, label: "December" },
// ];

// const yearsAhead = 5;
// const yearsBehind = 5;
// const currentYear = new Date().getFullYear();
// const years = Array.from(
//   new Array(yearsAhead + yearsBehind + 1),
//   (_, index) => currentYear - yearsBehind + index
// );

// export default function AddSalary() {
//   const navigate = useNavigate();
//   const { empID } = useParams();

//   const [employee, setEmployee] = useState({
//     fullName: "",
//     salary: "",
//     department: "",
//     employeecategory: "",
//     incentive: "",
//     transactionId: "",
//     taAllowance: "",
//     spi: "",
//   });

//   const [date, setDate] = useState("");
//   const [daysOfMonth, setDaysOfMonth] = useState("");
//   const [month, setMonth] = useState("");
//   const [year, setYear] = useState("");

//   const onInputChange = (e) => {
//     setEmployee({ ...employee, [e.target.name]: e.target.value });
//   };

//   const fetchEmployeeDetails = async () => {
//     try {
//       const response = await axios.get(`https://pjsofttech.in:10443/empById/${empID}`);
//       setEmployee(response.data);
//     } catch (error) {
//       console.error("Error fetching employee details:", error);
//     }
//   };

//   useEffect(() => {
//     if (empID) fetchEmployeeDetails();
//   }, [empID]);

//   const onSubmit = async (e) => {
//     e.preventDefault();

//     const paymentDate = `${year}-${String(month).padStart(2, "0")}-${String(
//       date
//     ).padStart(2, "0")}`;

//     try {
//       await axios.post(`https://pjsofttech.in:10443/salaries/add`, {
//         empID,
//         basicSalary: parseInt(employee.salary),
//         paymentDate,
//         daysOfMonth: parseInt(daysOfMonth),
//         month: parseInt(month),
//         year: parseInt(year),
//         ...employee,
//       });

//       await Swal.fire("Success!", "Salary added successfully!", "success");
//       navigate("/layout/employee-salary-manager/salary-list");
//     } catch (error) {
//       console.error("Error adding salary:", error);
//       Swal.fire("Error!", "Failed to add salary. Please try again.", "error");
//     }
//   };

//   return (
//     <Grid container justifyContent="center" sx={{ mt: 4 }}>
//       <Grid item xs={12} md={8}>
//         <Paper elevation={3} sx={{ p: 3 }}>
//           <Typography variant="h5" align="center" gutterBottom>
//             Add Salary
//           </Typography>
//           <form onSubmit={onSubmit}>



//           <Box
//               sx={{
//                 mb: 3,
//                 textAlign: "center",
//                 alignItems: "center",
//                 padding: "10px",
//                 background: "#F0F0F0",
//               }}
//               border={"1px solid #F0F0F0"}
//             >
//               <Typography variant="subtitle1" gutterBottom fontWeight={'bold'}>
//                 Employee Details
//               </Typography>

//               <TableContainer  elevation={3} sx={{ mt: 2, background:'#F0F0F0' }}>
//                 <Table size="small">
//                   <TableHead>
//                     <TableRow>
//                       <TableCell><Typography fontWeight={'bold'} textAlign={'center'} variant="subtitle2">ID</Typography></TableCell>
//                       <TableCell><Typography fontWeight={'bold'} textAlign={'center'} variant="subtitle2">Name</Typography></TableCell>
//                       <TableCell><Typography fontWeight={'bold'} textAlign={'center'} variant="subtitle2">Salary</Typography></TableCell>
//                       <TableCell><Typography fontWeight={'bold'} textAlign={'center'} variant="subtitle2">Department</Typography></TableCell>
//                       <TableCell><Typography fontWeight={'bold'} textAlign={'center'} variant="subtitle2">Category</Typography></TableCell>
//                     </TableRow>
//                   </TableHead>
//                   <TableBody>
//                     <TableRow>
//                       <TableCell><Typography variant="body2" textAlign={'center'}>{empID}</Typography></TableCell>
//                       <TableCell><Typography variant="body2" textAlign={'center'}>{employee.fullName}</Typography></TableCell>
//                       <TableCell><Typography variant="body2" textAlign={'center'}>{employee.salary}</Typography></TableCell>
//                       <TableCell><Typography variant="body2" textAlign={'center'}>{employee.department}</Typography></TableCell>
//                       <TableCell><Typography variant="body2" textAlign={'center'}>{employee.employeecategory}</Typography></TableCell>
//                     </TableRow>
//                   </TableBody>
//                 </Table>
//               </TableContainer>
//             </Box>


//             {/* <Box sx={{ mb: 3, textAlign:'center', alignItems:'center', padding:'10px', background:'#E6ECF8' }} border={'1px solid #E6ECF8'} borderRadius={'12px'}>
//               <Typography variant="subtitle1" gutterBottom>
//                 Employee Details
//               </Typography>
//               <Grid container spacing={0}>
//                 <Grid item xs={6} md={2}>
//                   <Typography variant="caption">ID</Typography>
//                   <Typography>{empID}</Typography>
//                 </Grid>
//                 <Grid item xs={6} md={2}>
//                   <Typography variant="caption">Name</Typography>
//                   <Typography>{employee.fullName}</Typography>
//                 </Grid>
//                 <Grid item xs={6} md={2}>
//                   <Typography variant="caption">Salary</Typography>
//                   <Typography>{employee.salary}</Typography>
//                 </Grid>
//                 <Grid item xs={2}>
//                   <Typography variant="caption">Department</Typography>
//                   <Typography>{employee.department}</Typography>
//                 </Grid>
//                 <Grid item xs={2}>
//                   <Typography variant="caption">Category</Typography>
//                   <Typography>{employee.employeecategory}</Typography>
//                 </Grid>
//               </Grid>
//             </Box> */}

//             <Grid container spacing={2} className="textField-root">
//               <Grid item xs={4}>
//                 <TextField
//                   label="Date"
//                   type="number"
//                   fullWidth
//                   value={date}
//                   onChange={(e) => setDate(e.target.value)}
//                 />
//               </Grid>
//               <Grid item xs={4}>
//                 <TextField
//                   label="Month"
//                   select
//                   fullWidth
//                   value={month}
//                   onChange={(e) => setMonth(e.target.value)}
//                 >
//                   {months.map((m) => (
//                     <MenuItem key={m.value} value={m.value}>
//                       {m.label}
//                     </MenuItem>
//                   ))}
//                 </TextField>
//               </Grid>
//               <Grid item xs={4}>
//                 <TextField
//                   label="Year"
//                   select
//                   fullWidth
//                   value={year}
//                   onChange={(e) => setYear(e.target.value)}
//                 >
//                   {years.map((y) => (
//                     <MenuItem key={y} value={y}>
//                       {y}
//                     </MenuItem>
//                   ))}
//                 </TextField>
//               </Grid>
//               <Grid item xs={4}>
//                 <TextField
//                   label="Working Days"
//                   type="number"
//                   fullWidth
//                   value={daysOfMonth}
//                   onChange={(e) => setDaysOfMonth(e.target.value)}
//                 />
//               </Grid>
//               <Grid item xs={4}>
//                 <TextField
//                   label="Incentive"
//                   type="number"
//                   fullWidth
//                   name="incentive"
//                   value={employee.incentive}
//                   onChange={onInputChange}
//                 />
//               </Grid>
//               <Grid item xs={4}>
//                 <TextField
//                   label="Travel Allowance"
//                   type="number"
//                   fullWidth
//                   name="taAllowance"
//                   value={employee.taAllowance}
//                   onChange={onInputChange}
//                 />
//               </Grid>
//               <Grid item xs={4}>
//                 <TextField
//                   label="Dearness Allowance"
//                   type="number"
//                   fullWidth
//                   name="spi"
//                   value={employee.spi}
//                   onChange={onInputChange}
//                 />
//               </Grid>
//             </Grid>

//             <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
//               <Button
//                 variant="contained"
//                 color="secondary"
//                 component={Link}
//                 to="/layout/employee-salary-manager/add-detail"
//               >
//                 Cancel
//               </Button>
//               <Button type="submit" variant="contained" color="primary">
//                 Add Salary
//               </Button>
//             </Box>
//           </form>
//         </Paper>
//       </Grid>
//     </Grid>
//   );
// }



import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Grid,
  Box,
  MenuItem,
  Typography,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import Swal from "sweetalert2";

const months = [
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
];

const yearsAhead = 5;
const yearsBehind = 5;
const currentYear = new Date().getFullYear();
const years = Array.from(
  new Array(yearsAhead + yearsBehind + 1),
  (_, index) => currentYear - yearsBehind + index
);

export default function AddSalary({ empID, onClose }) {
  const [employee, setEmployee] = useState({
    fullName: "",
    salary: "",
    department: "",
    employeecategory: "",
    incentive: "",
    transactionId: "",
    taAllowance: "",
    spi: "",
    status: "Pending", // Set default status to Pending
  });

  const [date, setDate] = useState("");
  const [daysOfMonth, setDaysOfMonth] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const onInputChange = (e) => {
    setEmployee({ ...employee, [e.target.name]: e.target.value });
  };

  const fetchEmployeeDetails = async () => {
    try {
      const response = await axios.get(`https://pjsofttech.in:10443/empById/${empID}`);
      setEmployee((prevState) => ({
        ...prevState,
        ...response.data,
        status: "Pending", // Ensure status stays Pending
      }));
    } catch (error) {
      console.error("Error fetching employee details:", error);
    }
  };

  useEffect(() => {
    if (empID) fetchEmployeeDetails();
  }, [empID]);

  const onSubmit = async (e) => {
    e.preventDefault();

    const paymentDate = `${year}-${String(month).padStart(2, "0")}-${String(
      date
    ).padStart(2, "0")}`;

    try {
      await axios.post(`https://pjsofttech.in:10443/salaries/add`, {
        empID,
        basicSalary: parseInt(employee.salary),
        paymentDate,
        daysOfMonth: parseInt(daysOfMonth),
        month: parseInt(month),
        year: parseInt(year),
        ...employee,
      });

      await Swal.fire("Success!", "Salary added successfully!", "success");
      onClose(); // Close modal after successful submission
    } catch (error) {
      console.error("Error adding salary:", error);
      Swal.fire("Error!", "Failed to add salary. Please try again.", "error");
    }
  };

  return (
    <div>
      <Grid item xs={12} md={8}>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Typography variant="h5" align="center" gutterBottom color={'#00649F'}>
            Add Salary
          </Typography>
          <form onSubmit={onSubmit}>
            <Box
              sx={{
                mb: 3,
                textAlign: "center",
                alignItems: "center",
                padding: "10px",
                background: "#F0F0F0",
              }}
              border={"1px solid #F0F0F0"}
            >
              <Typography variant="subtitle1" gutterBottom fontWeight={'bold'}>
                Employee Details
              </Typography>

              <TableContainer elevation={3} sx={{ mt: 2, background: '#F0F0F0' }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell><Typography fontWeight={'bold'} textAlign={'center'} variant="subtitle2">ID</Typography></TableCell>
                      <TableCell><Typography fontWeight={'bold'} textAlign={'center'} variant="subtitle2">Name</Typography></TableCell>
                      <TableCell><Typography fontWeight={'bold'} textAlign={'center'} variant="subtitle2">Salary</Typography></TableCell>
                      <TableCell><Typography fontWeight={'bold'} textAlign={'center'} variant="subtitle2">Department</Typography></TableCell>
                      <TableCell><Typography fontWeight={'bold'} textAlign={'center'} variant="subtitle2">Category</Typography></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell><Typography variant="body2" textAlign={'center'}>{empID}</Typography></TableCell>
                      <TableCell><Typography variant="body2" textAlign={'center'}>{employee.fullName}</Typography></TableCell>
                      <TableCell><Typography variant="body2" textAlign={'center'}>{employee.salary}</Typography></TableCell>
                      <TableCell><Typography variant="body2" textAlign={'center'}>{employee.department}</Typography></TableCell>
                      <TableCell><Typography variant="body2" textAlign={'center'}>{employee.employeecategory}</Typography></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            <Grid container spacing={2} className="textField-root">
              <Grid item xs={4}>
                <TextField
                  label="Date"
                  type="number"
                  fullWidth
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Month"
                  select
                  fullWidth
                  value={month}
                  onChange={(e) => setMonth(e.target.value)}
                >
                  {months.map((m) => (
                    <MenuItem key={m.value} value={m.value}>
                      {m.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Year"
                  select
                  fullWidth
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                >
                  {years.map((y) => (
                    <MenuItem key={y} value={y}>
                      {y}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Working Days"
                  type="number"
                  fullWidth
                  value={daysOfMonth}
                  onChange={(e) => setDaysOfMonth(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Incentive"
                  type="number"
                  fullWidth
                  name="incentive"
                  value={employee.incentive}
                  onChange={onInputChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Travel Allowance"
                  type="number"
                  fullWidth
                  name="taAllowance"
                  value={employee.taAllowance}
                  onChange={onInputChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Dearness Allowance"
                  type="number"
                  fullWidth
                  name="spi"
                  value={employee.spi}
                  onChange={onInputChange}
                />
              </Grid>
            </Grid>

            <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={onClose} // Close modal on cancel
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary"  onClick={onClose}>
                Add Salary
              </Button>
            </Box>
          </form>
        </Paper>
      </Grid>
    </div>
  );
}
