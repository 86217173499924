import React, { useEffect, useState, useRef } from "react";
import { Typography, Box, Paper } from "@mui/material";
import axios from "axios";
import { styled } from "@mui/material/styles";
import { BsFillBalloonFill } from "react-icons/bs";
import { FaArrowDown, FaArrowUp } from "react-icons/fa"; // Use arrow icons

const BalloonContainer = styled("div")({
  position: "relative",
  backgroundColor: "#fff",
  overflow: "hidden",
  padding: "30px 20px",
  borderRadius: "20px",
  boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)",
  background: "linear-gradient(45deg, #E6E6FA, #C8A2D6)",
});

const EmojiMove = styled("span")({
  display: "inline-block",
  fontSize: "1rem",
});

const BalloonSticker = styled("div")({
  position: "fixed",
  bottom: "10px",
  right: "10px",
  fontSize: "30px",
  color: "#800000",
  animation: "rise 1s infinite alternate",
});

const KidPartyFont = styled(Typography)({
  fontFamily: "'Luckiest Guy', cursive",
  fontWeight: "bold",
  color: "#800000",
});

const UpcomingFont = styled(Typography)({
  fontFamily: "'Dancing Script', cursive",
  fontWeight: "bold",
  fontSize: "1rem",
  color: "#fff",
});

// Define the keyframes for the upward movement of the balloon and explosion
const GlobalStyles = styled("style")(
  () => `
    @keyframes rise {
        0% {
            transform: translateY(0);
        }
        100% {
            transform: translateY(-10px);
        }
    }
    
    @keyframes explosion {
        0% {
            transform: scale(1);
            opacity: 1;
        }
        50% {
            transform: scale(1.5);
            opacity: 0.5;
        }
        100% {
            transform: scale(1);
            opacity: 1;
        }
    }

    @keyframes firecrack {
        0% { transform: scale(1); }
        50% { transform: scale(1.3); opacity: 0.5; }
        100% { transform: scale(1); opacity: 0; }
    }
`
);

export default function Birthdays() {
  const [birthdays, setBirthdays] = useState([]);
  const [todaysBirthdays, setTodaysBirthdays] = useState([]);
  const [firecrackers, setFirecrackers] = useState([]);
  const institutecode = localStorage.getItem("institutecode") || "";
  const firecrackerIntervalRef = useRef(null); // Ref to hold the interval ID

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response = await axios.get(
          `https://pjsofttech.in:10443/upcomingbirthdays?institutecode=${institutecode}`
        );
        const employees = response.data;

        const today = new Date();
        const currentDate = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate()
        );
        const oneWeekAhead = new Date();
        oneWeekAhead.setDate(today.getDate() + 6);

        const upcomingBirthdays = employees
          .map((employee) => {
            const dob = new Date(employee.dob);
            const nextBirthday = new Date(
              today.getFullYear(),
              dob.getMonth(),
              dob.getDate()
            );
            if (nextBirthday < currentDate) {
              nextBirthday.setFullYear(today.getFullYear() + 1);
            }
            return {
              fullName: employee.fullName,
              department: employee.department,
              dob: employee.dob,
              age: today.getFullYear() - dob.getFullYear(),
              nextBirthday,
            };
          })
          .filter(
            (employee) =>
              employee.nextBirthday >= currentDate &&
              employee.nextBirthday <= oneWeekAhead
          )
          .sort((a, b) => a.nextBirthday - b.nextBirthday);

        const todays = upcomingBirthdays.filter(
          (employee) =>
            employee.nextBirthday.getTime() === currentDate.getTime()
        );
        const upcoming = upcomingBirthdays.filter(
          (employee) =>
            employee.nextBirthday.getTime() !== currentDate.getTime()
        );

        setTodaysBirthdays(todays);
        setBirthdays(upcoming);
      } catch (error) {
        console.error("Error fetching employee data", error);
      }
    };

    fetchEmployeeData();
  }, [institutecode]);

  const startFireworks = (box) => {
    // Clear any existing interval
    if (firecrackerIntervalRef.current) {
      clearInterval(firecrackerIntervalRef.current);
    }

    // Generate firecracker positions continuously
    firecrackerIntervalRef.current = setInterval(() => {
      const boxRect = box.getBoundingClientRect();
      const left = Math.random() * (boxRect.width - 30) + 10; // Adjusted for size
      const top = Math.random() * (boxRect.height - 30) + 10; // Adjusted for size

      // Add a new firecracker
      setFirecrackers((prev) => [
        ...prev,
        { left: `${left}px`, top: `${top}px` },
      ]);
    }, 200); // Adjust this interval to control how fast firecrackers are added
  };

  const stopFireworks = () => {
    clearInterval(firecrackerIntervalRef.current); // Clear interval when mouse leaves
    firecrackerIntervalRef.current = null; // Reset ref
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <Paper
      sx={{
        padding: 1,
        borderRadius: "20px",
        background: "linear-gradient(135deg, #FF5F6D, #FF9A8B, #D387FF)",
        color: "#000",
        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
        position: "relative",
        maxHeight: "250px",
        overflow: "hidden",
        "&:hover": {
          overflowY: "auto",
        },
      }}
    >
      <GlobalStyles />
      {/* Upcoming Birthdays Section */}
      <Typography
        gutterBottom
        textAlign={"center"}
        style={{ color: "#fff", fontWeight: "bold" }}
      >
        Today's Birthdays
      </Typography>

      {/* Map through today's birthdays and create separate boxes */}
      {todaysBirthdays.length > 0 ? (
        todaysBirthdays.map((employee, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderRadius: "10px",
              boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.2s ease-in-out",
              marginBottom: 2,
              position: "relative", // This is important for positioning firecrackers
            }}
            onMouseEnter={(e) => startFireworks(e.currentTarget)} // Start the fireworks on hover
            onMouseLeave={stopFireworks} // Stop the fireworks on mouse leave
          >
            <EmojiMove
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <KidPartyFont
                sx={{
                  fontSize: "15px",
                  textAlign: "center",
                  marginLeft: "5px",
                }}
              >
                {employee.fullName} ({employee.department})<br />
                Happy Birthday !!
              </KidPartyFont>
            </EmojiMove>

            {/* Birthday Cake */}
            <Box
              sx={{
                width: "70px",
                height: "70px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "30px",
                transition: "transform 0.2s ease",
                cursor: "pointer",
              }}
            >
              🎂
            </Box>

            {/* Render Firecrackers */}
            {firecrackers.map((position, index) => (
              <Box
                key={index}
                sx={{
                  position: "absolute",
                  left: position.left,
                  top: position.top,
                  width: "10px",
                  height: "10px",
                  backgroundColor: "red",
                  borderRadius: "50%",
                  opacity: 0.8,
                  animation: "firecrack 1s ease forwards", // Apply animation
                }}
              />
            ))}
          </Box>
        ))
      ) : (
        <Typography variant="body1" color="text.secondary">
          No birthdays today
        </Typography>
      )}

      {/* Upcoming Birthdays Section */}
      <Typography
        variant="h6"
        gutterBottom
        mt={1}
        textAlign={"center"}
        style={{ color: "#fff", fontWeight: "bold" }}
      >
        🎉 Upcoming Birthdays 🎉
      </Typography>
      <Box sx={{ color: "#fff" }}>
        {birthdays.length > 0 ? (
          birthdays.map((employee, index) => {
            const today = new Date();
            const nextBirthday = employee.nextBirthday;
            const timeDiff = nextBirthday.getTime() - today.getTime();
            const daysRemaining = Math.ceil(timeDiff / (1000 * 3600 * 24));

            let remainingText;
            if (daysRemaining === 0) {
              remainingText = "Today";
            } else if (daysRemaining === 1) {
              remainingText = "Tomorrow";
            } else {
              remainingText = `${daysRemaining} days left !!`;
            }

            return (
              <Box key={index} sx={{ marginBottom: 2, color: "#fff" }}>
                <UpcomingFont variant="body1" sx={{ fontWeight: "bold" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span>
                        {employee.fullName} ({employee.department})
                      </span>
                      <Typography variant="body2">
                        Age: {employee.age}
                      </Typography>
                    </div>
                    <div style={{ textAlign: "right" }}>
                      <span>{remainingText}</span>
                      <Typography variant="body2">
                        {formatDate(employee.nextBirthday)}
                      </Typography>
                    </div>
                  </div>
                </UpcomingFont>
              </Box>
            );
          })
        ) : (
          <Typography>No upcoming birthdays</Typography>
        )}
      </Box>

      <BalloonSticker>
        <BsFillBalloonFill />
        <BsFillBalloonFill />
      </BalloonSticker>
    </Paper>
  );
}
