import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Slide,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from "@mui/material";
import Axios from "axios";
import MuiAlert from "@mui/material/Alert";
import { Delete, Edit, TabUnselectedRounded } from "@mui/icons-material";
import axios from "axios";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function HourlySalary() {
  const getInstituteCode = () => localStorage.getItem("institutecode");
  const [formData, setFormData] = useState({
    teacherName: "",
    hourlyRate: "",
    hoursWorked: "",
    subject: "",
    exam: "",
    date: "",
    teacherId: "",
  });

  const [salaries, setSalaries] = useState([]);
  const [filteredSalaries, setFilteredSalaries] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [hourlyRates, setHourlyRates] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [exams, setExams] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTeacher, setSelectedTeacher] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedSalary, setSelectedSalary] = useState(null);
  const [open, setOpen] = useState(false);

  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");

  useEffect(() => {
    fetchAllSalaries();
    fetchTeachers();
    fetchHourlyRates();
    fetchSubjects();
    fetchExams();
  }, []);

  useEffect(() => {
    filterSalaries();
  }, [
    selectedDate,
    selectedTeacher,
    selectedSubject,
    selectedMonth,
    selectedYear,
    salaries,
  ]);

  const fetchAllSalaries = async () => {
    try {
      const response = await Axios.get(
        ` https://pjsofttech.in:19443/getAllSalaryHourlies?institutecode=${getInstituteCode()}`
      );
      setSalaries(response.data);
    } catch (error) {
      console.error("Error fetching salaries:", error);
    }
  };

  const fetchTeachers = async () => {
    try {
      const response = await Axios.get(
        ` https://pjsofttech.in:19443/getAllTeachers?institutecode=${getInstituteCode()}`
      );
      setTeachers(response.data);
    } catch (error) {
      console.error("Error fetching teachers:", error);
    }
  };

  const fetchHourlyRates = async () => {
    try {
      const response = await Axios.get(
        ` https://pjsofttech.in:19443/getAllHourlyRates?instituteCode=${getInstituteCode()}`
      );
      setHourlyRates(response.data);
    } catch (error) {
      console.error("Error fetching hourly rates:", error);
    }
  };

  const fetchSubjects = async () => {
    try {
      const response = await Axios.get(
        ` https://pjsofttech.in:19443/getAllSubjects?institutecode=${getInstituteCode()}`
      );
      setSubjects(response.data);
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };

  const fetchExams = async () => {
    try {
      const response = await Axios.get(
        ` https://pjsofttech.in:19443/AllExams?institutecode=${getInstituteCode()}`
      );
      setExams(response.data);
    } catch (error) {
      console.error("Error fetching exams:", error);
    }
  };

  const filterSalaries = () => {
    let filtered = salaries;

    if (selectedDate) {
      filtered = filtered.filter(
        (salary) =>
          new Date(salary.date).toLocaleDateString() ===
          new Date(selectedDate).toLocaleDateString()
      );
    }

    if (selectedTeacher) {
      filtered = filtered.filter(
        (salary) => salary.teacherId === selectedTeacher
      );
    }

    if (selectedSubject) {
      filtered = filtered.filter(
        (salary) => salary.subject === selectedSubject
      );
    }

    if (selectedMonth) {
      const monthIndex = new Date(selectedMonth).getMonth();
      filtered = filtered.filter(
        (salary) => new Date(salary.date).getMonth() === monthIndex
      );
    }

    if (selectedYear) {
      filtered = filtered.filter(
        (salary) =>
          new Date(salary.date).getFullYear() === parseInt(selectedYear)
      );
    }

    setFilteredSalaries(filtered);
  };

  useEffect(() => {
    fetchAllSalaries();
  }, []);

  useEffect(() => {
    filterSalaries();
  }, [
    salaries,
    selectedDate,
    selectedTeacher,
    selectedSubject,
    selectedMonth,
    selectedYear,
  ]);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await Axios.post(
        ` https://pjsofttech.in:19443/createSalaryHourly?institutecode=${getInstituteCode()}`,
        formData
      );
      fetchAllSalaries(); // Refresh the list after adding a new salary
      setSnackbarMessage(
        `Salary added for ${formData.teacherName} (ID: ${formData.teacherId})`
      );
      setOpenSnackbar(true);
      setOpenDialog(false); // Close the dialog
      resetForm(); // Reset form fields
    } catch (error) {
      console.error("Error creating hourly salary:", error);
    }
  };

  const handleEditClick = (salary) => {
    setSelectedSalary(salary);
    setOpen(true); // Change this to setOpen(true) to open the dialog.
  };

  const handleDialogClose = () => {
    setOpen(false);
    setSelectedSalary(null);
  };

  const handleUpdateSalary = async (event) => {
    event.preventDefault(); // Prevent default form submission
    try {
      await axios.put(
        ` https://pjsofttech.in:19443/updateSalaryHourly/${selectedSalary.id}`,
        selectedSalary
      );
      fetchAllSalaries();
      handleDialogClose();
    } catch (err) {
      console.error("Error updating salary:", err);
    }
  };

  const handleDeleteClick = (id) => {
    if (window.confirm("Are you sure you want to delete this salary?")) {
      axios
        .delete(` https://pjsofttech.in:19443/deleteSalaryHourly/${id}`)
        .then(() => {
          fetchAllSalaries();
        })
        .catch((err) => console.error(err));
    }
  };

  const resetForm = () => {
    setFormData({
      teacherName: "",
      hourlyRate: "",
      hoursWorked: "",
      subject: "",
      exam: "",
      date: "",
      teacherId: "",
    });
    setSelectedDate("");
    setSelectedTeacher("");
    setSelectedSubject("");
    setSelectedMonth("");
    setSelectedYear("");
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
    resetForm();
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  // Populate years for the year dropdown
  const years = Array.from(
    new Array(10),
    (val, index) => new Date().getFullYear() - index
  );
  const months = [
    { label: "January", value: "2023-01-01" },
    { label: "February", value: "2023-02-01" },
    { label: "March", value: "2023-03-01" },
    { label: "April", value: "2023-04-01" },
    { label: "May", value: "2023-05-01" },
    { label: "June", value: "2023-06-01" },
    { label: "July", value: "2023-07-01" },
    { label: "August", value: "2023-08-01" },
    { label: "September", value: "2023-09-01" },
    { label: "October", value: "2023-10-01" },
    { label: "November", value: "2023-11-01" },
    { label: "December", value: "2023-12-01" },
  ];

  return (
    <div style={{ padding: "10px" }}>
      <>

        <Grid
          container
          spacing={3}
          style={{ marginBottom: "20px", alignItems:'center' }}
          className="textField-root"
        >
          <Grid item xs={12} sm={2}>
            <FormControl fullWidth>
              <TextField
                select
                label="Month"
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
                
              >
                <MenuItem value="">
                  <em>All Months</em>
                </MenuItem>
                {months.map((month) => (
                  <MenuItem key={month.value} value={month.value}>
                    {month.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={2}>
            <FormControl fullWidth>
              <TextField
                select
                label="Year"
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
                
              >
                <MenuItem value="">
                  <em>All Years</em>
                </MenuItem>
                {years.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={2}>
            <FormControl fullWidth>
              <TextField
                select
                label="Select Teacher"
                labelId="teacher-filter-label"
                value={selectedTeacher}
                onChange={(e) => setSelectedTeacher(e.target.value)}
                
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {teachers.map((teacher) => (
                  <MenuItem key={teacher.id} value={teacher.id}>
                    {teacher.teacherName}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={2}>
            <TextField
              label="Search by Date"
              type="date"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="h6" gutterBottom>
              Total Salaries - {filteredSalaries.length}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
            >
              Add New
            </Button>
          </Grid>
        </Grid>

        <TableContainer>
          <Table size="small">
            <TableHead
              style={{
                backgroundColor: "#f2f2f2",
                justifyContent: "center",
              }}
            >
              <TableRow>
                <TableCell style={{ fontWeight: "bold", align:"center" }}>ID</TableCell>
                <TableCell style={{ fontWeight: "bold", align:"center" }}>Date</TableCell>
                <TableCell style={{ fontWeight: "bold", align:"center" }}>
                  Teacher Name
                </TableCell>
                {/* <TableCell style={{ fontWeight: "bold" }}>
                  Hourly Rate
                </TableCell> */}
                <TableCell style={{ fontWeight: "bold", align:"center" }}>
                  Hours Worked
                </TableCell>
                <TableCell style={{ fontWeight: "bold", align:"center" }}>Subject</TableCell>
                <TableCell style={{ fontWeight: "bold", align:"center" }}>Exam</TableCell>
                <TableCell style={{ fontWeight: "bold", align:"center" }}>Teacher ID</TableCell>
                <TableCell style={{ fontWeight: "bold", align:"center" }}>
                  Total Salary
                </TableCell>
                <TableCell style={{ fontWeight: "bold", align:"center" }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredSalaries.length > 0 ? (
                filteredSalaries
                  .slice()
                  .sort((a, b) => new Date(b.date) - new Date(a.date))
                  .map((salary, index) => (
                    <TableRow key={index}>
                      <TableCell >{salary.id}</TableCell>
                      <TableCell >
                        {new Date(salary.date).toLocaleDateString()}
                      </TableCell>
                      <TableCell>{salary.teacherName}</TableCell>
                      {/* <TableCell>₹ {salary.hourlyRate}</TableCell> */}
                      <TableCell>{salary.hoursWorked}</TableCell>
                      <TableCell>{salary.subject || "NA"}</TableCell>
                      <TableCell>{salary.exam || "NA"}</TableCell>
                      <TableCell>{salary.teacherId}</TableCell>
                      <TableCell>₹ {salary.totalSalary}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => handleEditClick(salary)}
                          color="success"
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          onClick={() => handleDeleteClick(salary.id)}
                          color="error"
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                // Display a message if no entries match the filter
                <TableRow>
                  <TableCell colSpan={10} align="center">
                    No data available for the selected filters.
                  </TableCell>
                </TableRow>
              )}

              {/* Totals row */}
              {filteredSalaries.length > 0 && (
                <TableRow>
                  <TableCell
                    colSpan={2}
                    align="right"
                    style={{ fontWeight: "bold" }}
                  >
                    Totals
                  </TableCell>

                  <TableCell style={{ fontWeight: "bold" }}>
                    {/* {filteredSalaries.reduce(
                      (sum, salary) => sum + parseFloat(salary.hourlyRate || 0),
                      0
                    )} */}
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    {/* Calculate and display the sum of Hours Worked */}
                    {filteredSalaries.reduce(
                      (sum, salary) =>
                        sum + parseFloat(salary.hoursWorked || 0),
                      0
                    )}
                  </TableCell>
                  <TableCell colSpan={3} />
                  <TableCell style={{ fontWeight: "bold" }}>
                    {/* Calculate and display the sum of Total Salary */}₹{" "}
                    {filteredSalaries.reduce(
                      (sum, salary) =>
                        sum + parseFloat(salary.totalSalary || 0),
                      0
                    )}
                  </TableCell>
                  <TableCell />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </>

      {/* Add Salary Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        padding="10%"
        TransitionComponent={Transition}
        style={{
          borderRadius: "13px",
        }}
      >
        <DialogTitle>Add Hourly Salary</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit} style={{ marginTop: "20px" }} className="textField-root">
            <Grid container spacing={3}>
              {/* Teacher Select Dropdown */}
              <Grid item xs={12} sm={4} >
                <FormControl fullWidth>
                  <TextField
                    select
                    label="Select Teacher"
                    labelId="teacher-label"
                    name="teacherId"
                    value={formData.teacherId}
                    onChange={handleInputChange}
                    required
                  >
                    {teachers.map((teacher) => (
                      <MenuItem key={teacher.id} value={teacher.id}>
                        {teacher.teacherName}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>

              {/* Subject Select Dropdown */}
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <TextField
                    select
                    label="Select Subject"
                    labelId="subject-label"
                    name="subject"
                    value={formData.subject}
                    onChange={handleInputChange}
                  >
                    {subjects.map((subject) => (
                      <MenuItem key={subject.id} value={subject.subject}>
                        {subject.subject}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>

              {/* Exam Input */}
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <TextField
                    select
                    label="Select Exam"
                    labelId="exam-label"
                    name="exam"
                    value={formData.exam}
                    onChange={handleInputChange}
                  >
                    {exams.map((exam) => (
                      <MenuItem key={exam.id} value={exam.exam}>
                        {exam.exam}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>

              {/* Hourly Rate Select Dropdown */}
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <TextField
                    select
                    label="Select Hourly Rate"
                    labelId="hourly-rate-label"
                    name="hourlyRate"
                    value={formData.hourlyRate}
                    onChange={handleInputChange}
                    required
                  >
                    {hourlyRates.map((rate) => (
                      <MenuItem key={rate.id} value={rate.hourlyRate}>
                        {rate.hourlyRate}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>

              {/* Hours Worked Input */}
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Hours Worked"
                  name="hoursWorked"
                  type="number"
                  value={formData.hoursWorked}
                  onChange={handleInputChange}
                  required
                  fullWidth
                />
              </Grid>

              {/* Date Picker */}
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Date"
                  name="date"
                  type="date"
                  value={formData.date}
                  onChange={handleInputChange}
                  required
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>

            {/* Submit Button */}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginTop: "30px", display: "block", width: "100%" }}
            >
              Create Salary
            </Button>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Dialog Box */}
      <Dialog open={open} onClose={handleDialogClose}>
        <DialogTitle>Edit Salary</DialogTitle>
        <DialogContent>
          {selectedSalary && (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <TextField
                  autoFocus
                  margin="dense"
                  name="teacherId"
                  label="Teacher Id"
                  fullWidth
                  value={selectedSalary.teacherId}
                  onChange={(e) =>
                    setSelectedSalary({
                      ...selectedSalary,
                      teacherId: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                <TextField
                 select
                  margin="dense"
                 label="Select Subject"
                 labelId="subject-label"
                 name="subject"
                  value={selectedSalary.subject}
                  onChange={(e) =>
                    setSelectedSalary({
                      ...selectedSalary,
                      subject: e.target.value,
                    })
                  }
                >
                  {subjects.map((subject) => (
                      <MenuItem key={subject.id} value={subject.subject}>
                        {subject.subject}
                      </MenuItem>
                    ))}
                </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <TextField
                    select
                     margin="dense"
                    label="Select Exam"
                    name="exam"
                    value={selectedSalary.exam}
                    onChange={(e) =>
                      setSelectedSalary({
                        ...selectedSalary,
                        exam: e.target.value,
                      })
                    }
                  >
                    {exams.map((exam) => (
                      <MenuItem key={exam.id} value={exam.exam}>
                        {exam.exam}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  autoFocus
                  margin="dense"
                  name="hourlyRate"
                  label="Hourly Rate"
                  type="number"
                  fullWidth
                  value={selectedSalary.hourlyRate}
                  onChange={(e) =>
                    setSelectedSalary({
                      ...selectedSalary,
                      hourlyRate: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  autoFocus
                  margin="dense"
                  name="hoursWorked"
                  label="Worked Hours"
                  type="number"
                  fullWidth
                  value={selectedSalary.hoursWorked}
                  onChange={(e) =>
                    setSelectedSalary({
                      ...selectedSalary,
                      hoursWorked: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  autoFocus
                  margin="dense"
                  name="date"
                  label="Date"
                  type="date"
                  fullWidth
                  value={
                    selectedSalary.date
                      ? new Date(selectedSalary.date)
                          .toISOString()
                          .split("T")[0]
                      : ""
                  }
                  onChange={(e) =>
                    setSelectedSalary({
                      ...selectedSalary,
                      date: e.target.value,
                    })
                  }
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdateSalary} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
