import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Grid, MenuItem, IconButton } from "@mui/material";
import axios from "axios";
import {
    gender,
    bloodGroup,
    maritalStatus,
    incomeRanges,
    title,
    nationality,
    birthState,
    birthDistrict,
    castCategory,
    religionOptions,
    districtOptions,
    noOfYearsPlayed,
    weightOptions,
    heightOptions,
    disabilityTypes,
    sportsInjuries,
    levelOfParticipation,
    stateOptions,
    sportsName,
    specialPercentage,
    boardOptions,
  } from "./DropdownData.js";
  import RemoveIcon from "@mui/icons-material/Remove";
  import AddIcon from "@mui/icons-material/Add";
  import Swal from "sweetalert2"; // Import SweetAlert2
  import UpdateDocument from "./UpdateDocument.js";

const UpdateStudent = ({ student, onClose, onUpdateComplete }) => {
  const [updatedStudent, setUpdatedStudent] = useState(student);
  const [openDocumentsDialog, setOpenDocumentsDialog] = useState(false); // State to control document dialog visibility
  const handleDocumentsDialogOpen = () => setOpenDocumentsDialog(true);
  const handleDocumentsDialogClose = () => setOpenDocumentsDialog(false);
  const [standard, setStandards] = useState([]);
  const [medium, setMedium] = useState([]);
  const institutecode = () => localStorage.getItem("institutecode");
  const [exams, setExam] = useState([
    {
      board: "",
      collegeName: "",
      enterExamName: "",
      rollNo: "",
      obtainedMarks: "",
      totalMarks: "",
      percentage: "",
      year: "",
      reasonOfLeavingSchool: "",
      grade: "",
      cgpa: "",
    },
  ]);

  const removeEducationDetail = (index) => {
    const newEducationDetails = exams.filter((_, i) => i !== index);
    setExam(newEducationDetails);
  };

  const handleEducationDetailChange = (index, field, value) => {
    const newEducationDetails = [...exams];
    newEducationDetails[index][field] = value;
    setExam(newEducationDetails);
  };

  useEffect(() => {
    axios
      .get(`https://pjsofttech.in:9443/all?institutecode=${institutecode()}`)
      .then((response) => {
        setStandards(response.data); // populating standardOptions with response data
      })
      .catch((error) => {
        console.error("There was an error fetching the standards!", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`https://pjsofttech.in:9443/getall?institutecode=${institutecode()}`)
      .then((response) => {
        setMedium(response.data); // populating medium with response data
      })
      .catch((error) => {
        console.error("There was an error fetching the Medium!", error);
      });
  }, []);
  
  

  const addEducationDetail = () => {
    setExam([
      ...exams,
      {
        board: "",
        collegeName: "",
        enterExamName: "",
        rollNo: "",
        obtainedMarks: "",
        totalMarks: "",
        percentage: "",
        year: "",
        reasonOfLeavingSchool: "",
        grade: "",
        cgpa: "",
      },
    ]);
  };

  const handleChange = (event, key) => {
    const {value } = event.target;
    setUpdatedStudent((prev) => ({ ...prev, [key]: value,}));
  };

  const handleSubmit = async () => {
    try {
      await axios.put(`https://pjsofttech.in:9443/updateStudent/${student.stdid}`, updatedStudent);
      onUpdateComplete();
      onClose();
        // Optionally, show a success message
        Swal.fire({
            icon: 'success',
            title: 'Updated!',
            text: 'Student data has been Updated.',
          });
    } catch (error) {
      console.error("Error updating student:", error);
    }
  };

  return (
    <Dialog open={Boolean(student)}  maxWidth="md" fullWidth sx={{ "& .MuiDialog-paper": { width: "90%", height: "90%" } }} onClose={onClose}>
      <DialogTitle>Update Student</DialogTitle>
      <DialogContent>
      <Grid container spacing={2} marginTop={1}>
      <Grid item xs={12} sm={4}>
                  <TextField
                    label="Standard"
                    value={updatedStudent?.standard || ""}
                    onChange={(e) => handleChange(e, "standard")}
                    fullWidth
                    style={{ marginBottom: "16px" }}
                    select
                  >
                    <MenuItem value="">Please select Standard</MenuItem>
                    {standard.map((standard) => (
                      <MenuItem
                        key={standard.standard}
                        value={standard.standard}
                      >
                        {standard.standard}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Medium"
                    value={updatedStudent?.mediumname || ""}
                    onChange={(e) => handleChange(e, "mediumname")}
                    fullWidth
                    style={{ marginBottom: "16px" }}
                    select
                  >
                    <MenuItem value="">Please select Medium</MenuItem>
                    {medium.map((option) => (
                      <MenuItem
                        key={option.mediumname}
                        value={option.mediumname}
                      >
                        {option.mediumname}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Title"
                    value={updatedStudent?.title || ""}
                    onChange={(e) => handleChange(e, "title")}
                    fullWidth
                    style={{ marginBottom: "16px" }}
                    select
                  >
                    <MenuItem value="">Please select title</MenuItem>
                    {title.map((title) => (
                      <MenuItem key={title} value={title}>
                        {title}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
        
          <Grid item xs={12} sm={4}>
            <TextField
              label="First Name"
              name="firstName"
              value={updatedStudent.firstName}
              onChange={(e) => handleChange(e, "firstName")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Last Name"
              name="surname"
              value={updatedStudent?.surname || ""}
              onChange={(e) => handleChange(e, "surname")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
                  <TextField
                    label="Full Name"
                    value={updatedStudent?.full_name || ""}
                    onChange={(e) => handleChange(e, "full_name")}
                    fullWidth
                    style={{ marginBottom: "16px" }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Gender"
                    value={updatedStudent?.gender || ""}
                    onChange={(e) => handleChange(e, "gender")}
                    fullWidth
                    style={{ marginBottom: "16px" }}
                    select
                    name="gender"
                  >
                    <MenuItem value="">Please select gender</MenuItem>
                    {gender.map((gender) => (
                      <MenuItem key={gender} value={gender}>
                        {gender}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Blood Group"
                    value={updatedStudent?.bloodGroup || ""}
                    onChange={(e) => handleChange(e, "bloodGroup")}
                    fullWidth
                    style={{ marginBottom: "16px" }}
                    select
                    name="bloodGroup"
                  >
                    <MenuItem value="">Please select blood group</MenuItem>
                    {bloodGroup.map((bloodGroup) => (
                      <MenuItem key={bloodGroup} value={bloodGroup}>
                        {bloodGroup}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Mother Tongue"
                    value={updatedStudent?.motherTongue || ""}
                    onChange={(e) => handleChange(e, "motherTongue")}
                    fullWidth
                    style={{ marginBottom: "16px" }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Marital Status"
                    value={updatedStudent?.maritalStatus || ""}
                    onChange={(e) => handleChange(e, "maritalStatus")}
                    fullWidth
                    style={{ marginBottom: "16px" }}
                    select
                    name="maritalStatus"
                  >
                    <MenuItem value="">Please select Marital Status</MenuItem>
                    {maritalStatus.map((maritalStatus) => (
                      <MenuItem key={maritalStatus} value={maritalStatus}>
                        {maritalStatus}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Email"
                    name="emailAddress"
                    value={updatedStudent?.emailAddress}
                    onChange={(e) => handleChange(e, "emailAddress")}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <h4>Religion/Caste</h4>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Religion"
                        name="religion"
                        value={updatedStudent?.religion || ""}
                        onChange={(e) => handleChange(e, "religion")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                        select
                      >
                        <MenuItem value="">Please select religion</MenuItem>
                        {religionOptions.map((religionOptions) => (
                          <MenuItem
                            key={religionOptions}
                            value={religionOptions}
                          >
                            {religionOptions}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Cast Category"
                        value={updatedStudent?.castCategory || ""}
                        onChange={(e) => handleChange(e, "castCategory")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                        select
                        name="castCategory"
                      >
                        <MenuItem value="">
                          Please select caste category
                        </MenuItem>
                        {castCategory.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="minorityType"
                        value={updatedStudent?.minorityType || ""}
                        onChange={(e) => handleChange(e, "minorityType")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                        select
                        name="minorityType"
                      >
                        <MenuItem value="">Select Minority Type</MenuItem>
                        <MenuItem value="Muslims">Muslims</MenuItem>
                        <MenuItem value="Sikhs">Sikhs</MenuItem>
                        <MenuItem value="Christians">Christians</MenuItem>
                        <MenuItem value="Buddhists">Buddhists</MenuItem>
                        <MenuItem value="Jain">Jain</MenuItem>
                        <MenuItem value="Zorastrians (Parsis)">
                          Zorastrians (Parsis)
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="casteValidationNumber"
                        value={updatedStudent?.casteValidationNumber || ""}
                        onChange={(e) =>
                          handleChange(e, "casteValidationNumber")
                        }
                        fullWidth
                        style={{ marginBottom: "16px" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="casteCertificateNumber"
                        value={updatedStudent?.casteCertificateNumber || ""}
                        onChange={(e) =>
                          handleChange(e, "casteCertificateNumber")
                        }
                        fullWidth
                        style={{ marginBottom: "16px" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Caste"
                        value={updatedStudent?.caste || ""}
                        onChange={(e) => handleChange(e, "caste")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Sub-Caste"
                        value={updatedStudent?.subCaste || ""}
                        onChange={(e) => handleChange(e, "subCaste")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <h4>Birth Info</h4>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Date of Birth"
                        value={updatedStudent?.dateOfBirth || ""}
                        onChange={(e) => handleChange(e, "dateOfBirth")}
                        type="date"
                        fullWidth
                        style={{ marginBottom: "16px" }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Age"
                        value={updatedStudent?.age || ""}
                        onChange={(e) => handleChange(e, "age")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Birth Place"
                        value={updatedStudent?.birthPlace || ""}
                        onChange={(e) => handleChange(e, "birthPlace")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                      />{" "}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Birth Taluka"
                        value={updatedStudent?.birthTaluka || ""}
                        onChange={(e) => handleChange(e, "birthTaluka")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                        name="birthTaluka"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Birth District"
                        value={updatedStudent?.birthDistrict || ""}
                        onChange={(e) => handleChange(e, "birthDistrict")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                        select
                        name="birthDistrict"
                      >
                        <MenuItem value="">Please select District</MenuItem>
                        {birthDistrict.map((birthDistrict) => (
                          <MenuItem key={birthDistrict} value={birthDistrict}>
                            {birthDistrict}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>{" "}
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Birth State"
                        value={updatedStudent?.birthState || ""}
                        onChange={(e) => handleChange(e, "birthState")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                        select
                        name="birthState"
                      >
                        <MenuItem value="">Please select State</MenuItem>
                        {birthState.map((birthState) => (
                          <MenuItem key={birthState} value={birthState}>
                            {birthState}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Birth Country"
                        value={updatedStudent?.birthCountry || ""}
                        onChange={(e) => handleChange(e, "birthCountry")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                        select
                        name="birthCountry"
                      >
                        <MenuItem value="">Please select Country</MenuItem>
                        {nationality.map((nationality) => (
                          <MenuItem key={nationality} value={nationality}>
                            {nationality}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>{" "}
                </Grid>
                <Grid item xs={12}>
                  <h4>Family/Contact Info</h4>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Father's Name"
                        value={updatedStudent?.fathersName || ""}
                        onChange={(e) => handleChange(e, "fathersName")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Mother's Name"
                        value={updatedStudent?.motherName || ""}
                        onChange={(e) => handleChange(e, "motherName")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Father's Profession"
                        value={updatedStudent?.fatherProfession || ""}
                        onChange={(e) =>
                          handleChange(e, "fatherProfession")
                        }
                        fullWidth
                        style={{ marginBottom: "16px" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Father's Contact"
                        value={updatedStudent?.fathersContact || ""}
                        onChange={(e) => handleChange(e, "fathersContact")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Phone"
                        value={updatedStudent?.phoneNumber || ""}
                        onChange={(e) => handleChange(e, "phoneNumber")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="WhatsApp Number"
                        value={updatedStudent?.whatsappNumber || ""}
                        onChange={(e) => handleChange(e, "whatsappNumber")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="PAN Number"
                        value={updatedStudent?.panNumber || ""}
                        onChange={(e) => handleChange(e, "panNumber")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Aadhar Number"
                        value={updatedStudent?.aadharNumber || ""}
                        onChange={(e) => handleChange(e, "aadharNumber")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="UDISE Number"
                        value={updatedStudent?.udiseNo || ""}
                        onChange={(e) => handleChange(e, "udiseNo")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Saral Number"
                        value={updatedStudent?.saralNo || ""}
                        onChange={(e) => handleChange(e, "saralNo")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Income Ranges"
                        value={updatedStudent?.incomeRanges || ""}
                        onChange={(e) => handleChange(e, "incomeRanges")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                        select
                      >
                        <MenuItem value="">Please Select Income Range</MenuItem>
                        {incomeRanges.map((income) => (
                          <MenuItem key={income} value={income}>
                            {income}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Nationality"
                        value={updatedStudent?.nationality || ""}
                        onChange={(e) => handleChange(e, "nationality")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                        select
                        name="nationality"
                      >
                        <MenuItem value="">Please select nationality</MenuItem>
                        {nationality.map((nationality) => (
                          <MenuItem key={nationality} value={nationality}>
                            {nationality}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <h4>Educational Details</h4>
                  <Grid container spacing={2}>
                    {exams.map((detail, index) => (
                      <React.Fragment key={index}>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            label="Board"
                            name="board"
                            value={detail.board}
                            onChange={(e) =>
                              handleEducationDetailChange(
                                index,
                                "board",
                                e.target.value
                              )
                            }
                            fullWidth
                          >
                            <MenuItem value="">Please select Board</MenuItem>
                            {boardOptions.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            label="Enter Last School Name"
                            value={detail.collegeName}
                            onChange={(e) =>
                              handleEducationDetailChange(
                                index,
                                "collegeName",
                                e.target.value
                              )
                            }
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            label="Last Qualifying Exam"
                            value={detail.enterExamName}
                            onChange={(e) =>
                              handleEducationDetailChange(
                                index,
                                "enterExamName",
                                e.target.value
                              )
                            }
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            label="Roll Number"
                            value={detail.rollNo}
                            onChange={(e) =>
                              handleEducationDetailChange(
                                index,
                                "rollNo",
                                e.target.value
                              )
                            }
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            label="Obtained Marks"
                            value={detail.obtainedMarks}
                            onChange={(e) =>
                              handleEducationDetailChange(
                                index,
                                "obtainedMarks",
                                e.target.value
                              )
                            }
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            label="Total Marks"
                            value={detail.totalMarks}
                            onChange={(e) =>
                              handleEducationDetailChange(
                                index,
                                "totalMarks",
                                e.target.value
                              )
                            }
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            label="Percentage"
                            value={detail.percentage}
                            onChange={(e) =>
                              handleEducationDetailChange(
                                index,
                                "percentage",
                                e.target.value
                              )
                            }
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            label="CGPA"
                            value={detail.cgpa}
                            onChange={(e) =>
                              handleEducationDetailChange(
                                index,
                                "cgpa",
                                e.target.value
                                // (type = "Number")
                              )
                            }
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            label="Grade"
                            value={detail.grade}
                            onChange={(e) =>
                              handleEducationDetailChange(
                                index,
                                "grade",
                                e.target.value
                              )
                            }
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            label="Year"
                            value={detail.year}
                            onChange={(e) =>
                              handleEducationDetailChange(
                                index,
                                "year",
                                e.target.value
                              )
                            }
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            label="Reason For Leaving School"
                            value={detail.reasonOfLeavingSchool}
                            onChange={(e) =>
                              handleEducationDetailChange(
                                index,
                                "reasonOfLeavingSchool",
                                e.target.value
                              )
                            }
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <IconButton
                            onClick={() => removeEducationDetail(index)}
                            color="secondary"
                          >
                            <RemoveIcon />
                          </IconButton>
                        </Grid>
                      </React.Fragment>
                    ))}
                    <Grid item xs={12}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={addEducationDetail}
                        startIcon={<AddIcon />}
                      >
                        Add Education Detail
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <h4>Sports</h4>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Sports Name"
                        value={updatedStudent?.sportsName || ""}
                        onChange={(e) => handleChange(e, "sportsName")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                        select
                      >
                        <MenuItem value="">Select The Name Of Sport</MenuItem>
                        {sportsName.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Role"
                        value={updatedStudent?.role || ""}
                        onChange={(e) => handleChange(e, "role")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Height"
                        value={updatedStudent?.height || ""}
                        onChange={(e) => handleChange(e, "height")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                        select
                      >
                        <MenuItem value="">Select Height</MenuItem>
                        {heightOptions.map((height) => (
                          <MenuItem key={height} value={height}>
                            {height}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Weight"
                        value={updatedStudent?.weight || ""}
                        onChange={(e) => handleChange(e, "weight")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                        select
                      >
                        <MenuItem value="">Select Weight</MenuItem>
                        {weightOptions.map((weight) => (
                          <MenuItem key={weight} value={weight}>
                            {weight}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Achievement"
                        value={updatedStudent?.achievement || ""}
                        onChange={(e) => handleChange(e, "achievement")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Number of Years Played"
                        value={updatedStudent?.noOfYearsPlayed || ""}
                        onChange={(e) =>
                          handleChange(e, "noOfYearsPlayed")
                        }
                        fullWidth
                        style={{ marginBottom: "16px" }}
                        select
                      >
                        <MenuItem value="">Select Number of Years</MenuItem>
                        {noOfYearsPlayed.map((year) => (
                          <MenuItem key={year} value={year}>
                            {year}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Level of Participation"
                        value={updatedStudent?.levelOfParticipation || ""}
                        onChange={(e) =>
                          handleChange(e, "levelOfParticipation")
                        }
                        fullWidth
                        style={{ marginBottom: "16px" }}
                        select
                      >
                        <MenuItem value="">Select Participation Level</MenuItem>
                        {levelOfParticipation.map((level) => (
                          <MenuItem key={level} value={level}>
                            {level}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Sports Injuries"
                        value={updatedStudent?.sportsInjuries || ""}
                        onChange={(e) => handleChange(e, "sportsInjuries")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                        select
                      >
                        <MenuItem value="">
                          Select Known Medical Injuries
                        </MenuItem>
                        {sportsInjuries.map((injury) => (
                          <MenuItem key={injury} value={injury}>
                            {injury}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <h4>Other Details</h4>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Disability Type"
                        value={updatedStudent?.disabilityType || ""}
                        onChange={(e) => handleChange(e, "disabilityType")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                        select
                      >
                        <MenuItem value="">
                          Please select disability type
                        </MenuItem>
                        {disabilityTypes.map((type) => (
                          <MenuItem key={type} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Special Percentage"
                        value={updatedStudent?.specialPercentage || ""}
                        onChange={(e) =>
                          handleChange(e, "specialPercentage")
                        }
                        fullWidth
                        style={{ marginBottom: "16px" }}
                        select
                      >
                        <MenuItem value="">Please select percentage</MenuItem>
                        {specialPercentage.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Domicile Number"
                        value={updatedStudent?.domicileNumber || ""}
                        onChange={(e) => handleChange(e, "domicileNumber")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Date of Registration"
                        value={updatedStudent?.dateOfRegistration || ""}
                        onChange={(e) =>
                          handleChange(e, "dateOfRegistration")
                        }
                        type="date"
                        fullWidth
                        style={{ marginBottom: "16px" }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Scholarship Name"
                        value={updatedStudent?.scholarshipName || ""}
                        onChange={(e) =>
                          handleChange(e, "scholarshipName")
                        }
                        fullWidth
                        style={{ marginBottom: "16px" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Address"
                        value={updatedStudent?.address || ""}
                        onChange={(e) => handleChange(e, "address")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                      />
                    </Grid>{" "}
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="landmark"
                        value={updatedStudent?.landmark || ""}
                        onChange={(e) => handleChange(e, "landmark")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="City"
                        value={updatedStudent?.city || ""}
                        onChange={(e) => handleChange(e, "city")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Taluka"
                        value={updatedStudent?.taluka || ""}
                        onChange={(e) => handleChange(e, "taluka")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="District"
                        value={updatedStudent?.district || ""}
                        onChange={(e) => handleChange(e, "district")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                        select
                      >
                        <MenuItem value="">Please select District</MenuItem>
                        {districtOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="State"
                        value={updatedStudent?.state || ""}
                        onChange={(e) => handleChange(e, "state")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                        select
                      >
                        <MenuItem value="">Please select State</MenuItem>
                        {stateOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Country"
                        value={updatedStudent?.country || ""}
                        onChange={(e) => handleChange(e, "country")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                      />
                    </Grid>{" "}
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Pincode"
                        value={updatedStudent?.pincode || ""}
                        onChange={(e) => handleChange(e, "pincode")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Permanent Address"
                        value={updatedStudent?.permanentAddress || ""}
                        onChange={(e) =>
                          handleChange(e, "permanentAddress")
                        }
                        fullWidth
                        style={{ marginBottom: "16px" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Permanent Landmark"
                        value={updatedStudent?.plandmark || ""}
                        onChange={(e) => handleChange(e, "plandmark")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Permanent City"
                        value={updatedStudent?.pcity || ""}
                        onChange={(e) => handleChange(e, "pcity")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Permanent Taluka"
                        value={updatedStudent?.ptaluka || ""}
                        onChange={(e) => handleChange(e, "ptaluka")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Permanent District"
                        value={updatedStudent?.pdistrict || ""}
                        onChange={(e) => handleChange(e, "pdistrict")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                        select
                      >
                        <MenuItem value="">Please select District</MenuItem>
                        {districtOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Permanent State"
                        value={updatedStudent?.pstate || ""}
                        onChange={(e) => handleChange(e, "pstate")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                        select
                      >
                        <MenuItem value="">Please select District</MenuItem>
                        {stateOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="permanent country"
                        value={updatedStudent?.pcountry || ""}
                        onChange={(e) => handleChange(e, "pcountry")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="permanent pincode"
                        value={updatedStudent?.ppincode || ""}
                        onChange={(e) => handleChange(e, "ppincode")}
                        fullWidth
                        style={{ marginBottom: "16px" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
          {/* Add more fields as needed */}
        </Grid>
      </DialogContent>
      <DialogActions>
      <Button color="secondary" variant="contained" sx={{marginRight:'540px'}} onClick={handleDocumentsDialogOpen}>
          Update Documents
        </Button>
        <Button color="error" onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </DialogActions>
         {/* Include UpdateDocumentsDialog component */}
         <UpdateDocument
        open={openDocumentsDialog}
        onClose={handleDocumentsDialogClose}
        studentId={student.stdid} // Pass the student ID to the dialog
      />
    </Dialog>
  );
};

export default UpdateStudent;
