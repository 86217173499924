// import React, { useState } from "react";
// import { Button, Box, Typography } from "@mui/material";
// import AddEmpcategory from "../Employee/AddEmpcategory"; // Replace with the actual Exam component file path
// import AddDepartment from "../Employee/AddDepartment"; // Create this component
// import ManageHoliday from "../Employee/ManageHoliday"; // Create this component

// const EmployeeSetting = () => {
//   // State to track which component to render, defaulting to "exam"
//   const [selectedComponent, setSelectedComponent] = useState("AddEmpcategory");

//   const handleButtonClick = (component) => {
//     setSelectedComponent(component);
//   };

//   return (
//     <Box sx={{ padding: "20px" }}>
//       {/* Buttons */}
//       <Box sx={{ display: "flex", gap: "15px", marginBottom: "20px" }} justifyContent={"space-evenly"}>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={() => handleButtonClick("AddEmpcategory")}
//         >
//           Add Category
//         </Button>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={() => handleButtonClick("AddDepartment")}
//         >
//           Add Department
//         </Button>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={() => handleButtonClick("ManageHoliday")}
//         >
//          Add Holiday
//         </Button>
//       </Box>

//       {/* Conditionally render the selected component */}
//       <Box>
//         {selectedComponent === "AddEmpcategory" && <AddEmpcategory />}
//         {selectedComponent === "AddDepartment" && <AddDepartment />}
//         {selectedComponent === "ManageHoliday" && <ManageHoliday />}
//       </Box>
//     </Box>
//   );
// };

// export default EmployeeSetting;

import React, { useState } from "react";
import { Box, Paper, Typography } from "@mui/material";
import CategoryIcon from "@mui/icons-material/Category";
import ApartmentIcon from "@mui/icons-material/Apartment";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import AddEmpcategory from "../Employee/AddEmpcategory"; // Replace with the actual component file path
import AddDepartment from "../Employee/AddDepartment"; // Create this component
import ManageHoliday from "../Employee/ManageHoliday"; // Create this component

const EmployeeSetting = () => {
  // State to track which component to render, defaulting to "AddEmpcategory"
  const [selectedComponent, setSelectedComponent] = useState("AddEmpcategory");

  return (
    <Box sx={{ display: "flex", padding: "10px" }}>
      {/* Sidebar */}
      <Paper
  elevation={3}
  sx={{
    width: "200px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    height: "200px",
    justifyContent: "space-between",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    whiteSpace: "nowrap",
    borderRadius: "8px",
    backgroundColor: "#f0f0f0",
  }}
>
  <Typography
    variant="h6"
    sx={{
      display: "flex", // Align icon and text
      alignItems: "center", // Vertically align
      gap: "10px", // Space between icon and text
      cursor: "pointer",
      padding: "5px 10px",
      color: selectedComponent === "AddEmpcategory" ? "white" : "text.primary",
      fontWeight: selectedComponent === "AddEmpcategory" ? "bold" : "normal",
      borderRadius: selectedComponent === "AddEmpcategory" ? "10px" : "4px",
      backgroundColor: selectedComponent === "AddEmpcategory" ? "#3498DB" : "",
      textAlign: "center",
      fontSize: "13px",
      transition: "all 0.3s ease",
      boxShadow:
        selectedComponent === "AddEmpcategory"
          ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
          : "none",
    }}
    onClick={() => setSelectedComponent("AddEmpcategory")}
  >
    <CategoryIcon /> Add Category
  </Typography>
  <Typography
    variant="h6"
    sx={{
      display: "flex", // Align icon and text
      alignItems: "center", // Vertically align
      gap: "10px", // Space between icon and text
      cursor: "pointer",
      padding: "5px 10px",
      color: selectedComponent === "AddDepartment" ? "white" : "text.primary",
      fontWeight: selectedComponent === "AddDepartment" ? "bold" : "normal",
      borderRadius: selectedComponent === "AddDepartment" ? "10px" : "4px",
      backgroundColor: selectedComponent === "AddDepartment" ? "#3498DB" : "",
      textAlign: "center",
      fontSize: "12.5px",
      transition: "all 0.3s ease",
      boxShadow:
        selectedComponent === "AddDepartment"
          ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
          : "none",
    }}
    onClick={() => setSelectedComponent("AddDepartment")}
  >
    <ApartmentIcon /> Add Department
  </Typography>
  <Typography
    variant="h6"
    sx={{
      display: "flex", // Align icon and text
      alignItems: "center", // Vertically align
      gap: "10px", // Space between icon and text
      cursor: "pointer",
      padding: "5px 10px",
      color: selectedComponent === "ManageHoliday" ? "white" : "text.primary",
      fontWeight: selectedComponent === "ManageHoliday" ? "bold" : "normal",
      borderRadius: selectedComponent === "ManageHoliday" ? "10px" : "4px",
      backgroundColor: selectedComponent === "ManageHoliday" ? "#3498DB" : "",
      textAlign: "center",
      fontSize: "13px",
      transition: "all 0.3s ease",
      boxShadow:
        selectedComponent === "ManageHoliday"
          ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
          : "none",
    }}
    onClick={() => setSelectedComponent("ManageHoliday")}
  >
    <HolidayVillageIcon /> Add Holiday
  </Typography>
</Paper>





      {/* Content Area */}
      <Box
        sx={{
          flexGrow: 1,
          padding: "20px",
          marginLeft: "20px",
          border: "1px solid #ddd",
          borderRadius: "8px",
        }}
      >
        {selectedComponent === "AddEmpcategory" && <AddEmpcategory />}
        {selectedComponent === "AddDepartment" && <AddDepartment />}
        {selectedComponent === "ManageHoliday" && <ManageHoliday />}
      </Box>
    </Box>
  );
};

export default EmployeeSetting;
